import { TranslationFile } from '../models/translation-file.model';

export class TranslationsOperation {
    public static readonly DOUBLE_ENCODED_SLASH = '%25252F';
    public static readonly SINGLE_ENCODED_SLASH = '%2F';

    /**
     * Updates a translation Item of a specified language.
     * @param translationFile translation file of the language to update, this object will be changed by reference
     * @param route array of strings to the folder to update
     * @param key the key to update
     * @param value string containing the translation or an object for a topic
     * @param preventOverrides if set to true, if the key already exists, the value will not be changed.
     */
    public static updateItemAtRouteByRef(
        translationFile: TranslationFile,
        route: string[],
        key: string,
        value: string | Record<string, any>,
        preventOverrides = false
    ): void {
        let targetObj = translationFile.file;
        for (const subRoute of route) {
            // We update the targetObj until we arrive to the object we should have to edit
            if (!targetObj[subRoute]) {
                targetObj[subRoute] = {};
            }
            targetObj = targetObj[subRoute];
        }
        if (!preventOverrides || !targetObj[key]) {
            targetObj[key] = value;
        }
    }
}
