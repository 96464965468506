<mat-form-field subscriptSizing="dynamic">
    <input
        type="text"
        #inputTrigger
        placeholder="Search to Select"
        matInput
        [matAutocomplete]="autoComplete"
        formControlName="itemControl"
        [matChipInputFor]="chipList"
    />
    <mat-chip-grid #chipList>
        <mat-chip-row (removed)="remove(item)" *ngFor="let item of selectedItems">
            {{ item }}
            <button matChipRemove [attr.aria-label]="'remove ' + item">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
    </mat-chip-grid>
</mat-form-field>

<mat-autocomplete #autoComplete="matAutocomplete">
    <mat-option *ngFor="let item of filteredItems" [value]="selectedItems">
        <div (click)="optionClicked($event, item.value)">
            <mat-checkbox
                [checked]="isSelected(item.value)"
                (change)="toggleSelection(item.value)"
                (click)="$event.stopPropagation()"
            >
                {{ item.label }}
            </mat-checkbox>
        </div>
    </mat-option>
</mat-autocomplete>
