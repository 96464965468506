import { Component, Input } from '@angular/core';
import { SeasonTimelineComponent } from './season-timeline/season-timeline.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-timeframe-card',
    standalone: true,
    templateUrl: './timeframe-card.component.html',
    imports: [SeasonTimelineComponent, CommonModule, TranslateModule]
})
export class TimeframeCardComponent {
    @Input() title: string;
    @Input() startDate: string | Date;
    @Input() endDate: string | Date;
    @Input() applicationDeadlineDate: string | Date;
}
