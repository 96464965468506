<h1 mat-dialog-title>{{ 'translations.dialogs.add-topic.title' | translate }}</h1>

<div class="dialog-content pt-3">
    <p>{{ 'translations.dialogs.add-topic.text' | translate }}</p>

    <sag-ui-input
        [label]="'translations.dialogs.add-topic.topic-technical-name-label' | translate"
        [placeholder]="'translations.dialogs.add-topic.topic-technical-name-placeholder' | translate"
        [(ngModel)]="data.title"
    >
    </sag-ui-input>
</div>

<div align="end" class="actions pt-7" mat-dialog-actions>
    <sag-ui-button color="secondary" (click)="cancel()"> {{ 'translations.dialogs.add-topic.cancel' | translate }} </sag-ui-button>
    <sag-ui-button (click)="proceed()"> {{ 'translations.dialogs.add-topic.confirm' | translate }} </sag-ui-button>
</div>
