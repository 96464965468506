export interface Page<T> {
    items: T[];
    metadata: Metadata;
}

export interface Metadata {
    currentPage: number;
    take: number;
    itemCount: number;
    lastPage: number;
    hasNextPage: boolean;
}
