import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    DecrementStepper,
    IncrementStepper,
    LazyLoadSeasons,
    LoadSeasons,
    ResetSeasonForm,
    ResetStepper,
    LoadApplications,
    LoadFinancialOverview,
    SetSeason,
    SetStepper,
    SubmitForm,
    LazyLoadApplications,
    LoadSeason,
    DeleteSeason,
    ActivateSeason,
    ToggleSelectedForPayout,
    AutoSelectForPayout,
    ReleasePayout,
    UpdateApplicationComment,
    DeleteApplicationComment,
    CloseSeason
} from './season.action';
import { SeasonForm, SeasonFormStepper } from '../models/season-create.model';
import { SeasonService } from '../services/season.service';
import { SeasonCreateOperations } from '../operations/season-creation.operations';
import { catchError, EMPTY, Observable, of, switchMap, tap } from 'rxjs';
import { Metadata, Page, Season, SeasonStatus, SeasonWithUpdateBy, UserApplication } from 'libs/interfaces/src';
import { produce } from 'immer';
import { FinancialOverviewResponse } from '../models/season-financial.model';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ApplicationService } from '../services/application.service';

export interface SeasonStateModel {
    seasons: SeasonWithUpdateBy[];
    stepper: SeasonFormStepper;
    seasonCreationForm: {
        model: SeasonForm;
    };
    season: Season;
    isLoadingSeasons: boolean;
    isSavingSeason: boolean;
    isLoadingApplications: boolean;
    isLazyLoadingApplications: boolean;
    isLoadingSeasonDetails: boolean;
    isLoadingSeasonFinancialOverview: boolean;
    metadata: Metadata | undefined;
    financialOverview: FinancialOverviewResponse;
    applications: UserApplication[];
    applicationsMetadata: Metadata | undefined;
    latestFinancialOverviewRequest: Date;
}

const PAGE_SIZE = 20;

@State<SeasonStateModel>({
    name: 'season',
    defaults: {
        seasons: [],
        stepper: {
            active: 0,
            selected: 0
        },
        seasonCreationForm: undefined,
        season: undefined,
        isLoadingSeasons: true,
        isSavingSeason: false,
        isLoadingApplications: true,
        isLazyLoadingApplications: false,
        isLoadingSeasonDetails: true,
        isLoadingSeasonFinancialOverview: true,
        metadata: undefined,
        financialOverview: undefined,
        applications: undefined,
        applicationsMetadata: undefined,
        latestFinancialOverviewRequest: new Date()
    }
})
@Injectable()
export class SeasonState {
    constructor(private seasonService: SeasonService, private applicationService: ApplicationService) {}

    @Selector()
    static getSeasons(state: SeasonStateModel): SeasonWithUpdateBy[] {
        return state.seasons;
    }

    @Selector()
    static getForm(state: SeasonStateModel): SeasonForm {
        return state.seasonCreationForm.model;
    }

    @Selector()
    static getFormStepper(state: SeasonStateModel): SeasonFormStepper {
        return state.stepper;
    }

    @Selector()
    static getSeason(state: SeasonStateModel): Season {
        return state.season;
    }

    @Selector()
    static getFinancialOverview(state: SeasonStateModel): FinancialOverviewResponse {
        return state.financialOverview;
    }

    @Selector()
    static getApplications(state: SeasonStateModel): UserApplication[] {
        return state.applications;
    }

    static allSeasonsLoaded(state: SeasonStateModel): boolean {
        return !state.metadata?.hasNextPage;
    }

    static allApplicationsLoaded(state: SeasonStateModel): boolean {
        return !(state?.applicationsMetadata?.hasNextPage ?? true);
    }

    @Selector()
    static isLoadingSeasons(state: SeasonStateModel): boolean {
        return state.isLoadingSeasons;
    }

    @Selector()
    static isSavingSeason(state: SeasonStateModel): boolean {
        return state.isSavingSeason;
    }

    @Selector()
    static isLoadingApplications(state: SeasonStateModel): boolean {
        return state.isLoadingApplications;
    }

    @Selector()
    static isLazyLoadingApplications(state: SeasonStateModel): boolean {
        return state.isLazyLoadingApplications;
    }

    @Selector()
    static isSeasonsInitalLoading(state: SeasonStateModel): boolean {
        return state.isLoadingSeasons && state.seasons.length <= 0;
    }

    @Selector()
    static isLoadingSeasonDetails(state: SeasonStateModel): boolean {
        return state.isLoadingSeasonDetails || state.isLoadingSeasonFinancialOverview;
    }

    @Action(SetSeason)
    setSeason(ctx: StateContext<SeasonStateModel>, action: SetSeason) {
        ctx.patchState({ season: action.payload });
    }

    @Action(SetStepper)
    setStepper(context: StateContext<SeasonStateModel>, action: SetStepper) {
        context.patchState({
            stepper: {
                active: action.currentStepIndex,
                selected: action.currentStepIndex
            }
        });
    }

    @Action(LoadSeason)
    loadSeason(ctx: StateContext<SeasonStateModel>, action: LoadSeason): Observable<Season> {
        ctx.patchState({ isLoadingSeasonDetails: true });
        return this.seasonService.getSeasonById(action.payload.seasonId).pipe(
            catchError(() => {
                ctx.patchState({ isLoadingSeasonDetails: false });
                return of(void 0);
            }),
            tap(season =>
                ctx.patchState({
                    season: {
                        ...season,
                        startDate: season.startDate ? new Date(season.startDate) : null,
                        endDate: season.endDate ? new Date(season.endDate) : null
                    },
                    isLoadingSeasonDetails: false
                })
            )
        );
    }

    @Action(LoadFinancialOverview)
    loadFinancialOverview(
        ctx: StateContext<SeasonStateModel>,
        action: LoadFinancialOverview
    ): Observable<FinancialOverviewResponse> {
        ctx.patchState({ isLoadingSeasonFinancialOverview: true });
        return this.seasonService.getSeasonFinancialOverviewBySeasonId(action.payload.seasonId).pipe(
            catchError(() => {
                ctx.patchState({ isLoadingSeasonFinancialOverview: false });
                return of(void 0);
            }),
            tap(financialOverview => ctx.patchState({ financialOverview, isLoadingSeasonFinancialOverview: false }))
        );
    }

    @Action(LoadApplications)
    loadApplications(ctx: StateContext<SeasonStateModel>, action: LoadApplications) {
        ctx.patchState({ isLoadingApplications: true });
        return this.seasonService
            .getSeasonApplicationsById(action.payload.seasonId, 0, PAGE_SIZE, action.payload.gid)
            .pipe(
                catchError(() => {
                    ctx.patchState({ isLoadingApplications: false });
                    return of(void 0);
                }),
                tap(response =>
                    ctx.patchState({
                        applications: response.items.flat(),
                        isLoadingApplications: false
                    })
                )
            );
    }

    @Action(LazyLoadApplications)
    lazyLoadApplications(
        ctx: StateContext<SeasonStateModel>,
        action: LazyLoadApplications
    ): Observable<Page<UserApplication>> {
        const state = ctx.getState();
        const page = Math.round(ctx.getState().applications.length / PAGE_SIZE);
        ctx.patchState({ isLazyLoadingApplications: true });

        return this.seasonService.getSeasonApplicationsById(action.payload.seasonId, page, PAGE_SIZE).pipe(
            catchError(() => {
                ctx.patchState({ isLazyLoadingApplications: false });
                return of(void 0);
            }),
            tap(result =>
                ctx.patchState({
                    applications: state.applications.concat(result.items.flat()),
                    applicationsMetadata: result.metadata,
                    isLazyLoadingApplications: false
                })
            )
        );
    }

    @Action(ToggleSelectedForPayout)
    toggleSelectedForPayout(ctx: StateContext<SeasonStateModel>, action: ToggleSelectedForPayout) {
        const application = action.payload.application;
        const isSelectedForPayout = application.isSelectedForPayout;

        const now = new Date();

        ctx.setState(
            patch({
                applications: updateItem(
                    app => app.id === application.id,
                    patch({ isSelectedForPayout: !application.isSelectedForPayout })
                ),
                financialOverview: patch({
                    selectedApplications: isSelectedForPayout
                        ? Math.max(ctx.getState().financialOverview.selectedApplications - 1, 0)
                        : ctx.getState().financialOverview.selectedApplications + 1,
                    availableBudget: isSelectedForPayout
                        ? ctx.getState().financialOverview.availableBudget.add(application.potentialPayout)
                        : ctx.getState().financialOverview.availableBudget.subtract(application.potentialPayout)
                }),
                latestFinancialOverviewRequest: now
            })
        );

        return (
            isSelectedForPayout
                ? this.applicationService.unselectPayout(application.id)
                : this.applicationService.selectPayout(application.id)
        ).pipe(
            switchMap(() => this.seasonService.getSeasonFinancialOverviewBySeasonId(application.seasonId)),
            tap(financialOverview => {
                const latestFinancialOverviewRequest = ctx.getState().latestFinancialOverviewRequest;
                if (latestFinancialOverviewRequest > now) {
                    return;
                }

                ctx.setState(
                    patch({
                        financialOverview: financialOverview
                    })
                );
            }),
            catchError(() => {
                ctx.setState(
                    patch({
                        applications: updateItem(
                            app => app.id === application.id,
                            patch({ isSelectedForPayout: isSelectedForPayout })
                        )
                    })
                );

                return EMPTY;
            })
        );
    }

    @Action(AutoSelectForPayout)
    autoSelectForPayout(ctx: StateContext<SeasonStateModel>) {
        return this.seasonService.autoSelectApplications(ctx.getState().season.id);
    }

    @Action(ReleasePayout)
    releasePayout(ctx: StateContext<SeasonStateModel>) {
        return this.seasonService.releasePayout(ctx.getState().season.id);
    }

    @Action(LoadSeasons)
    loadSeasons(ctx: StateContext<SeasonStateModel>): Observable<Page<SeasonWithUpdateBy>> {
        ctx.patchState({ isLoadingSeasons: true });
        return this.seasonService.getSeasons(0, PAGE_SIZE, '').pipe(
            catchError(() => {
                ctx.patchState({ isLoadingSeasons: false });
                return of(void 0);
            }),
            tap(result =>
                ctx.setState(
                    produce(ctx.getState(), state => {
                        state.seasons = result.items;
                        state.metadata = result.metadata;
                        state.isLoadingSeasons = false;
                    })
                )
            )
        );
    }

    @Action(LazyLoadSeasons)
    lazyLoadSeasons(ctx: StateContext<SeasonStateModel>): Observable<Page<SeasonWithUpdateBy>> {
        ctx.patchState({ isLoadingSeasons: true });
        const page = Math.round(ctx.getState().seasons.length / PAGE_SIZE);
        return this.seasonService.getSeasons(page, PAGE_SIZE, undefined).pipe(
            catchError(() => {
                ctx.patchState({ isLoadingSeasons: false });
                return of(void 0);
            }),
            tap(result =>
                ctx.setState(
                    produce(ctx.getState(), state => {
                        state.seasons = result.items;
                        state.metadata = result.metadata;
                        state.isLoadingSeasons = false;
                    })
                )
            )
        );
    }

    @Action(ActivateSeason)
    activateSeason(ctx: StateContext<SeasonStateModel>, { seasonId }: ActivateSeason): Observable<void> {
        return this.seasonService.activateSeason(seasonId).pipe(
            catchError(() => {
                return of(void 0);
            }),
            tap(() =>
                ctx.setState(
                    patch({
                        seasons: updateItem(season => season.id === seasonId, patch({ status: SeasonStatus.ACTIVE }))
                    })
                )
            )
        );
    }

    @Action(CloseSeason)
    closeSeason(ctx: StateContext<SeasonStateModel>, { seasonId }: CloseSeason): Observable<void> {
        return this.seasonService.closeSeason(seasonId).pipe(
            catchError(() => {
                return of(void 0);
            }),
            tap(() =>
                ctx.setState(
                    patch({
                        seasons: updateItem(season => season.id === seasonId, patch({ status: SeasonStatus.CLOSED }))
                    })
                )
            )
        );
    }

    @Action(DeleteSeason)
    deleteSeason(ctx: StateContext<SeasonStateModel>, { seasonId }: DeleteSeason): Observable<void> {
        return this.seasonService.deleteSeason(seasonId).pipe(
            catchError(() => {
                return of(void 0);
            }),
            tap(() =>
                ctx.setState(
                    patch({
                        seasons: removeItem(season => season.id === seasonId)
                    })
                )
            )
        );
    }

    @Action(SubmitForm)
    submitForm(ctx: StateContext<SeasonStateModel>): Observable<Season> {
        const seasonState = ctx.getState();
        const currentSeasonState = seasonState.seasonCreationForm.model;
        const seasonToBeSubmitted = SeasonCreateOperations.mapSeasonFormToSeasonResponse(currentSeasonState);

        ctx.patchState({ isSavingSeason: true });

        const observable = seasonToBeSubmitted?.id
            ? this.seasonService.updateSeason(seasonToBeSubmitted)
            : this.seasonService.createSeason(seasonToBeSubmitted);

        return observable.pipe(tap(() => ctx.patchState({ isSavingSeason: false })));
    }

    @Action(IncrementStepper)
    incrementStepper(context: StateContext<SeasonStateModel>) {
        const currentStepperValue = context.getState().stepper;
        context.patchState({
            stepper: {
                active: currentStepperValue.active + 1,
                selected: currentStepperValue.selected + 1
            }
        });
    }

    @Action(DecrementStepper)
    decrementStepper(context: StateContext<SeasonStateModel>) {
        const currentStepperValue = context.getState().stepper;
        context.patchState({
            stepper: {
                active: currentStepperValue.active - 1,
                selected: currentStepperValue.selected - 1
            }
        });
    }

    @Action(ResetStepper)
    resetStepper(context: StateContext<SeasonStateModel>) {
        context.patchState({
            stepper: {
                active: 0,
                selected: 0
            }
        });
    }

    @Action(ResetSeasonForm)
    resetForm(context: StateContext<SeasonStateModel>) {
        context.patchState({
            stepper: {
                active: 0,
                selected: 0
            },
            seasonCreationForm: undefined,
            season: undefined
        });
    }

    @Action(UpdateApplicationComment)
    updateApplicationComment(ctx: StateContext<SeasonStateModel>, action: UpdateApplicationComment) {
        ctx.setState(
            patch({
                applications: updateItem(
                    app => app.id === action.payload.id,
                    patch({ comment: action.payload.comment })
                )
            })
        );

        return this.applicationService.updateComment(action.payload.id, action.payload.comment);
    }

    @Action(DeleteApplicationComment)
    deleteApplicationComment(ctx: StateContext<SeasonStateModel>, { payload }: DeleteApplicationComment) {
        return this.applicationService.deleteComment(payload.id).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        applications: updateItem(app => app.id === payload.id, patch({ comment: '' }))
                    })
                );
            })
        );
    }
}
