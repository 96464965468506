<div class="flex flex-col bg-cardBackgroundColor mt-8 mb-8 p-4 rounded">
    <div class="flex flex-col">
        <p>{{ title }}</p>
        <p class="text-darkGraySubtitleColor mt-2">{{ subtitle }}</p>
        <h1 class="text-l font-bold mt-4 mb-4">{{ totalBudget?.toFormat() }}</h1>
    </div>

    <div>
        <p class="mb-2">
            {{ 'seasons.season-details.setting-cards.budget-card.employee' | translate }}
        </p>
        <div class="flex flex-row">
            <div class="flex flex-col">
                <p class="text-darkGraySubtitleColor">
                    {{ 'seasons.season-details.setting-cards.budget-card.first-subsidy' | translate }}
                </p>
                <p>{{ employeeSubsidies?.[0]?.amount?.toFormat() }}</p>
            </div>
            <div class="flex flex-col ml-32">
                <p class="text-darkGraySubtitleColor">
                    {{ 'seasons.season-details.setting-cards.budget-card.second-subsidy' | translate }}
                </p>
                <p>{{ employeeSubsidies?.[1]?.amount?.toFormat() }}</p>
            </div>
        </div>
    </div>

    <hr class="border-separatorColor mt-4 mb-4" />

    <div>
        <p class="mb-2">
            {{ 'seasons.season-details.setting-cards.budget-card.spouse' | translate }}
        </p>
        <div class="flex flex-row">
            <div class="flex flex-col">
                <p class="text-darkGraySubtitleColor">
                    {{ 'seasons.season-details.setting-cards.budget-card.first-subsidy' | translate }}
                </p>
                <p>{{ spouseSubsidies?.[0]?.amount?.toFormat() }}</p>
            </div>
            <div class="flex flex-col ml-32">
                <p class="text-darkGraySubtitleColor">
                    {{ 'seasons.season-details.setting-cards.budget-card.second-subsidy' | translate }}
                </p>
                <p>{{ spouseSubsidies?.[1]?.amount?.toFormat() }}</p>
            </div>
        </div>
    </div>

    <hr class="border-separatorColor mt-4 mb-4" />

    <div>
        <p class="mb-2">
            {{ 'seasons.season-details.setting-cards.budget-card.children' | translate }}
        </p>
        <div class="flex flex-row">
            <div class="flex flex-col">
                <p class="text-darkGraySubtitleColor">
                    {{ 'seasons.season-details.setting-cards.budget-card.first-subsidy' | translate }}
                </p>
                <p>{{ childSubsidies?.[0]?.amount?.toFormat() }}</p>
            </div>
            <div class="flex flex-col ml-32">
                <p class="text-darkGraySubtitleColor">
                    {{ 'seasons.season-details.setting-cards.budget-card.second-subsidy' | translate }}
                </p>
                <p>{{ childSubsidies?.[1]?.amount?.toFormat() }}</p>
            </div>
        </div>
    </div>
</div>
