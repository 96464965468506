@if (user$ | async; as user) {
<div class="ml-6">
    <div sag-ui-avatar-base>
        @if(picture(); as picture) {
        <img [src]="picture" class="rounded-full h-[46px] w-[46px] border-2 border-transparent" />
        } @else {
        <div sag-ui-avatar>{{ user.firstName[0].toUpperCase() }}{{ user.lastName[0].toUpperCase() }}</div>
        }
        <div>
            <div sag-ui-avatar-name>{{ user.firstName }} {{ user.lastName }}</div>
            <div sag-ui-avatar-org>{{ user.orgCode }}</div>
        </div>
    </div>
</div>
}
