export const CREATE_SEASON_STEPS = [
    {
        label: 'seasons.season-creation.stepper.create',
        doneIcon: 'checkmark-large',
        icon: ''
    },
    {
        label: 'seasons.season-creation.stepper.timeframe',
        doneIcon: 'checkmark-large',
        icon: ''
    },
    {
        label: 'seasons.season-creation.stepper.budget',
        doneIcon: 'checkmark-large',
        icon: ''
    },
    {
        label: 'seasons.season-creation.stepper.social-criteria',
        doneIcon: 'checkmark-large',
        icon: ''
    },
    {
        label: 'seasons.season-creation.stepper.salary-weight',
        doneIcon: 'checkmark-large',
        icon: ''
    },
    {
        label: 'seasons.season-creation.stepper.summary',
        doneIcon: 'checkmark-large',
        icon: ''
    }
];
