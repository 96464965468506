<div class="min-h-[800px] py-6" *ngIf="user$ | async">
    <div>
        <h1 class="h-[55px]">
            <span class="font-header" class="text-[#00FFB9] font-header">{{ 'home-dashboard.ema' | translate }}</span>
            {{ 'home-dashboard.title' | translate }}
        </h1>
        <span class="text-[#E5E5E9]">{{ 'home-dashboard.description' | translate }}</span>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-7 pt-3 max-w-[1020px]">
        <div
            [routerLink]="['/seasons']"
            class="aspect-square flex flex-col rounded items-center justify-center bg-[#23233c] hover:bg-[#262648] hover:outline hover:outline-[#00FFB9] hover:bg-opacity-40 hover:text-[#00FFB9] hover: cursor-pointer"
        >
            <div class="flex flex-col items-center justify-center gap-2">
                <i class="material-icons-outlined">list_alt</i>
                <span class="font-header">{{ 'home-dashboard.tiles.season-overview' | translate }}</span>
            </div>
        </div>
        @if((user$ | async)?.roles?.includes(userRoles.ADMIN)){
        <div
            (click)="navigateToSeasonCreation()"
            class="aspect-square flex flex-col rounded items-center justify-center bg-[#23233c] hover:bg-[#262648] hover:outline hover:outline-[#00FFB9] hover:bg-opacity-40 hover:text-[#00FFB9] hover: cursor-pointer"
        >
            <div class="flex flex-col items-center justify-center gap-2">
                <i class="material-icons-outlined icon">add_circle</i>
                <span class="font-header">{{ 'home-dashboard.tiles.create-season' | translate }}</span>
            </div>
        </div>
        <div
            [routerLink]="['/translations']"
            class="aspect-square flex flex-col rounded items-center justify-center bg-[#23233c] hover:bg-[#262648] hover:outline hover:outline-[#00FFB9] hover:bg-opacity-40 hover:text-[#00FFB9] hover: cursor-pointer"
        >
            <div class="flex flex-col items-center justify-center gap-2">
                <i class="material-icons-outlined">translate</i>
                <span class="font-header">{{ 'home-dashboard.tiles.translations' | translate }}</span>
            </div>
        </div>
        <div
            [routerLink]="['/user-management']"
            class="aspect-square flex flex-col rounded items-center justify-center bg-[#23233c] hover:bg-[#262648] hover:outline hover:outline-[#00FFB9] hover:bg-opacity-40 hover:text-[#00FFB9] hover: cursor-pointer"
        >
            <div class="flex flex-col items-center justify-center gap-2">
                <i class="material-icons-outlined icon">manage_accounts</i>
                <span class="font-header">{{ 'home-dashboard.tiles.user-management' | translate }}</span>
            </div>
        </div>
        <div
            [routerLink]="['/workers-council-groups']"
            class="aspect-square flex flex-col rounded items-center justify-center bg-[#23233c] hover:bg-[#262648] hover:outline hover:outline-[#00FFB9] hover:bg-opacity-40 hover:text-[#00FFB9] hover: cursor-pointer"
        >
            <div class="flex flex-col items-center justify-center gap-2">
                <i class="material-icons-outlined icon">groups</i>
                <span class="font-header">{{ 'home-dashboard.tiles.workers-council-groups' | translate }}</span>
            </div>
        </div>
        }
    </div>
</div>
