<div class="table-container" [style]="style">
    <table class="table">
        <thead class="table-header z-10">
            <tr class="table-header-row">
                <th class="table-header-cell" *ngIf="selectable">
                    <mat-checkbox (change)="toggleSelectAll($event.checked)"></mat-checkbox>
                </th>
                <th
                    class="table-header-cell"
                    *ngFor="let column of columns"
                    [style]="{ minWidth: column.width ?? undefined }"
                    [ngClass]="column.unpadded ? 'unpadded': undefined"
                >
                    <div class="table-header-cell-content">
                        <div class="table-header-cell-left">
                            <span class="table-header-cell-text">{{ column.name }} </span>
                            <div *ngIf="column.sortable">
                                <button
                                    (click)="toggleSort(column.selector)"
                                    class="toggle-button"
                                    [class.toggle-button-active]="sortActive(column.selector)"
                                >
                                    <app-icon [icon]="getSortIcon(column.selector)" />
                                </button>
                            </div>
                        </div>
                        <div *ngIf="column.filterable">
                            <app-filter-menu
                                [selector]="column.selector"
                                [filterType]="column.filterType ?? 'checkbox'"
                                [filterOptions]="getFilterOptions(column.selector)"
                                [selectedFilters]="selectedFilterOptions(column.selector)"
                                (selectedFiltersChange)="filterArray(column.selector, $any($event))"
                            ></app-filter-menu>
                        </div>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody class="table-body" *ngIf="!loading">
            <tr class="table-body-row" *ngFor="let row of rows" (click)="rowClicked(row)">
                <td class="table-body-cell row-select-cell" *ngIf="selectable">
                    <mat-checkbox (change)="toggleSelect(row)" [checked]="isSelected(row, selectAll)"></mat-checkbox>
                </td>
                <td class="table-body-cell" *ngFor="let column of columns" [ngClass]="column.unpadded ? 'unpadded': undefined"
                >
                    <div *ngIf="column.row">
                        {{ column.row(row) }}
                    </div>
                    <div *ngIf="column.component">
                        <app-component-wrapper
                            [component]="column.component"
                            (componentInit)="invokeComponentInit(column, $event, row)"
                        ></app-component-wrapper>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="table-loading-spinner" *ngIf="loading">
        <sag-ui-spinner [size]="'lg'"></sag-ui-spinner>
    </div>

    <div class="table-no-items" *ngIf="!loading && rows.length === 0">
        <span>No items found</span>
    </div>
</div>

<div *ngIf="pagination" class="table-footer-container">
    <mat-paginator
        sag-ui-select
        #paginator
        [length]="totalSize"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="page - 1"
        aria-label="Select page"
        (page)="changePage($any($event))"
        class="pagination"
    >
    </mat-paginator>
</div>
