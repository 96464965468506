import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../../shared/interfaces/CreateOrUpdateUserPayload';
import { environment } from '../../../environments/environment';
import { SCDUser } from '../../shared/interfaces/ScdUser';
import { Roles } from '../../shared/enums/roles';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {
    private apiUrl = `${environment.apiUrl}/v1/users`;
    constructor(private httpClient: HttpClient) {}

    public getUsers(page: number, pageSize: number, searchTerm: string): Observable<{ items: User[]; metadata: any }> {
        const params = {
            page,
            pageSize,
            orderBy: 'last_name',
            sortOrder: 'ASC'
        };
        if (searchTerm?.length) {
            params['searchTerm'] = searchTerm;
        }
        return this.httpClient.get<{ items: User[]; metadata: any }>(`${this.apiUrl}`, {
            params
        });
    }

    public getDelegateUsers(): Observable<User[]> {
        return this.httpClient
            .get<{ items: User[] }>(`${this.apiUrl}`, {
                params: {
                    roles: [Roles.DELEGATE]
                }
            })
            .pipe(map(data => data.items));
    }

    public createUser(user: User): Observable<User> {
        return this.httpClient.post<User>(`${this.apiUrl}`, user);
    }

    public updateUser(user: User): Observable<User> {
        const { gid, ...rest } = user;
        return this.httpClient.patch<User>(`${this.apiUrl}/${gid}`, rest);
    }

    public deleteUser(gid: string): Observable<User> {
        return this.httpClient.delete<User>(`${this.apiUrl}/${gid}`);
    }

    public getUser(gid: string): Observable<User> {
        return this.httpClient.get<User>(`${this.apiUrl}/${gid}`);
    }

    public getUserFromScd(params: { gid?: string; firstName?: string; lastName?: string }): Observable<SCDUser[]> {
        if (!params.gid) delete params.gid;
        if (!params.firstName) delete params.firstName;
        if (!params.lastName) delete params.lastName;

        return this.httpClient.get<SCDUser[]>(`${this.apiUrl}/scd`, { params });
    }

    public getProfilePicture(): Observable<string | undefined> {
        return this.httpClient
            .get<{ data: string; encoding: string; contentType: string }>(`${environment.apiUrl}/v1/me/profile-picture`)
            .pipe(
                map(response => `data:${response.contentType};${response.encoding},${response.data}`),
                catchError(() => of(undefined))
            );
    }
}
