import { Season, UserApplication } from 'libs/interfaces/src';

export class LoadSeasons {
    static readonly type = '[Seasons] Load';
}
export class LazyLoadSeasons {
    static readonly type = '[Seasons] Lazy Load';
}
export class ActivateSeason {
    static readonly type = '[Seasons] Set Active';
    constructor(public seasonId: string) {}
}
export class DeleteSeason {
    static readonly type = '[Seasons] Delete';
    constructor(public seasonId: string) {}
}
export class IncrementStepper {
    static readonly type = '[Form] Increment Stepper';
}

export class DecrementStepper {
    static readonly type = '[Form] Decrement Stepper';
}

export class SetStepper {
    static readonly type = '[Form] Set Stepper';
    constructor(public currentStepIndex: number) {}
}

export class SetSeason {
    static readonly type = '[Season] Set Season';
    constructor(public payload: Season) {}
}

export class LoadSeason {
    static readonly type = '[Season] Load Season';
    constructor(public payload: { seasonId: string }) {}
}

export class CloseSeason {
    static readonly type = '[Season] Close Season';
    constructor(public seasonId: string) {}
}

export class LoadFinancialOverview {
    static readonly type = '[FinancialOverview] Set FinancialOverview';
    constructor(public payload: { seasonId: string }) {}
}

export class LoadApplications {
    static readonly type = '[Applications] Set Applications';
    constructor(public payload: { seasonId: string; gid?: string }) {}
}

export class LazyLoadApplications {
    static readonly type = '[Applications] Lazy Load';
    constructor(public payload: { seasonId: string }) {}
}

export class ToggleSelectedForPayout {
    static readonly type = '[Applications] Toggle Selected For Payout';
    constructor(public payload: { application: UserApplication }) {}
}

export class AutoSelectForPayout {
    static readonly type = '[Applications] Auto Select For Payout';
}

export class ReleasePayout {
    static readonly type = '[Applications] Release Payout';
}

export class ResetStepper {
    static readonly type = '[Form] Reset Stepper';
}

export class ResetSeasonForm {
    static readonly type = '[Form] Reset Form';
}

export class SubmitForm {
    static readonly type = '[Form] Submit Form';
}

export class UpdateApplicationComment {
    static readonly type = '[Applications] Update Comment';
    constructor(public payload: { id: string; comment: string }) {}
}

export class DeleteApplicationComment {
    static readonly type = '[Applications] Delete Comment';
    constructor(public payload: { id: string }) {}
}
