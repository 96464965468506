<div class="flex flex-col mx-auto my-0 max-w-[1440px] w-[95%] z-50 h-full py-6">
    <sag-ui-button color="tertiary" [routerLink]="['/dashboard']" icon="chevron-left" class="-ml-4">
        {{ 'general.back-to-dashboard' | translate }}
    </sag-ui-button>

    <div class="pb-14 w-full flex space-x-4 justify-between mt-4">
        <div class="flex space-x-4 justify-between items-center gap-4">
            <h3 class="text-xl">{{ 'seasons.season-overview.title' | translate }}</h3>
            @if (isAdmin()) {
            <sag-ui-button color="primary" [routerLink]="['/season/create']">
                <span class="icon-plus-small"></span>
                {{ 'seasons.season-overview.buttons.create-new' | translate }}
            </sag-ui-button>
            }
        </div>

        <div style="display: flex; flex-direction: column; gap: 0.5rem; width: 20rem">
            <sag-ui-input placeholder="{{ 'seasons.season-overview.search' | translate }}" [(ngModel)]="searchValue">
            </sag-ui-input>
        </div>
    </div>

    <div class="relative overflow-x-auto w-full h-full max-h-[60vh]">
        <table class="border-separate w-full border-spacing-y-1">
            <thead class="sticky top-0">
                <tr>
                    @for (column of columnNames(); track $index) {
                    <th class="bg-[var(--bgColorPrimary)] font-normal">
                        <div
                            class="flex items-center justify-start px-0 py-2.5 whitespace-nowrap min-w-8 gap-2.5 mr-2.5 truncate text-xs text-[#CCCCD4]"
                        >
                            <span>{{ column | translate }}</span>
                        </div>
                    </th>
                    }
                </tr>
            </thead>

            <tbody>
                @for (season of filteredSeasons(); track $index) {
                <tr class="h-14 bg-[var(--bgColorSecondary)] color-[var(--textColor)] cursor-pointer text-sm">
                    <td
                        class="text-[#00CCCC] rounded-tl-[10px] rounded-bl-[10px] p-2 px-4"
                        (click)="handleClickOnSeason(season.id)"
                    >
                        <div>{{ season.title }}</div>
                    </td>
                    <td (click)="handleClickOnSeason(season.id)">
                        <div>
                            {{ season.createdAt | date : 'dd.MM.yyyy' : undefined : 'en' }}
                        </div>
                    </td>
                    <td (click)="handleClickOnSeason(season.id)">
                        <div>
                            {{ season.startDate | date : 'dd.MM.yyyy' : undefined : 'en' }} -
                            {{ season.endDate | date : 'dd.MM.yyyy' : undefined : 'en' }}
                        </div>
                    </td>
                    <td class="text-left" (click)="handleClickOnSeason(season.id)">
                        <span
                            [style.backgroundColor]="seasonStateBackgroundColor[season.status]"
                            class="px-4 py-1 text-black rounded-full"
                            >{{ season.status }}</span
                        >
                    </td>

                    <td (click)="handleClickOnSeason(season.id)">
                        <div>{{ season.updatedBy?.firstName }} {{ season.updatedBy?.lastName }}</div>
                    </td>
                    <td (click)="handleClickOnSeason(season.id)">
                        <div>
                            {{ season.updatedAt | date : 'dd.MM.yyyy - HH:mm' : undefined : 'en' }}
                        </div>
                    </td>
                    @if (isAdmin()) {
                    <td class="color-[#00cccc] cursor-pointer text-center rounded-tr-[10px] rounded-br-[10px]">
                        <span
                            class="icon-options-horizontal text-3xl text-[#00cccc] text-xl"
                            sag-ui-menu-trigger
                            menuTriggerFor="menu-season-{{ season.id }}"
                        ></span>
                        <sag-ui-menu menu="menu-season-{{ season.id }}" class="text-left p-0">
                            <ul class="text-white w-full bg-[#000028] rounded-[10px]">
                                <li
                                    class="bg-[#23233C] hover:bg-[#37374D] p-2 data-[disabled=true]:text-[#6d6d80]"
                                    [attr.data-disabled]="season.status === 'Active'"
                                    (click)="season.status !== 'Active' && editSeason(season.id)"
                                    sag-ui-tooltip
                                    [tooltip]="
                                        season.status === 'Active'
                                            ? ('seasons.season-overview.table.actions.edit-disabled' | translate)
                                            : undefined
                                    "
                                >
                                    <span class="icon-cut p-2"></span>
                                    <span>{{ 'seasons.season-overview.table.actions.edit' | translate }}</span>
                                </li>
                                <li
                                    class="bg-[#23233C] hover:bg-[#37374D] text-[#00cccc] p-2 data-[disabled=true]:text-[#6d6d80]"
                                    [attr.data-disabled]="season.status !== draftSeasonStatus"
                                    (click)="season.status === draftSeasonStatus && openSetActiveDialog(season.id)"
                                >
                                    <span class="icon-chevron-double-right p-2"></span>
                                    <span>{{ 'seasons.season-overview.table.actions.set-active' | translate }}</span>
                                </li>
                                <li
                                    class="bg-[#23233C] hover:bg-[#37374D] text-[#ff0000] my-0.5 p-2 data-[disabled=true]:text-[#6d6d80]"
                                    [attr.data-disabled]="season.status === activeSeasonStatus"
                                    (click)="season.status !== activeSeasonStatus && openDeleteDialog(season.id)"
                                >
                                    <span class="icon-close-large p-2"></span>
                                    <span>{{ 'seasons.season-overview.table.actions.delete' | translate }}</span>
                                </li>
                            </ul>
                        </sag-ui-menu>
                    </td>
                    }
                </tr>
                }
            </tbody>
        </table>
        @if (isInitalLoading()) {
        <div class="flex w-full h-full items-center justify-center">
            <sag-ui-spinner [size]="'lg'" class="scale-150"></sag-ui-spinner>
        </div>
        }
    </div>
</div>
