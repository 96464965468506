import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<SnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA)
        public data: { type: 'Success' | 'Warning' | 'Error' | 'Information'; title: string; desc?: string }
    ) {}

    infoIcon(): string {
        switch (this.data.type) {
            case 'Success':
                return 'icon-circle-checkmark';
            case 'Warning':
                return 'icon-circle-warning';
            case 'Error':
                return 'icon-circle-error';
            case 'Information':
                return 'icon-circle-information';
            default:
                return 'icon-circle-information';
        }
    }

    close(): void {
        this.snackBarRef.dismiss();
    }
}
