export enum SnackbarType {
    INFO = 'info',
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning'
}

export enum SnackbarPosition {
    TOP_LEFT = 'top-left',
    TOP = 'top',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM = 'bottom',
    BOTTOM_RIGHT = 'bottom-right'
}

export type SnackbarPositionType = 'top-left' | 'top' | 'top-right' | 'bottom-left' | 'bottom' | 'bottom-right';
