<div class="delete-user">
<h2 mat-dialog-title>{{ 'user-management.dialogs.delete-dialog.heading' | translate }}</h2>
<mat-dialog-content>
    <p>{{ 'user-management.dialogs.delete-dialog.subheading' | translate }} <b>{{data.firstName}} {{data.lastName}} ({{data.gid}}).</b></p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <sag-ui-button color="secondary" mat-dialog-close>
        {{ 'user-management.dialogs.delete-dialog.cancel' | translate }}
    </sag-ui-button>

    <sag-ui-button [mat-dialog-close]="true" color="primary" (click)="deleteUser()">
        <span class="icon icon-delete"></span>
        {{ 'user-management.dialogs.delete-dialog.confirm' | translate }}
    </sag-ui-button>
</mat-dialog-actions>
</div>
