import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
    ChangeSelectedFrontendType,
    ChangeSelectedLanguage,
    LoadAvailableLanguages
} from '../../states/translations-editor.actions';
import { FrontendType } from '../../models/frontend-type.model';

@Component({
    selector: 'app-translations-dashboard',
    templateUrl: './translations-dashboard.component.html',
    styleUrls: ['./translations-dashboard.component.scss']
})
export class TranslationsDashboardComponent implements OnInit {
    frontendTypes = Object.values(FrontendType);
    defaultFe = FrontendType.ADMIN;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.frontendTypes.forEach(feType => this.store.dispatch(new LoadAvailableLanguages({ frontendType: feType })));
        this.store.dispatch(new ChangeSelectedFrontendType({ frontendType: this.frontendTypes[0] }));
        this.store.dispatch(new ChangeSelectedLanguage({ lang: 'en' }));
    }

    onTabChangeEvent(event): void {
        this.store.dispatch(new ChangeSelectedFrontendType({ frontendType: event }));
        this.store.dispatch(new ChangeSelectedLanguage({ lang: 'en' }));
    }
}
