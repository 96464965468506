import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { DecrementStepper, IncrementStepper } from '../../../state/season.action';
import { WeightCounterComponent } from '../weight-counter/weight-counter.component';
import { CommonModule } from '@angular/common';
import { socialCriterias } from '../../../configs/social-criteria.config';
import { SocialCriteriaForm } from '../../../models/season-create.model';
import { ButtonModule } from '@siemens/component-lib';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SeasonFormGroup } from '../../../models/season-form.model';

@Component({
    selector: 'app-social-criteria',
    standalone: true,
    templateUrl: './social-criteria.component.html',
    imports: [WeightCounterComponent, CommonModule, ButtonModule, ReactiveFormsModule, TranslateModule]
})
export class SocialCriteriaComponent implements OnInit {
    @Input() public socialCriteria: SeasonFormGroup['socialCriteria'];
    @Input() public enableIcon = false;
    @Input() public isDisabled?: boolean;

    public socialCriterias = socialCriterias;

    public getCriterion(criterion: keyof SocialCriteriaForm): FormControl<number> {
        return this.socialCriteria.get(criterion) as FormControl<number>;
    }

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.setDisabledState();
    }

    private setDisabledState(): void {
        if (this.isDisabled) {
            this.socialCriteria.disable();
        } else {
            this.socialCriteria.enable();
        }
    }

    public onNext(): void {
        this.store.dispatch(new IncrementStepper());
    }

    public onBack(): void {
        this.store.dispatch(new DecrementStepper());
    }

    public toggleDisable(): void {
        this.isDisabled = !this.isDisabled;
    }
}
