import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogConfig, DialogRef } from '@siemens/component-lib';
import { SharedModule } from '../../../../shared/shared.module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
    selector: 'app-release-payout-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule, ReactiveFormsModule],
    templateUrl: './release-payout-dialog.component.html'
})
export class ReleasePayoutDialogComponent {
    protected formGroup = new FormGroup({
        confirm: new FormControl('', [Validators.pattern('Payout')])
    });

    constructor(public config: DialogConfig, public dialogRef: DialogRef<string>) {}

    public onClose(result?: string): void {
        this.dialogRef.close(result);
    }

    public formControlValid(controlName: string): boolean {
        return this.formGroup.get(controlName).valid;
    }

    public isValid(): boolean {
        return this.formGroup.valid && this.formGroup.get('confirm').value === 'Payout';
    }
}
