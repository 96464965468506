import { TranslationAddItem, TranslationEditItem } from '../models/translation-edits.model';
import { FrontendType } from '../models/frontend-type.model';

export class LoadAvailableLanguages {
    static readonly type = '[Translations Editor] Load Available Languages';

    constructor(public readonly payload: { frontendType: FrontendType }) {}
}

export class ChangeSelectedLanguage {
    static readonly type = '[Translations Editor] Change language';

    constructor(public readonly payload: { lang: string }) {}
}

export class AddTranslationItem {
    static readonly type = '[Translations Editor] Add Translation item';

    constructor(public readonly payload: { item: TranslationAddItem }) {}
}

export class UploadTranslationEditorChanges {
    static readonly type = '[Translations Editor] Upload Changes';
}

export class EditTranslationItem {
    static readonly type = '[Translations Editor] Edit Translation Item';

    constructor(public readonly payload: { item: TranslationEditItem }) {}
}

export class ChangeSelectedFrontendType {
    static readonly type = '[Translations Editor] Change selected Frontend Type';

    constructor(public readonly payload: { frontendType: FrontendType }) {}
}
export class MergeFromPreviousStage {
    static readonly type = '[Translations Editor] Merge from previous stage';
}
