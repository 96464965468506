<div class="p-6 pt-4">
    <h3 class="text-xl mb-2">{{ 'seasons.season-creation.dialogs.cancel-dialog.title' | translate }}</h3>
    <p class="text-base">{{ 'seasons.season-creation.dialogs.cancel-dialog.text' | translate }}</p>
    <div class="flex justify-end items-center gap-2 mt-8">
        <sag-ui-button color="secondary" icon="chevron-left" (click)="onClose()">
            {{ 'seasons.season-creation.dialogs.cancel-dialog.reject' | translate }}
        </sag-ui-button>
        <sag-ui-button icon="close" (click)="onClose('cancel')">
            {{ 'seasons.season-creation.dialogs.cancel-dialog.accept' | translate }}
        </sag-ui-button>
    </div>
</div>
