import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DeleteUserDialogData {
    gid: string;
    firstName: string;
    lastName: string;
}

@Component({
    selector: 'app-delete-user-dialog',
    templateUrl: './delete-user.component.html',
    styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DeleteUserDialogData,
        private dialogRef: MatDialogRef<DeleteUserComponent>
    ) {}

    deleteUser() {
        this.dialogRef.close({ confirmed: true });
    }

    cancel() {
        this.dialogRef.close({ confirmed: false });
    }
}
