/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable dot-notation */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'interfaces/src';
import { Roles } from '../../enums/roles.enum';

@Component({
    selector: 'app-edit-user-dialog',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent {
    protected editUserForm: FormGroup;
    protected loading = false;
    protected Roles = Roles;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: User,
        private dialogRef: MatDialogRef<EditUserComponent>,
        private formBuilder: FormBuilder
    ) {
        this.editUserForm = this.buildEditForm();
    }

    protected editUser(event: Event): void {
        event.stopPropagation();
        const userToEdit: User = {
            gid: this.editUserForm.controls['gid'].value,
            firstName: this.editUserForm.controls['firstName'].value,
            lastName: this.editUserForm.controls['lastName'].value,
            roles: this.editUserForm.controls['roles'].value,
            email: this.editUserForm.controls['email'].value
        };
        this.dialogRef.close({ confirmed: true, payload: userToEdit });
    }

    protected cancel() {
        this.dialogRef.close({ confirmed: false });
    }

    private buildEditForm(): FormGroup {
        return this.formBuilder.group({
            gid: [{ value: this.data.gid, disabled: true }],
            firstName: [this.data.firstName, Validators.required],
            lastName: [this.data.lastName, Validators.required],
            email: [this.data.email, [Validators.required, Validators.email]],
            roles: [this.data.roles]
        });
    }
}
