import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export class TimeFrameValidators {
    static endDateBeforeApplicationDeadline(): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const _endDate = formGroup.get('endDate')?.value;
            const _applicationDeadlineDate = formGroup.get('applicationDeadlineDate')?.value;

            const endDate = new Date(_endDate);
            const applicationDeadlineDate = new Date(_applicationDeadlineDate);

            if (_endDate && _applicationDeadlineDate && endDate > applicationDeadlineDate) {
                formGroup.get('applicationDeadlineDate')?.setErrors({ endDateAfterApplicationDeadline: true });
            }

            return null;
        };
    }
}
