import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule, DateRangePickerModule, InputModule } from '@siemens/component-lib';
import { Store } from '@ngxs/store';
import { IncrementStepper } from '../../../state/season.action';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, skip, tap } from 'rxjs';
import { SeasonFormGroup, SeasonTimeframeFormGroup } from '../../../models/season-form.model';

@Component({
    selector: 'app-timeframe',
    standalone: true,
    templateUrl: './timeframe.component.html',
    imports: [ButtonModule, InputModule, ReactiveFormsModule, CommonModule, TranslateModule, DateRangePickerModule]
})
export class TimeframeComponent implements OnInit {
    @Input() public timeframe: SeasonFormGroup['timeframe'];
    @Input() public enableIcon = false;
    @Input() public isDisabled?: boolean;

    public startDateError: string | null = null;
    public endDateError: string | null = null;
    public applicationDeadlineDateError: string | null = null;
    public startDateAndEndDateInputsShouldBeInvalid: boolean;
    public applicationDeadlineDateShouldBeInvalid: boolean;

    constructor(private store: Store, private router: Router, private destroyRef: DestroyRef) {}

    ngOnInit(): void {
        this.setDisabledState();
    }

    private setDisabledState(): void {
        if (this.isDisabled) {
            this.timeframe.disable();
        } else {
            this.timeframe.enable();
        }
    }

    public onNext(): void {
        this.store.dispatch(new IncrementStepper());
    }

    public onCancel(): void {
        this.router.navigate(['/']);
    }

    public toggleDisable(): void {
        this.isDisabled = !this.isDisabled;
    }

    protected isInvalid(controlName: keyof SeasonTimeframeFormGroup): boolean {
        const control = this.timeframe.controls[controlName];
        return control.invalid && (control.touched || control.dirty);
    }

    protected errorMessage(control: string): string {
        const errors = Object.keys(this.timeframe.get(control)?.errors || {});

        if (errors.length) {
            if (errors[0] === 'required' && !this.timeframe.get(control)?.touched) {
                return '';
            }

            return 'seasons.season-creation.errors.' + errors[0];
        }

        return '';
    }
}
