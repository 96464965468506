<div
    [class.opacity-50]="isDisabled"
    class="flex flex-row items-center justify-between border-b border-customBorder pb-2"
>
    <p>{{ label }}</p>
    <div class="flex items-center justify-between">
        <button class="p-2" (click)="decrement()" [disabled]="value <= min || isDisabled">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" [attr.fill]="value <= min ? '#737389' : '#00CCCC'" />
                <path d="M18.25 10.75H5.75V13.25H18.25V10.75Z" fill="#000028" />
            </svg>
        </button>
        {{ value }}
        <button class="p-2" (click)="increment()" [disabled]="value >= max || isDisabled">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" [attr.fill]="value >= max ? '#737389' : '#00CCCC'" />
                <path
                    d="M18.7379 13.1387V11.1387L13.1382 11.1387L13.1382 5.53906L11.1382 5.53906V11.1387L5.53857 11.1387L5.53857 13.1387H11.1382L11.1382 18.7384H13.1382L13.1382 13.1387H18.7379Z"
                    fill="#000028"
                />
            </svg>
        </button>
    </div>
</div>
