import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-table-button',
    templateUrl: './table-button.component.html',
    styleUrls: ['./table-button.component.scss']
})
export class TableButtonComponent {
    @Input() text: string;
    @Input() translate = false;
    @Input() type: 'primary' | 'secondary' | 'tertiary';
    @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    clicked(event: any) {
        event.stopPropagation();
        this.buttonClick.emit();
    }
}
