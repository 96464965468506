import { Component, Input } from '@angular/core';
import { ButtonModule, InputModule } from '@siemens/component-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { IncrementStepper } from '../../../state/season.action';
import { Router } from '@angular/router';
import { NgxsFormDirective } from '@ngxs/form-plugin';
import { TranslateModule } from '@ngx-translate/core';
import { SeasonFormGroup } from '../../../models/season-form.model';

@Component({
    selector: 'app-season-info',
    standalone: true,
    templateUrl: './season-info.component.html',
    imports: [ButtonModule, InputModule, ReactiveFormsModule, NgxsFormDirective, TranslateModule]
})
export class SeasonInfoComponent {
    @Input() public disable = false;
    @Input() public seasonInfo: SeasonFormGroup['seasonInfo'];

    constructor(private store: Store, private router: Router) {}

    public onNext(): void {
        this.store.dispatch(new IncrementStepper());
    }

    public onCancel(): void {
        this.router.navigate(['/']);
    }
}
