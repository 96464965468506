import { Injectable } from '@angular/core';
import { darkTheme, lightTheme } from '../../../assets/styles/Themes';
import cssVars from 'css-vars-ponyfill';
import { ITheme } from '../interfaces/ITheme';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    public setTheme(mode: 'light' | 'dark'): void {
        const variables = this.getThemeVariables(mode);
        this.setColorVariables(variables);
    }

    private getThemeVariables(mode: 'light' | 'dark'): ITheme {
        if (mode === 'dark') {
            return darkTheme;
        } else {
            return lightTheme;
        }
    }

    private setColorVariables(colorPalette: ITheme): void {
        cssVars({
            variables: {
                ...colorPalette
            }
        });
    }
}
