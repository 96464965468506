import { append, compose, iif, NoInfer, patch, StateOperator, updateItem } from '@ngxs/store/operators';

export function insertOrUpdateItem<T extends Record<string, any>>(
    newItem: NoInfer<T>,
    key: string
): StateOperator<T[]> {
    return iif<T[]>(
        items => items.some(item => item[key] === newItem[key]),
        updateItem<T>((item: T) => item[key] === newItem[key], patch<T>(newItem)),
        append<T>([newItem] as unknown as NoInfer<T[]>)
    );
}

export function appendItemsWithoutDuplicates<T extends Record<string, any>>(
    items: NoInfer<T>[],
    key: string
): StateOperator<T[]> {
    return compose<T[]>(...items.map(item => insertOrUpdateItem<T>(item, key)));
}
