import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, Observable, tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { WritableDraft, produce } from 'immer';
import { TranslationFile } from '../models/translation-file.model';
import { TranslationsService } from '../services/translations.service';
import { FrontendType } from '../models/frontend-type.model';
import { HttpHelperService } from '../../shared/services/http-helper.service';
import { TranslationsOperation } from '../operations/translations.operation';
import { TranslationAddItem } from '../models/translation-edits.model';
import {
    AddTranslationItem,
    ChangeSelectedFrontendType,
    ChangeSelectedLanguage,
    EditTranslationItem,
    LoadAvailableLanguages,
    MergeFromPreviousStage,
    UploadTranslationEditorChanges
} from './translations-editor.actions';
import { SnackbarService } from '@siemens/component-lib';
import { SnackbarPosition, SnackbarType } from '../../shared/enums/snackbar.enum';

export interface TranslationsEditorStateModel {
    isLoading: boolean;
    selectedLanguage: string;
    selectedFrontendType?: FrontendType;
    translationFiles: Partial<Record<FrontendType, TranslationFile[]>>;
    availableLanguages: Partial<Record<FrontendType, string[]>>;
    dirtyLanguages: Partial<Record<FrontendType, string[]>>;
}

@State<TranslationsEditorStateModel>({
    name: 'translationsEditorState',
    defaults: {
        isLoading: false,
        selectedLanguage: 'en',
        translationFiles: {},
        availableLanguages: {},
        dirtyLanguages: {}
    }
})
@Injectable()
export class TranslationsEditorState {
    constructor(
        private translationsService: TranslationsService,
        private store: Store,
        private httpHelperService: HttpHelperService,
        private snackbarService: SnackbarService
    ) {}

    @Selector()
    static isLoading(state: TranslationsEditorStateModel): boolean {
        return state.isLoading;
    }

    @Selector()
    static selectedLanguage(state: TranslationsEditorStateModel): string {
        return state.selectedLanguage;
    }

    @Selector()
    static translationFiles(state: TranslationsEditorStateModel): TranslationFile[] {
        if (state.selectedFrontendType) return state.translationFiles[state.selectedFrontendType] ?? [];
        return [];
    }

    @Selector()
    static selectedTranslationFile(state: TranslationsEditorStateModel): TranslationFile | undefined {
        if (state.selectedFrontendType)
            return TranslationsEditorState.findFileByFeAndLang(
                state.translationFiles,
                state.selectedFrontendType,
                state.selectedLanguage
            );
        return undefined;
    }

    @Selector()
    static selectedFrontendType(state: TranslationsEditorStateModel): FrontendType | undefined {
        return state.selectedFrontendType;
    }

    @Selector()
    static availableLanguages(state: TranslationsEditorStateModel): string[] {
        if (state.selectedFrontendType) return state.availableLanguages[state.selectedFrontendType] ?? [];
        return [];
    }

    @Action(LoadAvailableLanguages)
    loadAvailableLanguages(
        ctx: StateContext<TranslationsEditorStateModel>,
        { payload }: LoadAvailableLanguages
    ): Observable<string[]> {
        return this.translationsService.getAvailableLanguages(payload.frontendType).pipe(
            this.httpHelperService.handleError('Could not fetch available languages'),
            tap((availableLangs: string[]) =>
                ctx.setState(
                    produce(ctx.getState(), (state: any) => {
                        state.availableLanguages[payload.frontendType] = availableLangs;
                    })
                )
            )
        );
    }

    @Action(ChangeSelectedLanguage)
    changeSelectedLanguage(ctx: StateContext<TranslationsEditorStateModel>, { payload }: ChangeSelectedLanguage): void {
        const ctxState = ctx.getState();
        if (!ctxState.selectedFrontendType) {
            return;
        }
        const currentTranslationFiles = ctxState.translationFiles[ctxState.selectedFrontendType];
        if (
            !currentTranslationFiles ||
            currentTranslationFiles.findIndex(file => file.languageKey === payload.lang) === -1
        ) {
            // The translationFile hasn't yet been loaded.
            ctx.patchState({ isLoading: true });
            this.translationsService
                .getTranslations(payload.lang, ctxState.selectedFrontendType)
                .pipe(
                    tap((file: TranslationFile) =>
                        ctx.setState(
                            produce(ctx.getState(), (state: any) => {
                                state.isLoading = false;
                                if (!ctxState.selectedFrontendType) {
                                    return;
                                }
                                if (state.translationFiles[ctxState.selectedFrontendType]) {
                                    state.translationFiles[ctxState.selectedFrontendType].push(file);
                                } else {
                                    state.translationFiles[ctxState.selectedFrontendType] = [file];
                                }
                            })
                        )
                    )
                )
                .subscribe();
        }
        ctx.patchState({ selectedLanguage: payload.lang });
    }

    @Action(ChangeSelectedFrontendType)
    changeSelectedFrontendType(
        ctx: StateContext<TranslationsEditorStateModel>,
        { payload }: ChangeSelectedFrontendType
    ): void {
        ctx.patchState({ selectedFrontendType: payload.frontendType });
    }

    @Action(AddTranslationItem)
    addTranslationItem(ctx: StateContext<TranslationsEditorStateModel>, { payload }: AddTranslationItem): void {
        const item = payload.item;
        const state = ctx.getState();
        if (!state.selectedFrontendType) {
            return;
        }
        const file = state.translationFiles[state.selectedFrontendType];
        const translationFiles: TranslationFile[] = file ? JSON.parse(JSON.stringify(file)) : undefined;

        if (!item.route) {
            return;
        }

        // For each translationFile, update the state
        for (const file of translationFiles) {
            if (item.translations) {
                // New item
                TranslationsOperation.updateItemAtRouteByRef(
                    file,
                    item.route,
                    item.title,
                    item.translations[file.languageKey] ?? item.translations.en,
                    true
                );
            } else {
                // New topic
                TranslationsOperation.updateItemAtRouteByRef(file, item.route, item.title, {}, true);
            }
        }

        this.updateItemInBE(ctx, translationFiles, item);
    }

    @Action(UploadTranslationEditorChanges)
    uploadTranslationEditorChanges(ctx: StateContext<TranslationsEditorStateModel>): Observable<void[]> {
        return forkJoin(
            this.dirtyLanguagesToTranslationFiles(ctx.getState()).flatMap(
                (data: [FrontendType, (TranslationFile | undefined)[]]) => {
                    // For every file that has been edited, in every FrontendType, we update the changes in the BE
                    return (data[1].filter(file => file !== undefined) as TranslationFile[]).map(file =>
                        this.translationsService.updateTranslationFile(data[0], file)
                    );
                }
            )
        ).pipe(
            tap(() =>
                ctx.patchState({
                    // All files have been updated: no more dirty files
                    dirtyLanguages: {}
                })
            )
        );
    }

    @Action(EditTranslationItem)
    editTranslationItem(ctx: StateContext<TranslationsEditorStateModel>, { payload }: EditTranslationItem): void {
        const item = payload.item;
        const ctxState = ctx.getState();

        if (!ctxState.selectedFrontendType) return;

        const file = TranslationsEditorState.findFileByFeAndLang(
            ctxState.translationFiles,
            ctxState.selectedFrontendType,
            item.languageKey
        );
        const translationFile: TranslationFile = file ? JSON.parse(JSON.stringify(file)) : undefined;

        TranslationsOperation.updateItemAtRouteByRef(translationFile, item.route, item.key, item.value);

        ctx.setState(
            produce(ctx.getState(), (state: WritableDraft<TranslationsEditorStateModel>) => {
                const file = TranslationsEditorState.findFileByFeAndLang(
                    state.translationFiles,
                    state.selectedFrontendType,
                    item.languageKey
                );
                if (file && file.file) {
                    file.file = translationFile.file;
                }

                // As the current file has been edited, mark the file as "dirty"
                if (!state.dirtyLanguages[state.selectedFrontendType]) {
                    state.dirtyLanguages[state.selectedFrontendType] = [state.selectedLanguage];
                } else if (!state.dirtyLanguages[state.selectedFrontendType].includes(state.selectedLanguage)) {
                    state.dirtyLanguages[state.selectedFrontendType].push(state.selectedLanguage);
                }
            })
        );
    }

    @Action(MergeFromPreviousStage)
    mergeFromPreviousStage(ctx: StateContext<TranslationsEditorStateModel>): void {
        if (ctx.getState().selectedFrontendType === undefined || ctx.getState().selectedFrontendType === null) {
            return;
        }
        ctx.patchState({ isLoading: true });
        this.translationsService
            .mergeFromPreviousStage(ctx.getState().selectedFrontendType!)
            .pipe(
                tap(() => {
                    // Once merge done, clear cached translations for current FE type, and reload current language
                    ctx.setState(
                        produce(ctx.getState(), (state: any) => {
                            state.isLoading = false;
                            state.translationFiles[ctx.getState().selectedFrontendType!] = [];
                        })
                    );
                    this.store.dispatch(new ChangeSelectedLanguage({ lang: ctx.getState().selectedLanguage }));
                })
            )
            .subscribe();
    }

    private updateItemInBE(
        ctx: StateContext<TranslationsEditorStateModel>,
        translationFiles: TranslationFile[],
        item: TranslationAddItem
    ): void {
        const state = ctx.getState();
        // Update the files in the BE
        if (!state.selectedFrontendType) return;
        this.translationsService
            .addTranslationItem(state.selectedFrontendType, item)
            .pipe(
                tap(() =>
                    ctx.setState(
                        patch({
                            translationFiles: patch({ [state.selectedFrontendType as string]: translationFiles })
                        })
                    )
                )
            )
            .subscribe();
    }

    private dirtyLanguagesToTranslationFiles(
        state: TranslationsEditorStateModel
    ): Array<[FrontendType, (TranslationFile | undefined)[]]> {
        const dirtyLanguages = state.dirtyLanguages;
        const translationFiles = state.translationFiles;
        const mapped: Array<[FrontendType, (TranslationFile | undefined)[]]> = (
            Object.entries(dirtyLanguages) as Array<[FrontendType, string[]]>
        ).map((data: [FrontendType, string[]]) => [
            data[0],
            data[1].map(langISO => TranslationsEditorState.findFileByFeAndLang(translationFiles, data[0], langISO))
        ]);
        if (mapped.length === 0) {
            this.snackbarService.open({
                title: 'No changes',
                description: 'No changes are detected in master file content for any languages',
                type: SnackbarType.INFO,
                duration: 3000,
                position: SnackbarPosition.BOTTOM
            });
        }

        return mapped;
    }

    /**
     * Finds a Translation File, searching by its Frontend Type and its language ISO code
     * @param translationFiles list of all the files, with their FrontendType
     * @param feType Frontend type of the file to find
     * @param langISO language of the file to find
     * @private
     */
    private static findFileByFeAndLang(
        translationFiles: Partial<Record<FrontendType, TranslationFile[]>>,
        feType: FrontendType,
        langISO: string
    ): TranslationFile | undefined {
        return translationFiles[feType]?.find(file => file.languageKey === langISO);
    }
}
