import { Inject } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';
import { Roles, User } from 'interfaces/src';
import { filter, tap } from 'rxjs';
import { SCDService, SCDUser } from '../services/scd.service';

export type SetFormFieldMap = Partial<User>;
export class UserFormBuilder extends FormBuilder {
    private formGroup: FormGroup;
    private userReturned: SCDUser;
    public userExistsInScd = false;
    constructor(
        controls: any,
        options?: AbstractControlOptions | null,
        private useScd?: boolean,
        private scdService?: SCDService
    ) {
        super();
        this.formGroup = super.group(controls, options);
    }

    public get form(): FormGroup | undefined {
        return this.formGroup;
    }

    public async getUserInfoSCD(field: string): Promise<void> {
        if (this.useScd) {
            try {
                const formGID = this.form!.controls['gid']?.value;
                const formEmail = this.form!.controls['email']?.value;
                if (
                    field === 'gid' &&
                    !!formGID &&
                    !this.form!.controls[field].invalid &&
                    !this.form!.controls[field].errors
                ) {
                    this.autoFillFromGid(formGID);
                } else if (
                    field === 'email' &&
                    !!formEmail &&
                    !(this.form!.controls[field].errors || this.form!.controls[field].invalid)
                ) {
                    this.autoFillFromEmail(formEmail);
                } else {
                    this.blankAllControlsExcept(field);
                }
            } catch (error) {
                this.form!.enable();
                console.log('Project is not registered to SCD yet', error);
            }
        }
    }

    private async autoFillFromGid(gid: string) {
        this.form!.disable();
        this.form!.get('roles')?.enable();
        this.scdService!.getUserByGid(gid)
            .pipe(
                filter(user => !!user),
                tap(user => {
                    this.userReturned ??= user;
                    if (this.userReturned?.gid) {
                        this.fillFields('gid');
                    }
                })
            )
            .subscribe();
    }

    private async autoFillFromEmail(email: string) {
        this.form!.disable();
        this.form!.get('roles')?.enable();
        this.scdService!.getUserByGid(email)
            .pipe(
                filter(user => !!user),
                tap(user => {
                    this.userReturned ??= user;
                    if (this.userReturned?.gid) {
                        this.fillFields('email');
                    }
                })
            )
            .subscribe();
    }

    private fillFields(field: string) {
        this.userExistsInScd = !!this.userReturned;
        if (this.userExistsInScd) {
            const fieldMap = {
                firstName: this.userReturned?.firstName,
                lastName: this.userReturned?.surName,
                orgCode: this.userReturned?.department
            } as SetFormFieldMap;
            if (field === 'gid') {
                fieldMap.email = this.userReturned.mail;
            } else {
                fieldMap.gid = this.userReturned.gid;
            }
            this.setAndDisableControls(fieldMap);
        } else {
            this.blankAllControlsExcept(field);
        }
    }

    private setAndDisableControls(values: SetFormFieldMap): void {
        Object.entries(values).forEach(([key, value]) => {
            this.setControlValueAndDisable(key, value as string | Roles[]);
        });
    }

    private setControlValueAndDisable(name: string, value: string | Roles[]): void {
        const GID = this.form!.controls['gid'].value!.toUpperCase();
        if (GID) {
            this.form!.controls['gid'].setValue(GID);
        }
        this.form!.controls[name].setValue(value as string);
        this.form!.controls[name].disable();
    }

    private blankAllControlsExcept(field: string) {
        const controls = ['firstName', 'lastName', 'email', 'gid'];
        controls.splice(controls.indexOf(field));
        this.blankControls(controls);
    }

    private blankControls(values: string[]): void {
        values.forEach(control => this.initializeControl(control));
    }

    private initializeControl(name: string): void {
        this.form!.controls[name].setValue('');
        this.form!.controls[name].enable();
    }
}
