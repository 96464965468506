import { GbrUser } from 'interfaces/src';

export class SetUser {
    static readonly type = '[User] Set User';
    constructor(public readonly payload: { user: GbrUser; isAuthenticated: boolean }) {}
}

export class LoadProfilePicture {
    static readonly type = '[User] Load Profile Picture';
}
