import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { AddTranslationItemComponent } from '../../dialogs/add-translation-item/add-translation-item.component';
import { TranslationAddItem, TranslationEditItem } from '../../models/translation-edits.model';
import { AddTranslationItem, EditTranslationItem } from '../../states/translations-editor.actions';
import { AddTopicComponent } from '../../dialogs/add-topic/add-topic.component';
import { FrontendType } from '../../models/frontend-type.model';

@Component({
    selector: 'app-translation-part',
    templateUrl: './translation-part.component.html',
    styleUrls: ['./translation-part.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationPartComponent implements OnInit, OnChanges {
    @Input() parent = '';
    @Input() allLanguages: string[]; // list of iso codes of all the supported languages
    @Input() selectedLanguage: string; // selected language as iso code
    @Input() frontendType: FrontendType;
    @Input() items: Record<string, any>;

    public itemsWithChildren: Record<string, any> = {};
    public itemsWithoutChildren: Record<string, any> = {};

    constructor(private dialog: MatDialog, private store: Store, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.initItemLists();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const languageChanged =
            changes.selectedLanguage &&
            changes.selectedLanguage.currentValue !== changes.selectedLanguage.previousValue;
        const frontendTypeChanged =
            changes.frontendType && changes.frontendType.currentValue !== changes.frontendType.previousValue;
        if (languageChanged || frontendTypeChanged) {
            this.initItemLists();
        }
    }

    private initItemLists(): void {
        this.itemsWithChildren = {};
        this.itemsWithoutChildren = {};

        for (const key in this.items) {
            if (this.items.hasOwnProperty.call(this.items, key)) {
                if (typeof this.items[key] === 'string') {
                    this.itemsWithoutChildren[key] = this.items[key];
                } else {
                    this.itemsWithChildren[key] = this.items[key];
                }
            }
        }
    }

    /** Add new item to language array */
    addItem(): void {
        this.dialog
            .open(AddTranslationItemComponent, {
                data: this.allLanguages,
                maxHeight: '90vh',
                panelClass: 'translations-modal'
            })
            .afterClosed()
            .pipe(
                filter(data => !!data),
                filter((item: TranslationAddItem) => !this.itemsWithoutChildren[item.title]),
                tap((item: TranslationAddItem) => (item.route = this.parent.split('.'))),
                tap((item: TranslationAddItem) => this.store.dispatch(new AddTranslationItem({ item }))),
                tap(
                    (item: TranslationAddItem) =>
                        (this.itemsWithoutChildren[item.title] =
                            item.translations && item.translations[this.selectedLanguage]
                                ? item.translations[this.selectedLanguage]
                                : item.translations?.en)
                ),
                tap(() => this.changeDetectorRef.markForCheck())
            )
            .subscribe();
    }

    /** Add new topic to language array */
    addTopic(): void {
        this.dialog
            .open(AddTopicComponent, { panelClass: 'translations-modal', width: '600px' })
            .afterClosed()
            .pipe(
                filter(data => !!data),
                filter((item: TranslationAddItem) => !this.itemsWithChildren[item.title]),
                tap((item: TranslationAddItem) => (item.route = this.parent === '' ? [] : this.parent.split('.'))),
                tap((item: TranslationAddItem) => this.store.dispatch(new AddTranslationItem({ item }))),
                tap((item: TranslationAddItem) => (this.itemsWithChildren[item.title] = {})),
                tap(() => this.changeDetectorRef.markForCheck())
            )
            .subscribe();
    }

    public changeOfValue(key: any, value: string): void {
        const item: TranslationEditItem = {
            value,
            key,
            route: this.parent.split('.'),
            languageKey: this.selectedLanguage
        };

        if (this.itemsWithoutChildren[key] !== value) {
            // The values changed
            this.store
                .dispatch(new EditTranslationItem({ item }))
                .pipe(tap(() => (this.itemsWithoutChildren[key] = value)))
                .subscribe();
        }
    }
}
