<sag-ui-button color="tertiary" [routerLink]="['/']" icon="chevron-left" class="mt-4">
    {{ 'general.back-to-dashboard' | translate }}
</sag-ui-button>

<app-user-management
    [isLoading]="(loading$ | async)!"
    [users]="(users$ | async)!"
    [total]="(total$ | async)!"
    (searchChanged)="updateSearch($event)"
    (userAdded)="addUser($event)"
    (userEdited)="editUser($event)"
    (userDeleted)="deleteUser($event)"
    (pageChange)="pageChange($event)"
    [page]="(pageOptions$ | async)?.page + 1"
    [pageSizeOptions]="pageSizeOptions"
></app-user-management>
