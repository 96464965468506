<div class="language-selector">
    <div *ngIf="(availableLanguages$ | async).length > 0">
        <div class="lang-list">
            <sag-ui-button
                *ngFor="let lang of availableLanguages$ | async"
                [color]="lang === (selectedLanguage$ | async) ? 'primary' : 'secondary'"
                (click)="changeLanguage(lang)">
                {{ 'translations.' + lang.replace('.json','') | translate }}
            </sag-ui-button>
        </div>
    </div>

    <sag-ui-button (click)="addNewLanguage()" color="primary" icon="plus-large"> {{ 'translations.buttons.add-language' | translate }} </sag-ui-button>
</div>
<div *ngIf="selectedTranslationFile$ | async">
    <div class="top-container">
        <h3>{{ 'translations.master-file-content' | translate }}</h3>
        <div class="buttons">
            <sag-ui-button color="secondary" *ngIf="previousStageMerge" (click)="mergeFrom()" icon="chevron-double-right"> {{ ('translations.merge-from' | translate) + '-' + (previousStageMerge | lowercase) }} </sag-ui-button>
            <sag-ui-button color="primary" (click)="uploadChanges()" icon="upload"> {{ 'translations.buttons.upload' | translate }} </sag-ui-button>
            <sag-ui-button color="primary" (click)="createInvalidation()" icon="share-standard"> {{ 'translations.buttons.create-invalidation' | translate }} </sag-ui-button>
        </div>
    </div>
    <div class="translation-content-container">
        <mat-accordion>
            <app-translation-part
                [selectedLanguage]="selectedLanguage$ | async"
                [frontendType]="frontendType$ | async"
                [allLanguages]="availableLanguages$ | async"
                [items]="(selectedTranslationFile$ | async)!.file">
            </app-translation-part>
        </mat-accordion>
    </div>
</div>
@if ((selectedTranslationFile$ | async) === undefined) {
    <div class="flex w-full h-[50vh] items-center justify-center">
        <sag-ui-spinner [size]="'lg'" class="scale-150"></sag-ui-spinner>
    </div>
}