import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeasonInfoComponent } from '../season-creation-season-info/season-info.component';
import { ButtonModule, DialogService, SnackbarService, SpinnerModule, StepperModule } from '@siemens/component-lib';
import { Store } from '@ngxs/store';
import { SeasonState } from '../../../state/season.state';
import { EMPTY, Observable, catchError, filter, map, skip, take, tap } from 'rxjs';
import { TimeframeComponent } from '../season-creation-timeframe/timeframe.component';
import { CREATE_SEASON_STEPS } from './config';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DecrementStepper,
    IncrementStepper,
    LoadSeason,
    ResetSeasonForm,
    ResetStepper,
    SetStepper,
    SubmitForm
} from '../../../state/season.action';
import { BudgetComponent } from '../season-creation-budget/budget.component';
import { SeasonCreateOperations } from '../../../operations/season-creation.operations';
import { SeasonFormStepper } from '../../../models/season-create.model';
import { SocialCriteriaComponent } from '../season-creation-social-criteria/social-criteria.component';
import { SalaryWeightComponent } from '../season-creation-salary-weight/salary-weight.component';
import { SummaryComponent } from '../season-creation-summary/summary.component';
import { NgxsFormDirective } from '@ngxs/form-plugin';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Season } from 'libs/interfaces/src';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SeasonFormGroup } from '../../../models/season-form.model';
import { CancleSeasonCreationDialogComponent } from '../../../dialogs/cancel-application/cancel-application.component';
import { SnackbarConfig } from '../../../../shared/configs/snackbar.config';

@Component({
    selector: 'app-season-create-edit',
    standalone: true,
    imports: [
        CommonModule,
        StepperModule,
        ButtonModule,
        SeasonInfoComponent,
        TimeframeComponent,
        BudgetComponent,
        SocialCriteriaComponent,
        SalaryWeightComponent,
        SummaryComponent,
        NgxsFormDirective,
        ReactiveFormsModule,
        SpinnerModule,
        TranslateModule
    ],
    templateUrl: './season-create-edit.component.html'
})
export class SeasonCreateEditComponent implements OnInit, OnDestroy {
    protected seasonStepper$: Observable<SeasonFormStepper> = inject(Store).select(SeasonState.getFormStepper);
    protected season$: Observable<Season> = inject(Store).select(SeasonState.getSeason);
    protected isSavingSeason$: Observable<boolean> = inject(Store).select(SeasonState.isSavingSeason);

    public steps = this.translatedSteps();
    public currentSeasonTitle: string | null = null;
    public seasonCreationForm: FormGroup;
    public currentStepIndex: number;
    public hasSeasonLoaded = false;

    public get seasonInfo(): SeasonFormGroup['seasonInfo'] {
        return this.seasonCreationForm?.get('seasonInfo') as SeasonFormGroup['seasonInfo'];
    }

    public get timeframe(): SeasonFormGroup['timeframe'] {
        return this.seasonCreationForm?.get('timeframe') as SeasonFormGroup['timeframe'];
    }

    public get budget(): SeasonFormGroup['budget'] {
        return this.seasonCreationForm?.get('budget') as SeasonFormGroup['budget'];
    }

    public get socialCriteria(): SeasonFormGroup['socialCriteria'] {
        return this.seasonCreationForm?.get('socialCriteria') as SeasonFormGroup['socialCriteria'];
    }

    public get salaryWeight(): SeasonFormGroup['salary'] {
        return this.seasonCreationForm?.get('salary') as SeasonFormGroup['salary'];
    }

    public get isFromValidInCorrectStep(): boolean {
        switch (this.currentStepIndex) {
            case 0:
                return this.seasonInfo.valid;
            case 1:
                return this.timeframe.valid;
            case 2:
                return this.budget.valid;
            case 3:
                return this.socialCriteria.valid;
            case 4:
                return this.salaryWeight.valid;
            default:
                return true;
        }
    }

    constructor(
        private store: Store,
        private router: Router,
        private destroyRef: DestroyRef,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private dialog: DialogService,
        private snackbar: SnackbarService
    ) {}

    private isStepInvalid(step: number): boolean {
        switch (step) {
            case 0:
                return this.seasonInfo.invalid;
            case 1:
                return this.timeframe.invalid;
            case 2:
                return this.budget.invalid;
            case 3:
                return this.socialCriteria.invalid;
            case 4:
                return this.salaryWeight.invalid;
            default:
                return false;
        }
    }

    public ngOnInit(): void {
        this.listenToSeason();
        this.loadData();
        this.listenToSteps();
        this.listenToLanguageChange();
    }

    public ngOnDestroy(): void {
        this.store.dispatch(new ResetStepper());
        this.store.dispatch(new ResetSeasonForm());
    }

    public onCancel(): void {
        this.dialog
            .open(CancleSeasonCreationDialogComponent, {})
            .afterClosed.pipe(
                filter(result => result === 'cancel'),
                tap(() => {
                    this.router.navigate(['/']);
                    this.store.dispatch(new ResetStepper());
                    this.store.dispatch(new ResetSeasonForm());
                })
            )
            .subscribe();
    }

    public onNext(): void {
        this.store.dispatch(new IncrementStepper());
    }

    public onBack(): void {
        this.store.dispatch(new DecrementStepper());
    }

    public onSubmit(): void {
        this.store
            .dispatch(new SubmitForm())
            .pipe(
                tap(() => {
                    this.router.navigate(['/season/success']);
                    this.store.dispatch(new ResetStepper());
                    this.store.dispatch(new ResetSeasonForm());
                }),
                catchError(error => {
                    switch (error.error.message) {
                        case 'Specified start and end date cannot cross the ending of a season.':
                            this.snackbar.open({
                                type: 'error',
                                description: this.translate.instant(
                                    'seasons.season-creation.errors.invalid-timeframe.description'
                                ),
                                title: this.translate.instant('seasons.season-creation.errors.invalid-timeframe.title'),
                                duration: SnackbarConfig.duration,
                                position: 'bottom'
                            });
                            break;
                        default:
                            this.snackbar.open({
                                type: 'error',
                                description: this.translate.instant(
                                    'seasons.season-creation.errors.general.description'
                                ),
                                title: this.translate.instant('seasons.season-creation.errors.general.title'),
                                duration: SnackbarConfig.duration,
                                position: 'bottom'
                            });
                            break;
                    }

                    return EMPTY;
                })
            )
            .subscribe();
    }

    public handleNavigateToStep(step: number): void {
        // Generate an array indicating the validity of each step.
        const stepValidity = Array.from({ length: CREATE_SEASON_STEPS.length }, (_, i) => i).map(
            i => !this.isStepInvalid(i)
        );

        // Find the index of the first invalid step, or set to the total number of steps if all are valid.
        let highestInvalidStep = stepValidity.findIndex(valid => !valid);
        highestInvalidStep = highestInvalidStep === -1 ? CREATE_SEASON_STEPS.length : highestInvalidStep;

        // If the requested step is within the valid range, dispatch the action to update the step.
        if (step <= highestInvalidStep) {
            this.store.dispatch(new SetStepper(step));
        }
    }

    private initializeForm(season?: Season): void {
        this.seasonCreationForm = SeasonCreateOperations.initializeForm(season);
        this.hasSeasonLoaded = true;
    }

    private loadData(): void {
        this.route.params
            .pipe(
                take(1),
                map(params => params['seasonId']),
                tap(seasonId => {
                    if (seasonId) {
                        this.store.dispatch(new LoadSeason({ seasonId }));
                    } else {
                        this.initializeForm();
                    }
                })
            )
            .subscribe();
    }

    private listenToSeason(): void {
        this.season$
            .pipe(
                skip(1),
                take(1),
                tap(season => this.initializeForm(season))
            )
            .subscribe();
    }

    private listenToSteps(): void {
        this.seasonStepper$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(stepper => (this.currentStepIndex = stepper.selected))
            )
            .subscribe();
    }

    private listenToLanguageChange(): void {
        this.translate.onLangChange
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => (this.steps = this.translatedSteps()))
            )
            .subscribe();
    }

    private translatedSteps(): typeof CREATE_SEASON_STEPS {
        return CREATE_SEASON_STEPS.map(step => ({
            ...step,
            label: this.translate.instant(step.label)
        }));
    }
}
