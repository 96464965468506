<div>
    <h2 class="text-xl font-bold">{{ 'seasons.season-creation.summary.title' | translate }}</h2>
    <p class="text-base text-[#F3F3F0] mb-8">{{ 'seasons.season-creation.summary.subtitle' | translate }}</p>

    <div class="border-b border-customBorder mb-8"></div>

    <div class="border-b border-customBorder mb-8">
        <app-timeframe [timeframe]="timeframe" [enableIcon]="true" [isDisabled]="true"></app-timeframe>
    </div>
    <div class="border-b border-customBorder mb-8">
        <app-budget [budget]="budget" [enableIcon]="true" [isDisabled]="true"></app-budget>
    </div>
    <div class="mb-8 border-r-customBorder">
        <app-social-criteria
            [socialCriteria]="socialCriteria"
            [enableIcon]="true"
            [isDisabled]="true"
        ></app-social-criteria>
    </div>
    <div class="mb-8 border-customBorder">
        <app-salary-weight [salaryWeight]="salaryWeight" [enableIcon]="true" [isDisabled]="true"></app-salary-weight>
    </div>
</div>
