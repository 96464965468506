import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Dinero } from 'dinero.js';

@Component({
    selector: 'app-financial-card',
    standalone: true,
    templateUrl: './financial-card.component.html',
    imports: [CommonModule]
})
export class FinancialCardComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() amount: number | Dinero;
    @Input() ammountShouldBeGreen: boolean;

    protected get amountValue(): string {
        return typeof this.amount === 'number' ? this.amount.toString() : this.amount.toFormat();
    }
}
