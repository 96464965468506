<div class="content pb-4">
    <sag-ui-button color="tertiary" [routerLink]="['/']" icon="chevron-left" class="my-4 -ml-4">
        {{ 'general.back-to-dashboard' | translate }}
    </sag-ui-button>

    <sag-ui-tabview [value]="defaultFe" (change)="onTabChangeEvent($event)">
        <sag-ui-tab
            *ngFor="let feType of frontendTypes"
            [value]="feType"
            [label]="'translations.types.' + feType | translate"
        ></sag-ui-tab>
    </sag-ui-tabview>

    <app-translation-view></app-translation-view>
</div>
