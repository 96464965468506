import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';
import { TokenInjector } from './services/token-injector.interceptor';
import { AuthGmsRoutingModule } from './auth-routing.module';
import { UnauthorizedComponent } from 'shared-ui';

@NgModule({
    declarations: [],
    exports: [AuthModule],
    imports: [
        UnauthorizedComponent,
        AuthGmsRoutingModule,
        AuthModule.forRoot({
            config: environment.authentication
        })
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true }]
})
export class AuthGmsAngularClientModule {}
