import { SocialCriteriaForm } from '../models/season-create.model';

export const socialCriterias: { socialCriteriaLabel: string; criterion: keyof SocialCriteriaForm }[] = [
    {
        socialCriteriaLabel: 'seasons.season-creation.social-criteria.parental-leave',
        criterion: 'parentalLeave'
    },
    {
        socialCriteriaLabel: 'seasons.season-creation.social-criteria.percentage-of-employment',
        criterion: 'percentageOfEmployment'
    },
    {
        socialCriteriaLabel: 'seasons.season-creation.social-criteria.severe-disability-employee',
        criterion: 'severeDisability'
    },
    {
        socialCriteriaLabel: 'seasons.season-creation.social-criteria.shift-worker',
        criterion: 'shiftWorker'
    },
    {
        socialCriteriaLabel: 'seasons.season-creation.social-criteria.single-parent',
        criterion: 'singleParent'
    },
    {
        socialCriteriaLabel: 'seasons.season-creation.social-criteria.caregiver',
        criterion: 'caregiver'
    },
    {
        socialCriteriaLabel: 'seasons.season-creation.social-criteria.severe-disability-spouse',
        criterion: 'severeDisabilityOfTheSpouse'
    },
    {
        socialCriteriaLabel: 'seasons.season-creation.social-criteria.severe-disability-child',
        criterion: 'severeDisabilityOfTheChild'
    },
    {
        socialCriteriaLabel: 'seasons.season-creation.social-criteria.eligible-for-child-benefit',
        criterion: 'isElegibleForChildBenefit'
    }
];
