import { TableColumn } from './components/table/table.component';
import { User } from 'interfaces/src';

export const columns: TableColumn<User>[] = [
    {
        name: 'GID',
        selector: 'gid',
        row: (row: User) => row.gid,
        width: '10vh'
    },
    {
        name: 'Name',
        selector: 'name',
        row: (row: User) => `${row.firstName} ${row.lastName}`,
        width: '20vh'
    },
    {
        name: 'E-Mail',
        selector: 'email',
        row: (row: User) => row.email,
        width: '20vh'
    },
    {
        name: 'Roles',
        selector: 'roles',
        row: (row: User) => row.roles.join(', '),
        width: '30vh'
    }
];
