import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationAddItem } from '../../models/translation-edits.model';

@Component({
    selector: 'app-add-topic',
    templateUrl: './add-topic.component.html',
    styleUrls: ['./add-topic.component.scss']
})
export class AddTopicComponent {
    data: TranslationAddItem = {
        title: ''
    };

    constructor(private dialogRef: MatDialogRef<AddTopicComponent>) {}

    proceed(): void {
        if (this.data.title && this.data.title !== '') {
            this.dialogRef.close(this.data);
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
