import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { map, Observable, switchMap, tap, withLatestFrom } from 'rxjs';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { TranslationsEditorState } from '../../states/translations-editor.state';
import { TranslationFile } from '../../models/translation-file.model';
import {
    ChangeSelectedLanguage,
    LoadAvailableLanguages,
    MergeFromPreviousStage,
    UploadTranslationEditorChanges
} from '../../states/translations-editor.actions';
import { FrontendType } from '../../models/frontend-type.model';
import { AddLanguageComponent } from '../../dialogs/add-language/add-language.component';
import { TranslationsService } from '../../services/translations.service';
import { EnvironmentType } from '../../../shared/enums/environment-type.enum';
import { environment } from '../../../../environments/environment';
import { DialogService } from '@siemens/component-lib';

@Component({
    selector: 'app-translation-view',
    templateUrl: './translation-view.component.html',
    styleUrls: ['./translation-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationViewComponent implements OnInit {
    selectedTranslationFile$: Observable<TranslationFile> = inject(Store).select(
        TranslationsEditorState.selectedTranslationFile
    );
    availableLanguages$: Observable<string[]> = inject(Store).select(TranslationsEditorState.availableLanguages);
    frontendType$: Observable<FrontendType | undefined> = inject(Store).select(
        TranslationsEditorState.selectedFrontendType
    );
    selectedLanguage$: Observable<string> = inject(Store).select(TranslationsEditorState.selectedLanguage);

    public previousStageMerge?: EnvironmentType;

    constructor(
        private http: HttpClient,
        private dialog: DialogService,
        private store: Store,
        private translationsService: TranslationsService
    ) {}

    ngOnInit(): void {
        this.initPreviousStageMergeButton();
    }

    public changeLanguage(lang: string): void {
        this.store.dispatch(new ChangeSelectedLanguage({ lang }));
    }

    public uploadChanges(): void {
        this.store.dispatch(new UploadTranslationEditorChanges());
    }

    public createInvalidation(): void {
        this.translationsService.createInvalidation().subscribe();
    }

    public addNewLanguage(): void {
        this.dialog
            .open(AddLanguageComponent, { width: '600px' })
            .afterClosed.pipe(
                filter(data => !!data),
                map(data => data as string),
                withLatestFrom(this.frontendType$),
                filter((res): res is [string, FrontendType] => res[1] !== undefined),
                switchMap((res: [string, FrontendType]) => {
                    return this.translationsService
                        .addNewTranslationLanguage(res[1], res[0])
                        .pipe(tap(() => this.store.dispatch(new LoadAvailableLanguages({ frontendType: res[1] }))))
                        .pipe(tap(() => this.store.dispatch(new ChangeSelectedLanguage({ lang: res[0] }))));
                })
            )
            .subscribe();
    }

    public mergeFrom(): void {
        this.store.dispatch(new MergeFromPreviousStage());
    }

    private initPreviousStageMergeButton(): void {
        switch (environment.type) {
            case EnvironmentType.Prod:
                this.previousStageMerge = EnvironmentType.Int;
                break;
            case EnvironmentType.Int:
                this.previousStageMerge = EnvironmentType.Dev;
                break;
        }
    }
}
