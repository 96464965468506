import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../services/theme.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    isSidebarOpen = false;

    constructor(private translate: TranslateService, private themeService: ThemeService) {}

    ngOnInit(): void {
        this.setTheme('dark');
    }

    public changeLang(langCode: string): void {
        this.translate.use(langCode);
    }

    private setTheme(mode: 'dark' | 'light') {
        this.themeService.setTheme(mode);
        localStorage.setItem('theme', JSON.stringify(mode));
    }
}
