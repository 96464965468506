import { Roles } from '../enums';
import { WorkersCouncilGroup } from './workers-council-group';

export interface User {
    gid: string;
    personnelNumber?: string;
    firstName: string;
    lastName: string;
    email?: string;
    roles: Roles[];
    workersCouncilGroup?: WorkersCouncilGroup;
    percentageOfEmployment?: number;
    individualCustomerStatus?: string;
    employmentStatus?: string;
    orgCode?: string;
    orgUnit?: string;
    endOfValidity?: Date;
    employeeGroup?: string;
    legalEntity?: string;
    location?: string;
    retrievedFromColorado?: boolean;
}
