/* eslint-disable */
import { Component, EventEmitter, Input, Output, Type, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

export interface TableColumn<T> {
    /**
     * The name to use for the column.
     */
    name: string;

    /**
     * Whether the column is a unique identifier.
     * Needed for the selection.
     * @default false
     */
    id?: boolean;

    /**
     * The selector to use for the column.
     */
    selector: string;

    /**
     * The function to use for the column.
     * @param row The row to get the values from.
     * @returns The value to display.
     */
    row?: (row: T) => string | number | boolean | null | undefined;

    /**
     * Renders a component for the cell.
     * @param row The row to get the values from.
     */
    component?: Type<any>;

    /**
     * Callback to initialize the component.
     * @param row The row to get the values from.
     */
    componentInit?: (component: any, row: any) => void;

    /**
     * Whether the column is sortable.
     * @default false
     * @choices true | false
     * @type boolean
     */
    sortable?: boolean;

    /**
     * Whether the column is filterable.
     * @default false
     * @choices true | false
     * @type boolean
     */
    filterable?: boolean;

    /**
     * Whether the filter options should be displayed as checkboxes or as a search field.
     * @default 'checkbox'
     * @choices 'checkbox' | 'search'
     * @type string
     */
    filterType?: 'checkbox' | 'search';

    /**
     * Filter options for this column.
     * Defaults to the values of the column for mode 'client'.
     * Required for mode 'server'.
     */
    filterOptions?: TableFilterOption[];

    /**
     * Custom filter function.
     * @param values The value to filter.
     * @param row The row to get the values from.
     * @returns {boolean} Whether the row should be displayed.
     */
    filterFn?: (values: any[], row: T) => boolean;

    /**
     * Custom sort function.
     * @param a The first row to compare.
     * @param b The second row to compare.
     * @returns {number} The sort order. -1 for a < b, 0 for a == b, 1 for a > b.
     */
    sortFn?: (a: T, b: T) => number;

    /**
     * Specifies the minimum width of the column.
     * @default 'auto'
     * @type string
     * @example '100px'
     */
    width?: string;

    /**
     * Whether or not the column has horizontal padding.
     * @type string
     * @example '1rem 0.5rem'
     */
    unpadded?: boolean;
}

export interface TableFilterOption {
    /**
     * The value to use for the filter.
     */
    value: string;

    /**
     * The label to use for the filter.
     */
    label: string;
}

export interface TableFilter {
    sort: 'asc' | 'desc' | undefined;
    options: string[];
}

export type TableFilterState = { [key: string]: TableFilter };

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent {
    /**
     * The mat-paginator component for the table
     */
    @ViewChild('paginator') paginator: MatPaginator;
    /**
     * The configuration for the columns.
     */
    @Input() columns: TableColumn<any>[];

    /**
     * The data to display.
     */
    @Input() data: any[];

    /**
     * Whether rows can be selected.
     */
    @Input() selectable = false;

    /**
     * Whether to show the pagination.
     * In client mode, the pagination is also performed automatically.
     * @default false
     */
    @Input() pagination = false;

    /**
     * The page size to use for the pagination.
     * @default 10
     */
    pageSize = 10;

    /**
     * The page size options to use for the pagination.
     * @default [10, 25, 50, 100]
     */
    @Input() pageSizeOptions: number[] = [10, 25, 50, 100];

    /**
     * The current page.
     * @default 1
     */
    @Input() page: number = 1;

    /**
     * The total number of items. Required for server mode pagination.
     * Client mode pagination will automatically calculate the total number of items.
     */
    @Input() total?: number;

    /**
     * The mode to use for the table.
     * Client mode will automatically filter, sort and paginate the data.
     * Server mode will only emit the filter and sort state.
     * @default 'client'
     */
    @Input() mode?: 'client' | 'server' = 'client';

    /**
     * Whether the table is loading.
     * @default false
     */
    @Input() loading = false;

    /**
     * Select all rows.
     * @default false
     * @choices true | false
     * @type boolean
     */
    @Input() selectAllRows = false;

    /**
     * Selected rows.
     * @default []
     */
    @Input() selectedRows: any[] = [];

    /**
     * Whether to automatically apply the filter.
     * @default false
     * @choices true | false
     * @type boolean
     */
    @Input() automaticFilterApply = false;

    /**
     * Custom style for the table.
     * @default ''
     * @type string
     */
    @Input() style: string;

    /**
     * Handles the filter change event, only emit the changed filter.
     */
    @Output() filterChange = new EventEmitter<TableFilterState>();

    /**
     * Handles the sort change event, only emit the changed sort.
     */
    @Output() sortChange = new EventEmitter<TableFilterState>();

    /**
     * Handles the filter state change event, emit the complete filter state.
     * This is useful for server mode pagination.
     */
    @Output() filterState = new EventEmitter<TableFilterState>();

    /**
     * Handles the page change event. Emits current page, page size and filter state.
     * This is useful for server mode pagination.
     */
    @Output() pageChange = new EventEmitter<{
        page: number;
        size: number;
        filters: TableFilterState;
    }>();

    /**
     * Handles the selection change event.
     * Emits the selected rows.
     */
    @Output() selectionChange = new EventEmitter<any[]>();

    /**
     * Handles the select all event.
     * Returns true if select all is checked, false otherwise.
     */
    @Output() selectAllChange = new EventEmitter<boolean>();

    /**
     * Handles the row click event.
     * Emits the clicked row.
     */
    @Output() rowClick = new EventEmitter<any>();

    protected filters: { [key: string]: TableFilter } = {};
    protected openFilterMenu: string | undefined;
    protected filterMenuHeight = 0;
    protected selectAll = false;
    protected selectionKey: string;
    protected stagedFilters: { [key: string]: TableFilter } = {};
    private dataSize = 0;

    ngOnInit() {
        // Initialize filters
        this.columns.forEach(column => {
            this.filters[column.selector!] = {
                sort: undefined,
                options: []
            };

            column.filterType = column.filterType || 'checkbox';
        });

        // Initialize selection
        const keyColumn = this.columns.find(column => column.id);

        if (this.selectable && keyColumn === undefined) {
            console.error("[SAG Table]: Property 'selectable' requires a column with 'id' set.");
        } else if (this.selectable && keyColumn?.selector) {
            this.selectionKey = keyColumn.selector;
        }
    }

    // ############ SORTING ############
    protected toggleSort(column: string) {
        if (this.filters[column].sort === undefined) {
            this.sort(column, 'asc');
        } else if (this.filters[column].sort === 'asc') {
            this.sort(column, 'desc');
        } else {
            this.sort(column, undefined);
        }
    }

    protected getSortIcon(column: string) {
        if (this.filters[column].sort === undefined) {
            return 'sort1';
        } else if (this.filters[column].sort === 'asc') {
            return 'caret-sort-up';
        } else {
            return 'caret-sort-down';
        }
    }

    protected sortActive(column: string) {
        return this.filters[column].sort !== undefined;
    }

    protected sort(columns: string, value?: 'asc' | 'desc') {
        if (this.filters[columns] === undefined) {
            this.filters[columns] = {
                sort: undefined,
                options: []
            };
        }

        Object.keys(this.filters).forEach(key => {
            if (key !== columns) {
                this.filters[key].sort = undefined;
            }
        });

        this.filters[columns].sort = value;

        this.paginator.firstPage();
        this.page = 1;
        this.sortChange.emit(this.filters);
    }

    // ############ FILTERING ############
    protected openFilter(column: string) {
        if (this.openFilterMenu === column) {
            this.openFilterMenu = undefined;
            return;
        }

        const filterMenu = document.querySelector('#filter-menu-' + column);
        if (filterMenu) {
            this.filterMenuHeight = filterMenu.clientHeight;
        }

        this.openFilterMenu = column;

        setTimeout(() => {
            const listener = (event: MouseEvent) => {
                const target = event.target as HTMLElement;
                if (!target.closest('.table-filter-menu')) {
                    this.openFilterMenu = undefined;
                    document.removeEventListener('click', listener);
                }
            };

            document.addEventListener('click', listener);
        }, 200);
    }

    protected filterActive(column: string) {
        return this.filters[column].options.length > 0 || this.openFilterMenu === column;
    }

    protected getFilterOptions(column: string | undefined): TableFilterOption[] {
        if (column === undefined) return [];
        if (this.columns.find(col => col.selector === column)?.filterOptions) {
            return this.columns.find(col => col.selector === column)?.filterOptions || [];
        }

        const options = new Set<string>();
        this.data.forEach(row => {
            if (row[column]) {
                !row?.gid
                    ? options.add(row[column])
                    : options.add(`${row[column]?.firstName} ${row[column]?.lastName}`);
            }
        });

        return Array.from(options)
            .map(option => {
                return {
                    value: option,
                    label: option
                };
            })
            .filter(option => option.value);
    }

    protected mapFilterOptions(column: TableFilterOption[]) {
        return column.map(option => option.label);
    }

    protected filterOptionActive(column: string, option: string) {
        return this.filters[column].options.includes(option);
    }

    protected filter(columns: string, value: string) {
        const filters = this.filters;
        if (filters[columns] === undefined) {
            filters[columns] = {
                sort: undefined,
                options: [value]
            };
        } else {
            if (filters[columns].options.includes(value)) {
                filters[columns].options = filters[columns].options.filter(option => option !== value);
            } else {
                filters[columns].options.push(value);
            }
        }

        this.paginator.firstPage();
        this.page = 1;
        this.filterChange.emit(this.filters);
    }

    protected filterArray(columns: string | undefined, values: string[]) {
        if (columns === undefined) return;
        const filters = this.filters;
        if (filters[columns] === undefined) {
            filters[columns] = {
                sort: undefined,
                options: values
            };
        } else {
            filters[columns].options = values;
        }

        this.paginator.firstPage();
        this.page = 1;
        this.filterChange.emit(this.filters);
    }

    protected selectedFilterOptions(column: string | undefined) {
        if (column === undefined) return [];
        return this.filters[column].options;
    }

    protected get rows() {
        if (this.mode === 'server') {
            return this.data;
        }

        // Filter
        let rows = this.data;

        Object.keys(this.filters).forEach(key => {
            const filter = this.filters[key];
            if (filter.options.length > 0) {
                rows = rows.filter(row => {
                    const column = this.columns.find(col => col.selector === key);
                    if (column?.filterFn) {
                        return column.filterFn(filter.options, row);
                    } else {
                        return filter.options.includes(row[key]);
                    }
                });
            }
        });

        // Sort
        Object.keys(this.filters).forEach(key => {
            const filter = this.filters[key];
            if (filter.sort !== undefined) {
                rows = rows.sort((a, b) => {
                    const column = this.columns.find(col => col.selector === key);
                    if (column?.sortFn) {
                        return column.sortFn(a, b) * (filter.sort === 'asc' ? 1 : -1);
                    } else {
                        if (a[key].toLowerCase() < b[key].toLowerCase()) {
                            return filter.sort === 'asc' ? -1 : 1;
                        }
                        if (a[key].toLowerCase() > b[key].toLowerCase()) {
                            return filter.sort === 'asc' ? 1 : -1;
                        }
                        return 0;
                    }
                });
            }
        });

        this.dataSize = rows.length;

        // Pagination
        if (this.pagination) {
            const start = (this.page - 1) * this.pageSize;
            const end = start + this.pageSize;
            rows = rows.slice(start, end);
        }
        return rows;
    }

    // ############ PAGINATION ############
    protected changePage(event: PageEvent) {
        this.page = event.pageIndex + 1;
        if (this.pageSize !== event.pageSize) {
            this.paginator.firstPage();
            this.page = 1;
        }

        this.pageSize = event.pageSize;

        this.pageChange.emit({
            page: this.page,
            size: this.pageSize,
            filters: this.filters
        });
    }

    protected get totalSize() {
        return this.total || this.dataSize;
    }

    protected get pageTotal() {
        return Math.min(this.totalSize, this.page * this.pageSize);
    }

    // ############ SELECTION ############
    protected toggleSelectAll(checked: boolean) {
        this.selectAll = checked;

        if (this.selectAll) {
            this.selectedRows = this.rows;
        } else {
            this.selectedRows = [];
        }

        this.selectionChange.emit(this.selectAll ? this.rows : []);
        this.selectAllChange.emit(this.selectAll);
    }

    protected toggleSelect(row: any) {
        const index = this.selectedRows.indexOf((row_: any) => row_[this.selectionKey] === row[this.selectionKey]);
        if (index === -1) {
            this.selectedRows.push(this.rows.find((row_: any) => row_[this.selectionKey] === row[this.selectionKey]));
        } else {
            this.selectedRows.splice(index, 1);
        }

        this.selectAll = this.selectedRows.length === this.rows.length;

        this.selectionChange.emit(this.selectedRows);
    }

    protected isSelected(row: any, selectAll: boolean) {
        return (
            selectAll ||
            this.selectedRows.find((row_: any) => row_[this.selectionKey] === row[this.selectionKey]) !== undefined
        );
    }

    protected invokeComponentInit(column: TableColumn<any>, row: any, component: any) {
        if (column.componentInit) {
            column.componentInit(component, row);
        }
    }

    protected rowClicked(row: any) {
        this.rowClick.emit(row);
    }
}
