/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonModule } from '@siemens/component-lib';

@Component({
    selector: 'app-weight-counter',
    templateUrl: './weight-counter.component.html',
    standalone: true,
    imports: [ButtonModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: WeightCounterComponent,
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeightCounterComponent implements ControlValueAccessor {
    @Input() startDate: string;
    @Input() endDate: string;
    @Input() applicationDeadlineDate: string;
    @Input() public label: string;
    @Input() public min = 0;
    @Input() public max = 100;
    @Input()
    public set formControl(control: FormControl<number>) {
        this._formControl = control;
        if (this._formControl) {
            this._formControl.valueChanges.subscribe(value => {
                this.value = value;
            });
        }
    }
    @Input() public isDisabled: boolean;

    private _formControl: FormControl<number>;
    public value: number;
    public onTouched: () => void = () => {};
    public onChange: (value: number) => void = () => {};

    writeValue(value: number): void {
        this.value = value;
    }

    registerOnChange(fn: (value: number) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public increment(): void {
        if (this._formControl) {
            const newValue = this._formControl.value + 1;
            if (newValue <= this.max) {
                this._formControl.setValue(newValue);
            }
        }
    }

    public decrement(): void {
        if (this._formControl) {
            const newValue = this._formControl.value - 1;
            if (newValue >= this.min) {
                this._formControl.setValue(newValue);
            }
        }
    }
}
