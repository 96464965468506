import { Component, OnInit } from '@angular/core';
import { User } from 'interfaces/src';
import { Observable, tap } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { PageOptions, UsersState } from './state/users.state';
import { UpdatePage, UpdateSearchTerm, GetUsers, CreateUser, UpdateUser, DeleteUser } from './state/users.actions';

@Component({
    selector: 'app-user-management-wrapper',
    templateUrl: './user-management.component.html',
    styleUrls: []
})
export class UserManagementWrapperComponent implements OnInit {
    @Select(UsersState.isLoading) protected loading$: Observable<boolean>;
    @Select(UsersState.users) protected users$: Observable<User[]>;
    @Select(UsersState.total) protected total$: Observable<number>;
    @Select(UsersState.pageOptions) protected pageOptions$: Observable<PageOptions>;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(new GetUsers());
        this.users$.pipe(tap(users => users)).subscribe();
    }

    protected pageSizeOptions = [10, 25, 50, 100];

    protected updateSearch(search: string) {
        this.store.dispatch(new UpdateSearchTerm(search));
    }

    protected pageChange(pageOptions: PageOptions) {
        const { page, pageSize } = pageOptions;
        this.store.dispatch(new UpdatePage({ pageSize, page: page - 1 }));
    }

    protected addUser(user: User) {
        this.store.dispatch(new CreateUser(user as unknown as User));
    }
    protected editUser(user: User) {
        this.store.dispatch(new UpdateUser(user as unknown as User));
    }
    protected deleteUser(gid: string) {
        this.store.dispatch(new DeleteUser(gid));
    }
}
