export enum ProfilePhotoSize {
    SIZE_48x48 = '48x48',
    SIZE_64x64 = '64x64',
    SIZE_96x96 = '96x96',
    SIZE_120x120 = '120x120',
    SIZE_240x240 = '240x240',
    SIZE_360x360 = '360x360',
    SIZE_432x432 = '432x432',
    SIZE_504x504 = '504x504',
    SIZE_648x648 = '648x648'
}
