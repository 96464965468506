<div class="p-6 pt-4" [formGroup]="formGroup">
    <h3 class="text-xl mb-2">{{ 'seasons.season-details.dialogs.comment-dialog.title' | translate }}</h3>
    <p class="text-base mb-4">{{ 'seasons.season-details.dialogs.comment-dialog.description' | translate }}</p>
    <sag-ui-textarea
        label="{{ 'seasons.season-details.dialogs.comment-dialog.label' | translate }}"
        formControlName="comment"
    ></sag-ui-textarea>
    <div class="flex justify-end mt-2">
        <sag-ui-button size="sm" color="tertiary" icon="delete" (click)="clearComment()">{{
            'seasons.season-details.dialogs.comment-dialog.delete' | translate
        }}</sag-ui-button>
    </div>
    <div class="flex justify-end items-center gap-2 mt-4">
        <sag-ui-button color="secondary" icon="chevron-left" (click)="onClose()">
            {{ 'seasons.season-details.dialogs.comment-dialog.cancel' | translate }}
        </sag-ui-button>
        <sag-ui-button icon="save" (click)="onClose('save')">
            {{ 'seasons.season-details.dialogs.comment-dialog.confirm' | translate }}
        </sag-ui-button>
    </div>
</div>
