import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PayoutElement } from 'libs/interfaces/src';
import { Dinero } from 'dinero.js';

@Component({
    selector: 'app-budget-card',
    standalone: true,
    templateUrl: './budget-card.component.html',
    imports: [CommonModule, TranslateModule]
})
export class BudgetCardComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() totalBudget: Dinero;

    @Input()
    set payoutElements(value: PayoutElement[]) {
        this._payoutElements = value;
        this.processPayoutElements();
    }

    get payoutElements(): PayoutElement[] {
        return this._payoutElements;
    }

    private _payoutElements: PayoutElement[];
    public employeeSubsidies: PayoutElement[];
    public spouseSubsidies: PayoutElement[];
    public childSubsidies: PayoutElement[];

    private processPayoutElements(): void {
        if (this._payoutElements?.length) {
            this.employeeSubsidies = this._payoutElements.filter(payoutElement => payoutElement.name === 'employeeGid');
            this.spouseSubsidies = this._payoutElements.filter(payoutElement => payoutElement.name === 'spouse');
            this.childSubsidies = this._payoutElements.filter(payoutElement => payoutElement.name === 'child');
        }
    }
}
