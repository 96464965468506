import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { UserState } from '../auth/state/user.state';
import { GbrUser } from 'interfaces/src';
import { Router } from '@angular/router';
import { Roles } from '../shared/enums/roles';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: []
})
export class DashboardComponent {
    protected user$: Observable<GbrUser> = inject(Store).select(UserState.user);
    public userRoles = Roles;

    constructor(private router: Router) {}

    public navigateToSeasonCreation(): void {
        this.router.navigate(['/season/create']);
    }
}
