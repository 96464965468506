import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: []
})
export class IconComponent {
    /**
     * The name of the icon to display.
     */
    @Input() icon: string;

    /**
     * A custom class to add to the icon.
     */
    @Input() class: string;

    /**
     * A custom style to add to the icon.
     */
    @Input() style: string;
}
