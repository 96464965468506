import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationAddItem } from '../../models/translation-edits.model';

@Component({
    selector: 'app-add-translation-item',
    templateUrl: './add-translation-item.component.html',
    styleUrls: ['./add-translation-item.component.scss']
})
export class AddTranslationItemComponent {
    data: TranslationAddItem = {
        title: '',
        translations: {
            en: ''
        }
    };

    constructor(
        private dialogRef: MatDialogRef<AddTranslationItemComponent>,
        @Inject(MAT_DIALOG_DATA) public langs: string[]
    ) {}

    proceed(): void {
        if (
            this.data.title &&
            this.data.title !== '' &&
            this.data.translations &&
            this.data.translations.en &&
            this.data.translations.en !== ''
        ) {
            this.dialogRef.close(this.data);
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
