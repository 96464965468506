<div class="flex flex-col w-full bg-cardBackgroundColor mt-8 mb-2 rounded p-4">
    <!-- <p>{{ 'seasons.season-details.timeframe-card-title' | translate }}</p> -->
    <p>{{ title }}</p>
    <div class="flex flex-row justify-start mt-4 mb-4">
        <p class="text-darkGraySubtitleColor mr-8">
            {{ startDate | date : 'dd.MM.yyyy' }} - {{ endDate | date : 'dd.MM.yyyy' }}
        </p>
        <p class="text-darkGraySubtitleColor">
            {{ 'seasons.season-details.setting-cards.timeframe-card.end-of-application-phase-label' | translate }}:
            {{ applicationDeadlineDate | date : 'dd.MM.yyyy' }}
        </p>
    </div>
    <app-season-timeline
        [startDate]="startDate"
        [endDate]="endDate"
        [applicationDeadlineDate]="applicationDeadlineDate"
    ></app-season-timeline>
    <div class="flex flex-row w-1/3 justify-start items-center mt-8">
        <p class="mr-4 flex items-center gap-2">
            <span class="inline-block w-2 h-2 rounded-full bg-white"></span>
            {{ 'seasons.season-details.setting-cards.timeframe-card.season-period-label' | translate }}
        </p>
        <p class="flex items-center gap-2">
            <span class="inline-block w-2 h-2 rounded-full bg-siemensGreen"></span
            >{{ 'seasons.season-details.setting-cards.timeframe-card.application-deadline-label' | translate }}
        </p>
    </div>
</div>
