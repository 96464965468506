<div class="p-6 pt-4" [formGroup]="formGroup">
    <h3 class="text-xl mb-2">{{ 'seasons.season-details.dialogs.release-payout-dialog.title' | translate }}</h3>
    <p class="text-base mb-4">{{ 'seasons.season-details.dialogs.release-payout-dialog.description' | translate }}</p>
    <sag-ui-input
        label="{{ 'seasons.season-details.dialogs.release-payout-dialog.confirm-label' | translate }}"
        [invalid]="!formControlValid('confirm')"
        formControlName="confirm"
    ></sag-ui-input>
    <div class="flex justify-end items-center gap-2 mt-8">
        <sag-ui-button color="secondary" icon="chevron-left" (click)="onClose()">
            {{ 'seasons.season-details.dialogs.release-payout-dialog.cancel' | translate }}
        </sag-ui-button>
        <sag-ui-button icon="chevron-right" (click)="onClose('delete')" [disabled]="!isValid()">
            {{ 'seasons.season-details.dialogs.release-payout-dialog.release-payout' | translate }}
        </sag-ui-button>
    </div>
</div>
