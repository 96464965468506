<form class="flex flex-col mb-4" [formGroup]="salaryWeight">
    <div class="flex flex-row items-center justify-start gap-2 mb-2">
        <h2 class="text-xl font-bold">{{ 'seasons.season-creation.salary-weight.title' | translate }}</h2>
        <span
            class="icon-edit cursor-pointer text-lg text-[#00CCCC] hover:text-[#00FFB9]"
            *ngIf="enableIcon"
            (click)="toggleDisable()"
        ></span>
    </div>
    <p class="mb-4 text-base">{{ 'seasons.season-creation.salary-weight.subtitle' | translate }}</p>

    <div formArrayName="salaryWeights">
        <div class="grid grid-cols-2 gap-4">
            <div>
                <div class="flex flex-row justify-between text-darkGraySubtitleColor">
                    <p>{{ 'seasons.season-creation.salary-weight.monthly-salary-column-label' | translate }}</p>
                    <p class="pr-4">{{ 'seasons.season-creation.salary-weight.weight-column-label' | translate }}</p>
                </div>
                <ng-container *ngFor="let group of salaryWeight.get('salaryWeights')['controls']; let i = index">
                    <div *ngIf="i < 5" [formGroupName]="i" class="flex flex-col">
                        <app-weight-counter
                            [label]="salaryWeights[i].rangeLabel"
                            [formControl]="group.get('weight')"
                            [min]="0"
                            [max]="10"
                            [isDisabled]="isDisabled"
                        ></app-weight-counter>
                    </div>
                </ng-container>
            </div>
            <div>
                <div class="flex flex-row justify-between text-darkGraySubtitleColor">
                    <p>{{ 'seasons.season-creation.salary-weight.monthly-salary-column-label' | translate }}</p>
                    <p class="pr-4">{{ 'seasons.season-creation.salary-weight.weight-column-label' | translate }}</p>
                </div>
                <ng-container *ngFor="let group of salaryWeight.get('salaryWeights')['controls']; let i = index">
                    <div *ngIf="i >= 5" [formGroupName]="i" class="flex flex-col">
                        <app-weight-counter
                            [label]="salaryWeights[i].rangeLabel"
                            [formControl]="group.get('weight')"
                            [isDisabled]="isDisabled"
                        ></app-weight-counter>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</form>
