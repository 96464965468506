import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogConfig, DialogRef } from '@siemens/component-lib';
import { SharedModule } from '../../../../shared/shared.module';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UserApplication } from 'libs/interfaces/src';

@Component({
    selector: 'app-comment-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule, ReactiveFormsModule],
    templateUrl: './comment-dialog.component.html'
})
export class CommentDialogComponent {
    protected formGroup = new FormGroup({
        comment: new FormControl(this.config.data.comment)
    });

    constructor(public config: DialogConfig<UserApplication>, public dialogRef: DialogRef<string>) {}

    public clearComment(): void {
        this.formGroup.patchValue({ comment: '' });
    }

    public onClose(result?: string): void {
        if (result) {
            this.dialogRef.close(this.formGroup.value.comment);
        }

        this.dialogRef.close();
    }
}
