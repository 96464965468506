import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteModule, DialogConfig, DialogRef } from '@siemens/component-lib';
import { SharedModule } from '../../../shared/shared.module';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { filter, map, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { WorkersCouncilGroupState } from '../../state/workers-council-group.state';
import { User } from '../../../shared/interfaces/CreateOrUpdateUserPayload';

@Component({
    selector: 'app-select-delegate-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule, ReactiveFormsModule, AutocompleteModule],
    templateUrl: './select-delegate-dialog.component.html'
})
export class SelectDelegateDialogComponent {
    protected readonly delegateUsers$: Observable<User[]> = inject(Store).select(
        WorkersCouncilGroupState.getDelegateUsers
    );
    protected selectControl = new FormControl(null);
    protected filteredUsers$: Observable<User[]> = this.delegateUsers$.pipe(
        map(users => users.filter(user => !this.config.data.delegates.includes(user.gid)))
    );

    public compareFn = (firstUser: User, secondUser: User) =>
        firstUser && secondUser && firstUser.gid === secondUser.gid;

    constructor(public config: DialogConfig<{ delegates: string[] }>, public dialogRef: DialogRef<string>) {}

    public onClose(result?: string): void {
        if (result) {
            this.dialogRef.close(this.selectControl.value);
        }

        this.dialogRef.close();
    }
}
