<div class="flex flex-col mx-auto my-0 max-w-[1440px] py-6 z-50 h-full">
    <sag-ui-button color="tertiary" [routerLink]="['/']" icon="chevron-left" class="-ml-4">
        {{ 'general.back-to-dashboard' | translate }}
    </sag-ui-button>

    <h1 class="text-xl mt-4">
        {{ 'workers-council-groups.list.title' | translate }}
    </h1>

    <div class="mt-8">
        <div class="block max-h-14 overflow-hidden px-4 py-0 text-white">
            <div class="grid cursor-pointer grid-cols-8 items-center gap-4 p-2 text-sm">
                <span class="col-span-2 truncate text-xs text-[#CCCCD4]" (click)="sortBRUnit()">{{
                    'workers-council-groups.list.gbr-unit' | translate
                }}  
                    <span class="icon-caret-sort-{{ groupsSorted ? 'down' : 'up' }}"></span>
                </span>
                <span class="col-span-6 truncate text-xs text-[#CCCCD4]">{{
                    'workers-council-groups.list.delegates' | translate
                }}</span>
            </div>
        </div>
        <div class="flex flex-col gap-2">
            @if (groups$ | async; as groups) { @for (group of groups; track group.id) {
            <div class="block max-h-14 overflow-hidden rounded-md px-4 py-0 text-white h-14 bg-[#23233c]">
                <div class="grid cursor-pointer grid-cols-8 items-center gap-4 px-2 relative w-full h-full">
                    @if (!group.delegates || group.delegates.length === 0) {
                    <span class="absolute top-0 -left-4 w-2 h-full bg-[#FF2640]"></span>
                    }
                    <span class="col-span-2 truncate text-base text-white">{{ group.name }}</span>
                    <span class="col-span-6 truncate">
                        @for (delegate of group.delegates; track $index) {
                        <sag-ui-chip
                            class="text-sm mr-1"
                            [removable]="true"
                            (remove)="onRemoveDelegate(group.id, delegate.gid)"
                            >{{ delegate.firstName }} {{ delegate.lastName }}</sag-ui-chip
                        >
                        }
                        <sag-ui-button
                            color="tertiary"
                            icon="plus-large"
                            class="rounded-lg"
                            (click)="openPickerDialog(group.id, group.delegates)"
                        >
                            {{ 'workers-council-groups.list.add-delegate' | translate }}
                        </sag-ui-button>
                    </span>
                </div>
            </div>
            } }
        </div>
        @if (isLoading()) {
        <div class="flex flex-col gap-2 animate-pulse mt-2">
            <div class="h-14 bg-[#23233c] rounded-md opacity-100 w-full"></div>
            <div class="h-14 bg-[#23233c] rounded-md opacity-90 w-full"></div>
            <div class="h-14 bg-[#23233c] rounded-md opacity-80 w-full"></div>
            <div class="h-14 bg-[#23233c] rounded-md opacity-70 w-full"></div>
            <div class="h-14 bg-[#23233c] rounded-md opacity-60 w-full"></div>
        </div>
        }
    </div>
</div>
