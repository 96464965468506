<form class="flex flex-col" [formGroup]="seasonInfo">
    <h2 class="text-xl font-bold mb-2">{{ 'seasons.season-creation.season-title.title' | translate }}</h2>
    <p class="mb-4 text-base text-[#F3F3F0]">{{ 'seasons.season-creation.season-title.subtitle' | translate }}</p>

    <div class="mb-6 max-w-[400px]">
        <sag-ui-input
            label="{{ 'seasons.season-creation.season-title.title-label' | translate }}"
            placeholder="{{ 'seasons.season-creation.season-title.title-placeholder' | translate }}"
            formControlName="title"
            [required]="true"
        ></sag-ui-input>
    </div>
</form>
