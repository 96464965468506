import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { WeightCounterComponent } from '../weight-counter/weight-counter.component';
import { salaryWeights } from '../../../configs/salary-weight.config';
import { ButtonModule } from '@siemens/component-lib';
import { Store } from '@ngxs/store';
import { DecrementStepper, IncrementStepper } from '../../../state/season.action';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SeasonFormGroup } from '../../../models/season-form.model';

@Component({
    selector: 'app-salary-weight',
    standalone: true,
    templateUrl: './salary-weight.component.html',
    imports: [CommonModule, WeightCounterComponent, ButtonModule, ReactiveFormsModule, TranslateModule]
})
export class SalaryWeightComponent implements OnInit {
    @Input() public salaryWeight: SeasonFormGroup['salary'];
    @Input() public enableIcon = false;
    @Input() public isDisabled?: boolean;

    public salaryWeights = salaryWeights;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.setDisabledState();
    }

    private setDisabledState(): void {
        if (this.isDisabled) {
            this.salaryWeight.disable();
        } else {
            this.salaryWeight.enable();
        }
    }

    public onNext(): void {
        this.store.dispatch(new IncrementStepper());
    }

    public onBack(): void {
        this.store.dispatch(new DecrementStepper());
    }

    public toggleDisable(): void {
        this.isDisabled = !this.isDisabled;
    }
}
