import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementWrapperComponent } from './user-management.component';
import { UserManagementModule } from '@gbr-monorepo/user-mgmt-ui';
import { environment } from '../../environments/environment';
import { ButtonModule } from '@siemens/component-lib';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [UserManagementWrapperComponent],
    imports: [
        CommonModule,
        ButtonModule,
        TranslateModule,
        RouterModule,
        UserManagementModule.forRoot({
            resources: [],
            useScd: true,
            apiUrl: `${environment.apiUrl}/v1/users`,
            useThemes: false
        })
    ]
})
export class UserManagementWrapperModule {}
