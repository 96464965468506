import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { TranslationsRoutingModule } from './translations-routing.module';
import { TranslationsDashboardComponent } from './components/translations-dashboard/translations-dashboard.component';
import { TranslationViewComponent } from './components/translation-view/translation-view.component';
import { TranslationPartComponent } from './components/translation-part/translation-part.component';
import { AddTranslationItemComponent } from './dialogs/add-translation-item/add-translation-item.component';
import { TranslationsEditorState } from './states/translations-editor.state';
import { AddTopicComponent } from './dialogs/add-topic/add-topic.component';
import { AddLanguageComponent } from './dialogs/add-language/add-language.component';
import { SpinnerModule } from '@siemens/component-lib';

@NgModule({
    declarations: [
        TranslationsDashboardComponent,
        TranslationViewComponent,
        TranslationPartComponent,
        AddTranslationItemComponent,
        AddTopicComponent,
        AddLanguageComponent
    ],
    imports: [
        CommonModule,
        TranslationsRoutingModule,
        SharedModule,
        NgxsModule.forFeature([TranslationsEditorState]),
        TranslateModule,
        SpinnerModule
    ]
})
export class TranslationsModule {}
