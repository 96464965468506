export interface ColoradoEntry {
    gid: string;
    personnelNumber: string;
    firstName: string;
    lastName: string;
    workersCouncilGroupAbbreviation: string;
    workersCouncilGroupName: string;
    percentageOfEmployment: number;
    individualCustomerStatus: string;
    employmentStatus: string;
    endOfValidity: string;
    employeeGroup: string;
    legalEntity: string;
    orgUnit: string;
    orgCode: string;
    location: string;
}
