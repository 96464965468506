import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

export interface SCDUser {
    firstName: string;
    surName: string;
    mail: string;
    gid: string;
    department: string;
}
@Injectable({
    providedIn: 'root'
})
export class SCDService {
    private apiUrl: string;
    constructor(private httpClient: HttpClient, @Inject('API_URL') apiUrl: string) {
        this.apiUrl = apiUrl + '/scd';
    }

    private errorPipe(request: Observable<SCDUser>): Observable<any> {
        return request.pipe(
            catchError(error => {
                console.log('Error in catchError', error);
                return of({});
            })
        );
    }

    getUserByGid(gid: string): Observable<any> {
        const res = this.errorPipe(this.httpClient.get<SCDUser>(this.apiUrl, { params: { gid } }));
        return res;
    }
    getUserByEmail(email: string): Observable<any> {
        const res = this.errorPipe(this.httpClient.get<SCDUser>(this.apiUrl, { params: { mail: email } }));
        return res;
    }
}
