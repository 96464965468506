export class AttachmentOperations {
    public static downloadFile(url: string, fileName: string): void {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        document.removeChild(link);
    }
}
