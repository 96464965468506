import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogConfig, DialogRef } from '@siemens/component-lib';
import { SharedModule } from '../../../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-close-season-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule, ReactiveFormsModule],
    templateUrl: './close-season-dialog.component.html'
})
export class CloseSeasonDialogComponent {
    constructor(public config: DialogConfig, public dialogRef: DialogRef<string>) {}

    public onClose(result?: string): void {
        this.dialogRef.close(result);
    }
}
