import { SeasonStatus } from './../enums/status';
import { SalaryRangeItem } from './salary';
import { User } from './user';
import { Dinero } from 'dinero.js';

export interface Season<MoneyType = Dinero> {
    id: string;
    title: string;
    createdAt?: Date;
    updatedAt?: Date;
    updatedBy?: string;
    startDate?: Date;
    endDate?: Date;
    totalBudget?: MoneyType;
    totalPayout?: MoneyType;
    applicationDeadline?: Date;
    status: SeasonStatus;
    payoutElements: PayoutElement<MoneyType>[];
    salaryRangeItems: SalaryRangeItem[];
    eligibilityCriteria: EligibilityCriterion[];
}

export interface SeasonWithUpdateBy<MoneyType = Dinero> {
    id: string;
    title: string;
    createdAt?: Date;
    updatedAt?: Date;
    updatedBy?: User;
    startDate?: Date;
    endDate?: Date;
    totalBudget?: MoneyType;
    totalPayout?: MoneyType;
    applicationDeadline?: Date;
    status: SeasonStatus;
    payoutElements: PayoutElement<MoneyType>[];
    salaryRangeItems: SalaryRangeItem[];
    eligibilityCriteria: EligibilityCriterion[];
}

export interface PayoutElement<MoneyType = Dinero> {
    id: string;
    name: string;
    minimumDuration: number; //days
    maximumDuration?: number; //days
    amount: MoneyType;
}

export interface EligibilityCriterion {
    id: string;
    property: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
    comparisonOperator: ComparisonOperator;
    weight: number;
    joinedEntityName?: string;
}

export enum ComparisonOperator {
    EQUALS = 'EQUALS',
    IS_GREATER_THAN = 'IS_GREATER_THAN',
    IS_LESS_THAN = 'IS_LESS_THAN',
    BETWEEN = 'BETWEEN',
    EXISTS = 'EXISTS'
}
