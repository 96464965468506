import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableFilterOption } from '../table.component';

@Component({
    selector: 'app-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent {
    @Input() selector: string;
    @Input() filterType: 'checkbox' | 'search';
    @Input() filterOptions: TableFilterOption[];
    @Input() selectedFilters: string[];
    @Output() selectedFiltersChange = new EventEmitter<string[]>();

    public isSelected(filter: string): boolean {
        return this.selectedFilters.includes(filter);
    }

    public toggleFilter(filter: string): void {
        if (this.isSelected(filter)) {
            this.selectedFilters = this.selectedFilters.filter(f => f !== filter);
        } else {
            this.selectedFilters.push(filter);
        }
        this.selectedFiltersChange.emit(this.selectedFilters);
    }

    public changeFilters(filters: string[]): void {
        this.selectedFiltersChange.emit(filters);
    }
}
