export function scrollOverAmount(e: any, amount = 0.8) {
    const { scrollTop, offsetHeight } = e.target.scrollingElement;
    const { innerHeight } = window;
    return Math.round(scrollTop) + innerHeight > offsetHeight * amount;
}

export function scrollOverAmountElement(e: any, amount = 0.8) {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    return Math.round(scrollTop) + offsetHeight > scrollHeight * amount;
}
