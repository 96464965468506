import { SalaryRangeForm } from '../models/season-create.model';

export const salaryWeights: SalaryRangeForm[] = [
    {
        lowerBound: 0,
        upperBound: 1500,
        rangeLabel: '0 - 1500 EUR'
    },
    {
        lowerBound: 1501,
        upperBound: 2500,
        rangeLabel: '1501 - 2500 EUR'
    },
    {
        lowerBound: 2501,
        upperBound: 3000,
        rangeLabel: '2501 - 3000 EUR'
    },
    {
        lowerBound: 3001,
        upperBound: 3500,
        rangeLabel: '3001 - 3500 EUR'
    },
    {
        lowerBound: 3501,
        upperBound: 4000,
        rangeLabel: '3501 - 4000 EUR'
    },
    {
        lowerBound: 4001,
        upperBound: 4500,
        rangeLabel: '4001 - 4500 EUR'
    },
    {
        lowerBound: 4501,
        upperBound: 5000,
        rangeLabel: '4501 - 5000 EUR'
    },
    {
        lowerBound: 5001,
        upperBound: 6000,
        rangeLabel: '5001 - 6000 EUR'
    },
    {
        lowerBound: 6001,
        upperBound: 100000,
        rangeLabel: '> 6000 EUR'
    }
];
