<mat-icon-button [matMenuTriggerFor]="menu" class="toggle-button">
    <app-icon icon="filter" />
</mat-icon-button>
<mat-menu #menu="matMenu">
    <div class="table-filter-menu">
        <div [id]="'filter-menu-' + selector" class="table-filter-menu-content">
            <ng-container *ngIf="filterType === 'search'">
                <form (click)="$event.stopPropagation()">
                    <app-autocomplete-multi-select
                        placeholder="Search filter options"
                        [items]="filterOptions"
                        [selectedItems]="selectedFilters"
                        (selectedItemsChange)="changeFilters($event)"
                    ></app-autocomplete-multi-select>
                </form>
            </ng-container>
            <ng-container *ngIf="filterType === 'checkbox'">
                <form (click)="$event.stopPropagation()">
                    <div class="table-filter-menu-row" *ngFor="let filterOption of filterOptions">
                        <mat-checkbox
                            [checked]="isSelected(filterOption.value)"
                            (change)="toggleFilter(filterOption.value)"
                        >
                            <span class="table-filter-menu-label">{{ filterOption.label }}</span>
                        </mat-checkbox>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</mat-menu>
