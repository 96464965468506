import { PayoutElement, Season, UserApplication } from 'libs/interfaces/src';
import Dinero from 'dinero.js';
import { SeasonCreate } from '../models/season-create-dto.model';
import { FinancialOverviewResponse } from '../models/season-financial.model';

export class SeasonOperations {
    public static mapSeasonFromDineroToNumber(season: Season<Dinero.Dinero>): Season<number> {
        return {
            ...season,
            totalBudget: season.totalBudget ? season.totalBudget.getAmount() : undefined,
            totalPayout: season.totalPayout ? season.totalPayout.getAmount() : undefined,
            payoutElements: season.payoutElements.map(this.mapPayoutElementFromDineroToNumber)
        };
    }

    public static mapPayoutElementFromDineroToNumber(
        payoutElement: PayoutElement<Dinero.Dinero>
    ): PayoutElement<number> {
        return {
            ...payoutElement,
            amount: payoutElement.amount?.getAmount() || 0
        };
    }

    public static mapSeasonCreateFromDineroToNumber(season: SeasonCreate<Dinero.Dinero>): SeasonCreate<number> {
        return {
            ...season,
            totalBudget: season.totalBudget ? season.totalBudget.getAmount() : undefined,
            totalPayout: season.totalPayout ? season.totalPayout.getAmount() : undefined,
            payoutElements: season.payoutElements.map(this.mapPayoutElementFromDineroToNumber)
        };
    }

    public static mapUserApplicationFromDineroToNumber(application: UserApplication): UserApplication<number> {
        return {
            ...application,
            lastYearPayout: application.lastYearPayout.getAmount(),
            potentialPayout: application.potentialPayout.getAmount()
        };
    }

    public static mapFinancialOverviewResponseFromDineroToNumber(
        financialOverview: FinancialOverviewResponse
    ): FinancialOverviewResponse<number> {
        return {
            ...financialOverview,
            totalBudget: financialOverview.totalBudget.getAmount(),
            availableBudget: financialOverview.availableBudget.getAmount()
        };
    }

    public static mapSeasonFromNumberToDinero(season: Season<number>): Season<Dinero.Dinero> {
        return {
            ...season,
            totalBudget: Dinero({ amount: Number(season.totalBudget), currency: 'EUR' }),
            totalPayout: season.totalPayout
                ? Dinero({ amount: Number(season.totalPayout), currency: 'EUR' })
                : undefined,
            payoutElements: season.payoutElements.map(this.mapPayoutElementFromNumberToDinero)
        };
    }

    public static mapPayoutElementFromNumberToDinero(
        payoutElement: PayoutElement<number>
    ): PayoutElement<Dinero.Dinero> {
        return {
            ...payoutElement,
            amount: Dinero({ amount: Number(payoutElement.amount), currency: 'EUR' })
        };
    }

    public static mapSeasonCreateFromNumberToDinero(season: SeasonCreate<number>): SeasonCreate<Dinero.Dinero> {
        return {
            ...season,
            totalBudget: Dinero({ amount: season.totalBudget, currency: 'EUR' }),
            totalPayout: Dinero({ amount: season.totalPayout, currency: 'EUR' }),
            payoutElements: season.payoutElements.map(this.mapPayoutElementFromNumberToDinero)
        };
    }

    public static mapUserApplicationFromNumberToDinero(application: UserApplication<number>): UserApplication {
        return {
            ...application,
            lastYearPayout: Dinero({ amount: application.lastYearPayout, currency: 'EUR' }),
            potentialPayout: Dinero({ amount: application.potentialPayout, currency: 'EUR' })
        };
    }

    public static mapFinancialOverviewResponseFromNumberToDinero(
        financialOverview: FinancialOverviewResponse<number>
    ): FinancialOverviewResponse {
        return {
            ...financialOverview,
            totalBudget: Dinero({ amount: financialOverview.totalBudget, currency: 'EUR' }),
            availableBudget: Dinero({ amount: financialOverview.availableBudget, currency: 'EUR' })
        };
    }
}
