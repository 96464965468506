<div class="flex flex-col bg-cardBackgroundColor mt-8 mb-8 p-4 rounded">
    <div class="flex flex-col">
        <p>{{ title }}</p>
        <p class="text-darkGraySubtitleColor mt-2 mb-2">{{ subtitle }}</p>
        <div class="flex flex-row justify-between text-darkGraySubtitleColor mb-2">
            <p>
                {{ 'seasons.season-details.setting-cards.salary-card.monthly-salary-column-label' | translate }}
            </p>
            <p class="pr-5">
                {{ 'seasons.season-details.setting-cards.salary-card.weight-column-label' | translate }}
            </p>
        </div>
        @for (salaryRangeItem of salaryRangeItems; track salaryRangeItem; let index = $index) {
        <div class="flex justify-between">
            <p>
                {{
                    index + 1 === salaryRangeItems.length
                        ? '> ' + salaryRangeItem.lowerBound + ' EUR'
                        : salaryRangeItem.lowerBound + ' - ' + salaryRangeItem.upperBound + ' EUR'
                }}
            </p>
            <p class="pr-8">{{ salaryRangeItem?.weight }}</p>
        </div>
        <hr class="border-separatorColor mt-2 mb-2" />
        }
    </div>
</div>
