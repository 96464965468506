/* eslint-disable @typescript-eslint/unbound-method */
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { User } from 'interfaces/src';
import { Roles } from '../../enums/roles.enum';
import { SCDService } from '../../services/scd.service';
import { UserFormBuilder } from '../../utils/user-form-builder';

@Component({
    selector: 'app-add-user-dialog',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class AddUserComponent {
    private formBuilder: UserFormBuilder;
    protected Roles = Roles;

    constructor(
        private dialogRef: MatDialogRef<AddUserComponent>,
        @Inject('useScd') useScd: boolean,
        scdService: SCDService
    ) {
        this.formBuilder = new UserFormBuilder(
            {
                gid: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                roles: [[], Validators.required],
                orgCode: ['']
            },
            null,
            useScd,
            scdService
        );
    }

    protected get addUserForm() {
        return this.formBuilder.form!;
    }

    protected getUserInfoSCD(field: string) {
        this.formBuilder.getUserInfoSCD(field);
    }

    createUser(event: Event): void {
        event.stopPropagation();
        const userToCreate: User = {
            gid: this.addUserForm.controls['gid'].value,
            firstName: this.addUserForm.controls['firstName'].value,
            lastName: this.addUserForm.controls['lastName'].value,
            roles: this.addUserForm.controls['roles'].value,
            email: this.addUserForm.controls['email'].value,
            orgCode: this.addUserForm.controls['orgCode'].value
        };
        this.dialogRef.close({ confirmed: true, payload: userToCreate });
    }

    cancel() {
        this.dialogRef.close({ confirmed: false });
    }
}
