import { Injectable } from '@angular/core';
import {
    catchError,
    EMPTY,
    filter,
    forkJoin,
    iif,
    map,
    mergeMap,
    Observable,
    of,
    switchMap,
    tap,
    throwError
} from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TranslationFile } from '../models/translation-file.model';
import { FrontendType } from '../models/frontend-type.model';
import { TranslationAddItem } from '../models/translation-edits.model';
import { TranslationsOperation } from '../operations/translations.operation';
import { HttpHelperService } from '../../shared/services/http-helper.service';
import { SnackbarService } from '@siemens/component-lib';
import { SnackbarPosition, SnackbarType } from '../../shared/enums/snackbar.enum';

@Injectable({
    providedIn: 'root'
})
export class TranslationsService {
    constructor(
        private http: HttpClient,
        private httpHelperService: HttpHelperService,
        private snackbarService: SnackbarService
    ) {}

    private readonly baseUrl = `${environment.apiUrl}/v1`;
    /**
     * @param lang iso code of the language
     * @param frontendType either customer or admin frontend
     */
    public getTranslations(lang: string, frontendType: FrontendType): Observable<TranslationFile> {
        const path = encodeURIComponent(`${frontendType.toString()}/${lang}.json`);

        return this.http.get<Record<string, any>>(`${this.baseUrl}/translations/files/${path}`).pipe(
            this.httpHelperService.handleError(`Could not fetch ${lang} Translations`),
            map((data: Record<string, any>): TranslationFile => ({ languageKey: lang, file: data }))
        );
    }

    public getAvailableLanguages(frontendType: FrontendType): Observable<string[]> {
        return this.http.get<string[]>(`${this.baseUrl}/translations/files/languages/${frontendType.toString()}`).pipe(
            catchError(err => {
                if (err.status === 404 || err.status === 500) {
                    return of([]);
                }
                return throwError(() => err);
            }),
            map(langs => langs.map(lang => lang.replace('.json', '')))
        );
    }

    public getAllTranslations(frontendType: FrontendType): Observable<TranslationFile[]> {
        return this.getAvailableLanguages(frontendType).pipe(
            map(langs =>
                langs.map(lang => {
                    return this.getTranslations(lang, frontendType);
                })
            ),
            mergeMap(observables => forkJoin(observables))
        );
    }

    public updateTranslationFile(frontendType: FrontendType, file: TranslationFile): Observable<void> {
        const blob = new Blob([JSON.stringify(file.file) as BlobPart], { type: 'application/json' });
        const fileToSend = new File([blob], `${file.languageKey}.json`, { type: 'application/json' });

        const encodedFileNameWithPrefix = `${frontendType.toString()}/${fileToSend.name}`.replace(
            /\//g,
            TranslationsOperation.SINGLE_ENCODED_SLASH
        );
        const formData = new FormData();
        formData.append('file', fileToSend);

        return this.http.put<void>(`${this.baseUrl}/translations/files/${encodedFileNameWithPrefix}`, formData).pipe(
            this.httpHelperService.handleError(
                `Could not update "${file.languageKey}" translations for ${frontendType.toString()}`
            ),
            tap(() =>
                this.snackbarService.open({
                    title: 'Successful',
                    description: `Successful update of "${
                        file.languageKey
                    }" translations for ${frontendType.toString()}`,
                    type: SnackbarType.SUCCESS,
                    duration: 3000,
                    position: SnackbarPosition.BOTTOM
                })
            )
        );
    }

    public addTranslationItem(frontendType: FrontendType, item: TranslationAddItem): Observable<void[] | never> {
        if (!item.route) {
            return EMPTY;
        }
        // Load all translations from BE to get the last state of the translations thus prevent overrides
        return this.getAllTranslations(frontendType).pipe(
            tap((files: TranslationFile[]) => {
                // Update the content of the files
                for (const file of files) {
                    if (item.translations && item.route) {
                        // New item
                        TranslationsOperation.updateItemAtRouteByRef(
                            file,
                            item.route,
                            item.title,
                            item.translations[file.languageKey] ?? item.translations['en'],
                            true
                        );
                    } else {
                        // New topic
                        if (item.route)
                            TranslationsOperation.updateItemAtRouteByRef(file, item.route, item.title, {}, true);
                    }
                }
            }),
            // Publish changes to BE
            switchMap((files: TranslationFile[]) =>
                forkJoin(files.map(file => this.updateTranslationFile(frontendType, file)))
            )
        );
    }

    public addNewTranslationLanguage(frontendType: FrontendType, newLang: string): Observable<void> {
        let isBrandNewLanguage = false;

        return this.getAvailableLanguages(frontendType).pipe(
            tap(availableLangs => (isBrandNewLanguage = !availableLangs.includes(newLang))),
            map(availableLangs => (isBrandNewLanguage ? [...availableLangs, newLang] : availableLangs)),
            catchError((error: HttpErrorResponse): Observable<any> => {
                if (error.status === 404) {
                    // Could be that the 'available-languages.json' file doesn't exist yet.
                    isBrandNewLanguage = true;
                    return of([newLang]);
                }
                return throwError(() => error);
            }),
            this.httpHelperService.handleError(`Could not add language: ${newLang}`),
            tap(() => {
                if (!isBrandNewLanguage) {
                    this.snackbarService.open({
                        title: 'Already exists',
                        description: `The language '${newLang}' already exists`,
                        type: SnackbarType.INFO,
                        duration: 3000,
                        position: SnackbarPosition.BOTTOM
                    });
                }
            }),
            filter(() => isBrandNewLanguage),
            switchMap(() => this.cloneTranslationFile(frontendType, 'en', newLang)),
            tap(() =>
                this.snackbarService.open({
                    title: 'Added language',
                    description: `Successfully added language for ${newLang}`,
                    type: SnackbarType.SUCCESS,
                    duration: 3000,
                    position: SnackbarPosition.BOTTOM
                })
            )
        );
    }

    public createInvalidation(): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/translations/files/invalidate`, null).pipe(
            tap(() =>
                this.snackbarService.open({
                    title: 'Invalidated',
                    description: `Successfully created invalidations for translations`,
                    type: SnackbarType.SUCCESS,
                    duration: 3000,
                    position: SnackbarPosition.BOTTOM
                })
            )
        );
    }

    public mergeFromPreviousStage(frontendType: FrontendType): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/translations/merging/${frontendType.toString()}`, null).pipe(
            this.httpHelperService.handleError(
                `Could not merge changes from previous stage for ${frontendType.toString()}`
            ),
            tap(() =>
                this.snackbarService.open({
                    title: 'Merged',
                    description: `Successfully merged changes from stage for ${frontendType.toString()}`,
                    type: SnackbarType.SUCCESS,
                    duration: 3000,
                    position: SnackbarPosition.BOTTOM
                })
            )
        );
    }

    private cloneTranslationFile(frontendType: FrontendType, originLang: string, destLang: string): Observable<void> {
        return this.getTranslations(originLang, frontendType).pipe(
            tap(file => (file.languageKey = destLang)),
            catchError((error: string): Observable<any> => {
                if (error === `Could not fetch ${originLang} Translations`) {
                    // Could be that the file doesn't exist yet.
                    return of({ languageKey: destLang, file: {} } as TranslationFile);
                }
                return throwError(() => error);
            }),
            this.httpHelperService.handleError(`Error during cloning the translation file for ${destLang}`),
            switchMap(file => this.updateTranslationFile(frontendType, file as TranslationFile))
        );
    }
}
