import { ScdPickerBaseValue } from '@siemens/component-lib';

export class LoadWorkersCouncilGroups {
    static readonly type = '[WorkersCouncilGroup] Load';
    constructor(public sorted: boolean) {}
}
export class LazyLoadWorkersCouncilGroups {
    static readonly type = '[WorkersCouncilGroup] Lazy Load';
    constructor(public sorted: boolean) {}
}
export class AddDelegate {
    static readonly type = '[WorkersCouncilGroup] Add Delegate';
    constructor(public groupId: string, public user: ScdPickerBaseValue) {}
}

export class RemoveDelegate {
    static readonly type = '[WorkersCouncilGroup] Remove Delegate';
    constructor(public groupId: string, public gid: string) {}
}

export class LoadDelegateUsers {
    static readonly type = '[WorkersCouncilGroup] Load Delegate Users';
}
