import { Injectable } from '@angular/core';
import { catchError, defer, EMPTY, filter, iif, map, Observable, of, switchMap, tap } from 'rxjs';
import { IJwtIdTokenClaims, GbrUser } from 'interfaces/src';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadProfilePicture, SetUser } from '../state/user.action';
import { UserManagementService } from '../../user-management/services/user-management.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public isAuthenticated$: Observable<boolean>;
    private gbrUser: GbrUser;

    constructor(
        private securityService: OidcSecurityService,
        private userService: UserManagementService,
        private store$: Store,
        private router: Router
    ) {
        this.isAuthenticated$ = this.securityService.checkAuth().pipe(
            filter(user => !!user?.userData),
            switchMap(user => this.getUserRoles(user.userData)),
            tap(auth => this.dispatchUser(auth.isAuthenticated, auth.user)),
            tap(() => this.loadProfilePicture()),
            map(auth => auth.isAuthenticated)
        );
    }

    public login(): void {
        const url = window.location.pathname + window.location.search;
        window.localStorage.setItem('redirectUrl', url);
        this.securityService.authorize();
    }

    public get accessToken$() {
        return this.securityService.getAccessToken();
    }

    private dispatchUser(isAuthenticated: boolean, user: GbrUser): void {
        this.store$.dispatch(
            new SetUser({
                isAuthenticated,
                user
            })
        );
    }

    private getUserRoles(jwt: IJwtIdTokenClaims): Observable<{ user: GbrUser; isAuthenticated: boolean }> {
        if (this.gbrUser) {
            return of({ user: this.gbrUser, isAuthenticated: true });
        }
        return defer(() => {
            if (jwt) {
                return this.fetchUser(jwt);
            }
            return EMPTY.pipe(
                tap(() => {
                    this.router.navigate(['/auth/unauthorized']);
                })
            );
        });
    }

    private fetchUser(
        user: IJwtIdTokenClaims
    ): Observable<{ user: GbrUser; isAuthenticated: boolean } | { user: GbrUser; isAuthenticated: boolean }> {
        return this.userService.getUser(user['custom:gid']).pipe(
            switchMap(gbrUser => {
                this.gbrUser = this.mapUserToGbrUser(user, gbrUser.roles);
                return of({ user: this.gbrUser, isAuthenticated: true });
            }),
            catchError(err => {
                console.log('User can not be received', err);
                this.gbrUser = this.mapUserToGbrUser(user, ['User']);
                return of({ user: this.gbrUser, isAuthenticated: true });
            })
        );
    }

    private mapUserToGbrUser(authUser: IJwtIdTokenClaims, roles: string[]): GbrUser {
        return {
            gid: authUser?.sub,
            firstName: authUser?.given_name,
            lastName: authUser?.family_name,
            nickName: authUser.nickname,
            email: authUser?.email,
            orgCode: authUser?.['custom:org_code'],
            country: authUser?.['custom:country'],
            roles
        } as GbrUser;
    }

    private loadProfilePicture(): void {
        this.store$.dispatch(new LoadProfilePicture());
    }

    public logout(): void {
        this.securityService.logoffLocal();
        this.securityService.logoffAndRevokeTokens().subscribe();
        this.securityService.logoff().subscribe();
        window.location.reload();
    }
}
