import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ButtonModule } from '@siemens/component-lib';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-payout-success',
    standalone: true,
    templateUrl: './payout-success.component.html',
    imports: [ButtonModule, TranslateModule]
})
export class PayoutSuccessComponent {
    constructor(private router: Router, private store: Store) {}

    public onBackToHome(): void {
        this.router.navigate(['/']);
    }
}
