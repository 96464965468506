import { ApplicationStatus } from '../enums';
import { Child } from './child';
import { SalaryRangeItem } from './salary';
import { Season } from './season';
import { Spouse } from './spouse';
import { User } from './user';
import { Dinero } from 'dinero.js';

export interface Application<MoneyType = Dinero> {
    id: string;
    employeeGid: string;
    seasonId: string;
    delegateGid?: string;
    submittedAt?: Date;
    isSingleParent?: boolean;
    spouse?: Spouse;
    children?: Child[];
    isDisabled?: boolean;
    isCaregiver?: boolean;
    isOnParentalLeave?: boolean;
    isShiftWorker?: boolean;
    isPartTime?: boolean;
    salaryRange?: SalaryRangeItem;
    hasGivenConsent?: boolean;
    status: ApplicationStatus;
    travelStartDate: Date;
    travelEndDate: Date;
    createdAt: Date;
    evaluationPoints?: number;
    isSelectedForPayout?: boolean;
    emailPrivate?: string;
    confirmationOfDataCorrectness?: boolean;
    potentialPayout?: MoneyType;
    lastYearPayout?: MoneyType;
    comment?: string;
}

export interface ApplicationWithSeasonDetails<MoneyType = Dinero> extends Application<MoneyType> {
    season: Season<MoneyType>;
}

export interface UserApplication<MoneyType = Dinero> extends Application<MoneyType> {
    employee: User;
    delegate?: User;
}
