<div class="flex flex-col bg-cardBackgroundColor mt-8 mb-8 p-4 rounded">
    <div class="flex flex-col">
        <p>{{ title }}</p>
        <p class="text-darkGraySubtitleColor mt-2 mb-2">{{ subtitle }}</p>
        <div class="flex flex-row justify-between text-darkGraySubtitleColor mb-2">
            <p>
                {{ 'seasons.season-details.setting-cards.social-criteria-card.criteria-label' | translate }}
            </p>
            <p class="pr-5">
                {{ 'seasons.season-details.setting-cards.social-criteria-card.weight-label' | translate }}
            </p>
        </div>
        <div *ngFor="let socialCriteria of socialCriterias">
            <div class="flex justify-between">
                <p>
                    {{
                        'seasons.season-details.setting-cards.social-criteria-card.' +
                            (socialCriteria.joinedEntityName ? socialCriteria.joinedEntityName + '-' : '') +
                            socialCriteria.property | translate
                    }}
                </p>
                <p class="pr-8">{{ socialCriteria.weight }}</p>
            </div>
            <hr class="border-separatorColor mt-2 mb-2" />
        </div>
    </div>
</div>
