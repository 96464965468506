import {
    ElementRef,
    HostBinding,
    Component,
    OnInit,
    ViewChild,
    Input,
    Optional,
    Self,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TableFilterOption } from '../table.component';

@Component({
    selector: 'app-autocomplete-multi-select',
    templateUrl: './autocomplete-multi-select.component.html',
    styleUrls: ['./autocomplete-multi-select.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: AutocompleteMultiSelectComponent }]
})
export class AutocompleteMultiSelectComponent implements OnInit, MatFormFieldControl<string>, OnInit, OnDestroy {
    @ViewChild('inputTrigger', { read: MatAutocompleteTrigger }) inputTrigger: MatAutocompleteTrigger;

    @Input() items: TableFilterOption[] = new Array<TableFilterOption>();
    @Input() selectedItems: string[] = new Array<string>();
    @Output() selectedItemsChange = new EventEmitter<string[]>();

    itemControl = new FormControl();
    stateChanges = new Subject<void>();
    private _placeholder: string;
    static nextId = 0;
    @HostBinding() id = `input-ac-${AutocompleteMultiSelectComponent.nextId++}`;
    @HostBinding('attr.aria-describedby') describedBy = '';

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() set value(value: any) {
        if (value) {
            this.selectedItems = value;
        }
        this.stateChanges.next();
        this.selectedItemsChange.emit(this.selectedItems);
    }

    get value() {
        return this.selectedItems;
    }

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    private changeCallback: () => void;
    private touchedCallback: () => void;
    focused = false;
    isAllSelected = false;

    filteredItems: TableFilterOption[] = this.items;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
        private cd: ChangeDetectorRef
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }

    empty: boolean;
    shouldLabelFloat: boolean;
    required: boolean;
    disabled: boolean;
    errorState: boolean;
    controlType?: string;
    autofilled?: boolean;
    userAriaDescribedBy?: string;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onContainerClick(_event: MouseEvent): void {
        throw new Error('Method not implemented.');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    writeValue(value: any) {
        console.log(value, 'Inside writeValue');
    }

    registerOnChange(fn: () => void) {
        this.changeCallback = fn;
    }

    registerOnTouched(fn: () => void) {
        this.touchedCallback = fn;
    }

    ngOnInit() {
        this.filteredItems = this.items;
        this.itemControl.valueChanges
            .pipe(
                startWith(''),
                map(filter => this.filter(filter as string))
            )
            .subscribe(data => (this.filteredItems = data));
    }

    clicker() {
        this.inputTrigger.openPanel();
    }

    filter(filter: string): TableFilterOption[] {
        if (filter) {
            return this.items.filter(option => option.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
        } else {
            return this.items;
        }
    }

    optionClicked(event: Event, item: string) {
        event.stopPropagation();
        this.toggleSelection(item);
    }

    toggleSelection(item: string) {
        if (!this.selectedItems.includes(item)) {
            this.selectedItems.push(item);
            this.selectedItemsChange.emit(this.selectedItems);
        } else {
            const i = this.selectedItems.findIndex(value => value === item);
            this.selectedItems.splice(i, 1);
            this.selectedItemsChange.emit(this.selectedItems);
        }
    }

    isSelected(item: string) {
        return this.selectedItems.includes(item);
    }

    ngOnDestroy() {
        this.fm.stopMonitoring(this.elRef.nativeElement);
        this.stateChanges.complete();
    }

    public remove(item: string) {
        const i = this.selectedItems.findIndex(value => value === item);
        this.selectedItems.splice(i, 1);
        this.selectedItemsChange.emit(this.selectedItems);
    }
}
