import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-season-timeline',
    standalone: true,
    templateUrl: './season-timeline.component.html',
    imports: [CommonModule]
})
export class SeasonTimelineComponent implements OnInit, OnChanges {
    @Input() startDate!: string | Date;
    @Input() endDate!: string | Date;
    @Input() applicationDeadlineDate!: string | Date;

    public start: Date;
    public end: Date;
    public deadline: Date;

    public whiteBarWidth: number;
    public greenBarWidth: number;
    public totalWidth: number;

    public ngOnInit(): void {
        this.convertDates();
        this.calculateWidths();
    }

    public ngOnChanges(): void {
        this.convertDates();
        this.calculateWidths();
    }

    private convertDates(): void {
        this.start = new Date(this.startDate);
        this.end = new Date(this.endDate);
        this.deadline = new Date(this.applicationDeadlineDate);
    }

    private calculateWidths(): void {
        const totalDuration = this.deadline.getTime() - this.start.getTime();
        const whiteDuration = this.end.getTime() - this.start.getTime();
        const greenDuration = this.deadline.getTime() - this.end.getTime();

        this.totalWidth = 100; // total percentage
        this.whiteBarWidth = (whiteDuration / totalDuration) * this.totalWidth;
        this.greenBarWidth = (greenDuration / totalDuration) * this.totalWidth;
    }

    public getMonthLabels(): string[] {
        const startMonthIndex = this.start.getMonth();
        const endMonthIndex = this.deadline.getMonth();
        const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];

        const labels = [];
        let currentMonthIndex = startMonthIndex;
        let currentYear = this.start.getFullYear();

        while (
            currentYear < this.deadline.getFullYear() ||
            (currentYear === this.deadline.getFullYear() && currentMonthIndex <= endMonthIndex)
        ) {
            labels.push(monthLabels[currentMonthIndex] + ' ' + currentYear.toString().slice(-2));
            currentMonthIndex = (currentMonthIndex + 1) % 12;
            if (currentMonthIndex === 0) {
                currentYear++;
            }
        }

        return labels;
    }

    public getYearLabel(monthLabel: string): string {
        const [month, year] = monthLabel.split(' ');
        return year;
    }
}
