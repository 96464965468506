<div class="p-8 pt-6">
    <h1 class="text-xl">{{ 'translations.dialogs.add-language.title' | translate }}</h1>

    <div class="dialog-content">
        <sag-ui-select
            [placeholder]="'translations.dialogs.add-language.select-new-language' | translate"
            [label]="'translations.dialogs.add-language.select-new-language' | translate"
            (change)="selectLanguage($event)"
        >
            @for (lang of langsToSelect; track lang;) {
            <sag-ui-option [value]="lang"> {{ 'translations.' + lang | translate }} </sag-ui-option>
            }
        </sag-ui-select>
    </div>

    <div class="flex justify-end items-center gap-2">
        <sag-ui-button color="secondary" (click)="cancel()">
            {{ 'translations.dialogs.add-language.cancel' | translate }}
        </sag-ui-button>
        <sag-ui-button (click)="proceed()" [disabled]="!langSelected">
            {{ 'translations.dialogs.add-language.confirm' | translate }}
        </sag-ui-button>
    </div>
</div>
