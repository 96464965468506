import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogConfig, DialogRef } from '@siemens/component-lib';
import { SharedModule } from '../../shared.module';

@Component({
    selector: 'app-basic-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './basic-dialog.component.html',
    styleUrl: './basic-dialog.component.css'
})
export class BasicDialogComponent {
    constructor(public config: DialogConfig, public dialogRef: DialogRef<string>) {}

    public onClose(result?: string): void {
        this.dialogRef.close(result);
    }
}
