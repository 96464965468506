import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of, tap } from 'rxjs';
import { GbrUser } from 'interfaces/src';
import { LoadProfilePicture, SetUser } from './user.action';
import { Roles } from '../../shared/enums/roles';
import { UserManagementService } from '../../user-management/services/user-management.service';

export interface UserStateModel {
    user?: GbrUser;
    isAuthenticated: boolean;
    profilePicture?: string;
}

@State<UserStateModel>({
    name: 'userState',
    defaults: {
        user: undefined,
        isAuthenticated: false,
        profilePicture: undefined
    }
})
@Injectable()
export class UserState {
    constructor(private userService: UserManagementService) {}

    @Selector()
    static user(state: UserStateModel): GbrUser | undefined {
        return state?.user;
    }

    @Selector()
    static profilePicture(state: UserStateModel): string | undefined {
        return state.profilePicture;
    }

    @Selector()
    static isAdmin(state: UserStateModel): boolean | undefined {
        return state?.user.roles?.includes(Roles.ADMIN);
    }

    @Selector()
    static isProcessManager(state: UserStateModel): boolean | undefined {
        return state?.user.roles?.includes(Roles.PROCESS_MANAGER);
    }

    @Action(SetUser)
    public setUser({ patchState }: StateContext<UserStateModel>, { payload }: SetUser) {
        return of(patchState({ user: payload.user, isAuthenticated: payload.isAuthenticated }));
    }

    @Action(LoadProfilePicture)
    public setProfilePicture({ patchState }: StateContext<UserStateModel>) {
        return this.userService.getProfilePicture().pipe(tap(profilePicture => patchState({ profilePicture })));
    }
}
