import {
    Component,
    Input,
    ViewChild,
    AfterViewInit,
    ComponentFactoryResolver,
    Type,
    ComponentRef,
    EventEmitter,
    Output,
    ViewContainerRef
} from '@angular/core';

@Component({
    selector: 'app-component-wrapper',
    template: '<ng-container #container></ng-container>'
})
export class TableComponentWrapperComponent implements AfterViewInit {
    @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() component: Type<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() componentInit: EventEmitter<any> = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentRef: ComponentRef<any>;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    ngAfterViewInit() {
        const factory = this.componentFactoryResolver.resolveComponentFactory(this.component);
        this.componentRef = this.container.createComponent(factory);
        this.componentInit.emit(this.componentRef.instance);
    }
}
