<div class="p-6 pt-4">
    <h3 class="text-xl mb-2">{{ 'workers-council-groups.select-delegate-dialog.title' | translate }}</h3>
    <p class="text-base mb-4">{{ 'workers-council-groups.select-delegate-dialog.description' | translate }}</p>
    <sag-ui-autocomplete
        [compareWith]="compareFn"
        [formControl]="selectControl"
        label="{{ 'workers-council-groups.select-delegate-dialog.label' | translate }}"
    >
        @for (user of filteredUsers$ | async; track user.gid) {
        <sag-ui-autocomplete-option [value]="user"
            >{{ user.firstName }} {{ user.lastName }} ({{ user.gid }})</sag-ui-autocomplete-option
        >
        }
    </sag-ui-autocomplete>
    <div class="flex justify-end items-center gap-2 mt-8">
        <sag-ui-button color="secondary" icon="chevron-left" (click)="onClose()">
            {{ 'workers-council-groups.select-delegate-dialog.cancel' | translate }}
        </sag-ui-button>
        <sag-ui-button (click)="onClose('confirm')">
            {{ 'workers-council-groups.select-delegate-dialog.confirm' | translate }}
        </sag-ui-button>
    </div>
</div>
