export const environment = {
    type: 'Dev',
    production: true,
    apiUrl: 'https://api.admin.dev.gbr.erholung.siemens.com',
    translationsUrl: 'https://dev.files.translations.gbr.erholung.siemens.com',
    customerAppUrl: 'https://dev.gbr.erholung.siemens.com',
    authentication: {
        unauthorizedRoute: '/auth/unauthorized',
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: '2vvp47rdt3cie8ja295ldlm0nk',
        authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg',
        authWellknownEndpointUrl:
            'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg/.well-known/openid-configuration',
        scope: 'openid profile email',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        autoUserInfo: true,
        renewTimeBeforeTokenExpiresInSeconds: 300,
        renewUserInfoAfterTokenRenew: true,
        ignoreNonceAfterRefresh: true
    }
};
