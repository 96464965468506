<form class="flex flex-col mb-4" [formGroup]="socialCriteria">
    <div class="flex flex-row items-center justify-start gap-2 mb-2">
        <h2 class="text-xl font-bold">{{ 'seasons.season-creation.social-criteria.title' | translate }}</h2>
        <span
            class="icon-edit cursor-pointer text-lg text-[#00CCCC] hover:text-[#00FFB9]"
            *ngIf="enableIcon"
            (click)="toggleDisable()"
        ></span>
    </div>
    <p class="mb-4 text-base text-[#F3F3F0]">{{ 'seasons.season-creation.social-criteria.subtitle' | translate }}</p>
    <div class="max-w-[500px]">
        <div class="flex flex-row justify-between text-darkGraySubtitleColor">
            <p>{{ 'seasons.season-creation.social-criteria.criteria-column-label' | translate }}</p>
            <p class="pr-5">{{ 'seasons.season-creation.social-criteria.weight-column-label' | translate }}</p>
        </div>
        <ng-container *ngFor="let criteria of socialCriterias">
            <app-weight-counter
                [label]="criteria.socialCriteriaLabel | translate"
                [formControl]="getCriterion(criteria.criterion)"
                [min]="0"
                [max]="10"
                [isDisabled]="isDisabled"
            ></app-weight-counter>
        </ng-container>
    </div>
</form>
