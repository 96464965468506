import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, CoreModule } from '@siemens/component-lib';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
    declarations: [DashboardComponent],
    imports: [CommonModule, CoreModule.forRoot('sag'), SharedModule, ButtonModule],
    exports: [DashboardComponent]
})
export class DashboardModule {}
