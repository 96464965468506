import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@siemens/component-lib';

@Component({
    selector: 'app-add-language',
    templateUrl: './add-language.component.html',
    styleUrls: ['./add-language.component.scss']
})
export class AddLanguageComponent {
    langSelected?: string;
    langsToSelect = ['en', 'de'];

    constructor(private translate: TranslateService, private dialogRef: DialogRef<string>) {}

    proceed(): void {
        if (this.langSelected) {
            this.dialogRef.close(this.langSelected);
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }

    langDisplayFn(lang: string): string {
        return this.translate.instant(`general.lang.${lang}`) as string;
    }

    selectLanguage(event): void {
        this.langSelected = event;
    }
}
