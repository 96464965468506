<form class="flex flex-col" [formGroup]="timeframe">
    <div class="flex flex-row items-center justify-start gap-2 mb-2">
        <h2 class="text-xl font-bold">{{ 'seasons.season-creation.timeframe.title' | translate }}</h2>
        <span
            class="icon-edit cursor-pointer text-lg text-[#00CCCC] hover:text-[#00FFB9]"
            *ngIf="enableIcon"
            (click)="toggleDisable()"
        ></span>
    </div>
    <p class="mb-4 text-base text-[#F3F3F0]">{{ 'seasons.season-creation.timeframe.subtitle' | translate }}</p>

    <div class="mb-6 max-w-[400px] flex flex-col gap-4">
        <sag-ui-date-range-picker
            [formGroup]="timeframe"
            formControlNameStart="startDate"
            formControlNameEnd="endDate"
            label="{{ 'seasons.season-creation.timeframe.timeframe' | translate }}"
            placeholder="01.01.2025 - 31.12.2025"
            [disabled]="isDisabled"
            [required]="true"
            [error]="errorMessage('startDate') || errorMessage('endDate') | translate"
            [invalid]="isInvalid('startDate') || isInvalid('endDate')"
        ></sag-ui-date-range-picker>
        <sag-ui-input
            type="date"
            formControlName="applicationDeadlineDate"
            label="{{ 'seasons.season-creation.timeframe.application-deadline-date' | translate }}"
            placeholder="01.01.2026"
            [disabled]="isDisabled"
            [required]="true"
            [error]="errorMessage('applicationDeadlineDate') | translate"
            [invalid]="isInvalid('applicationDeadlineDate')"
        ></sag-ui-input>
    </div>
</form>
