export interface GbrUser {
    email?: string;
    firstName: string;
    lastName: string;
    nickName: string;
    orgCode: string;
    gid: string;
    roles?: string[];
    delegateForGroups?: {
        id: string;
        name: string;
        unit: string;
    }[];
}
