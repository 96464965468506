import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SalaryRangeItem } from 'libs/interfaces/src';

@Component({
    selector: 'app-salary-card',
    standalone: true,
    templateUrl: './salary-card.component.html',
    imports: [CommonModule, TranslateModule]
})
export class SalaryCardComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() salaryRangeItems: SalaryRangeItem[];
}
