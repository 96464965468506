import { ITheme } from '../../app/shared/interfaces/ITheme';

export const lightTheme: ITheme = {
    bgColorPrimary: '#ffffff',
    textColor: '#000028',
    petrolGradient: 'linear-gradient(180deg, #00FFB9 0%, #00E6DC 100%)',
    highlightColor: '#009999',
    actionColor: '#007993',
    actionHoverColor: '#005159',
    actionDisabledColor: '#9999A9',
    actionDisabledBgColor: '#E5E5E9',
    bgColorSecondary: '#F3F3F0',
    bgHoverColor: '#E8E8E3',
    textColorSecondary: '#66667e',
    warningColor: '#B81E31',
    inputBgColor: '#ebf7f8',
    inputBorderColor: '#4C4C68',
    inputActiveBgColor: '#d1fff2',
    inputActiveBorderColor: '#005159',
    radioButtonBorderColor: '#4C4C68',
    radioButtonBGColor: 'transparent',
    slideThumbColor: '#ffffff',
    slideBarColor: '#7D8099',
    slideThumbActiveColor: '#196269',
    slideBarActiveColor: '#005159',
    slideBarDisabledColor: '#EBEBEE',
    slideThumbDisabledColor: '#CCCCD4',
    tabBorder: '#CCCCD4',
    tabLabelActiveColor: '#005159',
    tooltipBG: '#000028',
    tooltipSuccess: '#01893a',
    tooltipWarning: '#FFD732',
    tooltipError: '#D72339',
    heroBtnBgHover: '#62EEC7',
    inputErrorBG: '#FCCCD7',
    checkboxSelectedCheck: '#ffffff',
    checkboxSelectedBG: '#005159',
    checkboxDisabledBorder: '#7D8099',
    expansionPanelBorder: '#000028',
    expansionPanelHoverBG: '#D1FFF2',
    expansionPanelHoverBorder: '#005159',
    primaryBtnBG: '#007993',
    primaryBtnBGHover: '#005159',
    primaryBtnText: '#ffffff',
    primaryBtnTextHover: '#ffffff',
    secondaryBtnBG: '#ffffff',
    secondaryBtnBGHover: '#D1FFF2',
    secondaryBtnText: '#007993',
    secondaryBtnTextHover: '#005159',
    tetriaryBtnBG: '#ffffff',
    tetriaryBtnBGHover: '#D1FFF2',
    tetriaryBtnText: '#007993',
    tetriaryBtnTextHover: '#005159',
    snackbarBg: '#ffffff',
    inputBoxShadow: '#0000281E',
    focusOutline: '#1491EB',
    inputDisabledBg: '#ebf7f8',
    inputDisabledBorder: '#CCCCD4'
};

export const darkTheme: ITheme = {
    bgColorPrimary: '#000028',
    bgColorSecondary: '#23233C',
    bgHoverColor: '#37374D',
    textColor: '#ffffff',
    petrolGradient: 'linear-gradient(180deg, #00FFB9 0%, #00E6DC 100%)',
    highlightColor: '#009999',
    actionColor: '#00CCCC',
    actionHoverColor: '#00FFB9',
    actionDisabledColor: '#737389',
    actionDisabledBgColor: '#333353',
    textColorSecondary: '#9999A9',
    warningColor: '#FF7687',
    inputBgColor: '#00183B',
    inputBorderColor: '#B3B3BE',
    inputActiveBgColor: '#001F39',
    inputActiveBorderColor: '#00FFB9',
    radioButtonBorderColor: '#B3B3BE',
    radioButtonBGColor: '#262648',
    slideThumbColor: '#000028',
    slideBarColor: '#9999A9',
    slideThumbActiveColor: '#FFFFFF',
    slideBarActiveColor: '#00E5AA',
    slideBarDisabledColor: '#333353',
    slideThumbDisabledColor: '#737389',
    tabBorder: '#737389',
    tabLabelActiveColor: '#00FFB9',
    tooltipBG: '#F3F3F0',
    tooltipSuccess: '#01D65A',
    tooltipWarning: '#FFD732',
    tooltipError: '#FF2640',
    heroBtnBgHover: '#C5FFEF',
    inputErrorBG: '#331131',
    checkboxSelectedCheck: '#000028',
    checkboxSelectedBG: '#00FFB9',
    checkboxDisabledBorder: '#737389',
    expansionPanelBorder: '#FFFFFF',
    expansionPanelHoverBG: '#001F39',
    expansionPanelHoverBorder: '#00FFB9',
    primaryBtnBG: '#00CCCC',
    primaryBtnBGHover: '#00FFB9',
    primaryBtnText: '#000028',
    primaryBtnTextHover: '#000028',
    secondaryBtnBG: '#000028',
    secondaryBtnBGHover: '#001F39',
    secondaryBtnText: '#00CCCC',
    secondaryBtnTextHover: '#00FFB9',
    tetriaryBtnBG: '#000028',
    tetriaryBtnBGHover: '#001F39',
    tetriaryBtnText: '#00CCCC',
    tetriaryBtnTextHover: '#00FFB9',
    snackbarBg: '#23233C',
    inputBoxShadow: '#0000281E',
    focusOutline: '#1491EB',
    inputDisabledBg: '#262648',
    inputDisabledBorder: '#4C4C68'
};
