<form class="flex flex-col" [formGroup]="budget">
    <div class="flex flex-row items-center justify-start gap-2 mb-2">
        <h2 class="text-xl font-bold">{{ 'seasons.season-creation.budget.title' | translate }}</h2>
        <span
            class="icon-edit cursor-pointer text-lg text-[#00CCCC] hover:text-[#00FFB9]"
            *ngIf="enableIcon"
            (click)="toggleDisable()"
        ></span>
    </div>
    <p class="mb-4 text-base text-[#F3F3F0]">{{ 'seasons.season-creation.budget.subtitle' | translate }}</p>

    <div class="mt-6 mb-10 w-2/5">
        <!-- Budget for Calendar Year -->
        <sag-ui-input
            class="p-0 border-0"
            formControlName="totalBudget"
            [label]="calendarYearBudgetLabel | translate"
            [disabled]="isDisabled"
            [required]="true"
            (keydown)="preventNonNumericInput($event)"
            type="number"
        >
            <span ngProjectAs="end-decorator" class="text-[#F3F3F0] pr-4 h-full flex items-center justify-center">
                €
            </span>
        </sag-ui-input>
    </div>

    <div class="border-b border-customBorder pb-2 mb-4 w-3/5" formGroupName="employee">
        <!-- Employee -->
        <p class="text-lg pb-4 text-[budgetInputGroupLabelColor]">
            {{ 'seasons.season-creation.budget.employee' | translate }}
        </p>
        <!-- 2 inputs with labels -->
        <div class="flex flex-row justify-between">
            <div class="pr-4 pb-2 w-full" formGroupName="firstSubsidy">
                <sag-ui-input
                    class="p-0 border-0"
                    [label]="shortestSubsidyLabel | translate"
                    formControlName="amount"
                    [disabled]="isDisabled"
                    [required]="true"
                    (keydown)="preventNonNumericInput($event)"
                    type="number"
                    ><span
                        ngProjectAs="end-decorator"
                        class="text-[#F3F3F0] pr-4 h-full flex items-center justify-center"
                    >
                        €
                    </span></sag-ui-input
                >
            </div>
            <div class="pb-2 w-full" formGroupName="secondSubsidy">
                <sag-ui-input
                    class="p-0 border-0"
                    [label]="longestSubsidyLabel | translate"
                    formControlName="amount"
                    [disabled]="isDisabled"
                    [required]="true"
                    (keydown)="preventNonNumericInput($event)"
                    type="number"
                    ><span
                        ngProjectAs="end-decorator"
                        class="text-[#F3F3F0] pr-4 h-full flex items-center justify-center"
                    >
                        €
                    </span></sag-ui-input
                >
            </div>
        </div>
    </div>

    <div class="border-b border-customBorder pb-2 mb-4 w-3/5" formGroupName="spouse">
        <!-- Spouse -->
        <p class="text-lg pb-4 text-[budgetInputGroupLabelColor]">
            {{ 'seasons.season-creation.budget.spouse' | translate }}
        </p>
        <!-- 2 inputs with labels -->
        <div class="flex flex-row justify-between">
            <div class="pr-4 pb-2 w-full" formGroupName="firstSubsidy">
                <sag-ui-input
                    class="pr-4w-full p-0 border-0"
                    [label]="shortestSubsidyLabel | translate"
                    formControlName="amount"
                    [disabled]="isDisabled"
                    [required]="true"
                    (keydown)="preventNonNumericInput($event)"
                    type="number"
                    ><span
                        ngProjectAs="end-decorator"
                        class="text-[#F3F3F0] pr-4 h-full flex items-center justify-center"
                    >
                        €
                    </span></sag-ui-input
                >
            </div>
            <div class="pb-2 w-full" formGroupName="secondSubsidy">
                <sag-ui-input
                    class="w-full p-0 border-0"
                    [label]="longestSubsidyLabel | translate"
                    formControlName="amount"
                    [disabled]="isDisabled"
                    [required]="true"
                    (keydown)="preventNonNumericInput($event)"
                    type="number"
                    ><span
                        ngProjectAs="end-decorator"
                        class="text-[#F3F3F0] pr-4 h-full flex items-center justify-center"
                    >
                        €
                    </span></sag-ui-input
                >
            </div>
        </div>
    </div>

    <div class="mb-4 w-3/5" formGroupName="child">
        <p class="text-lg pb-4 text-[budgetInputGroupLabelColor]">
            {{ 'seasons.season-creation.budget.child' | translate }}
        </p>
        <div class="flex flex-row justify-between">
            <div class="pr-4 w-full" formGroupName="firstSubsidy">
                <sag-ui-input
                    class="w-full p-0 border-0"
                    [label]="shortestSubsidyLabel | translate"
                    formControlName="amount"
                    [disabled]="isDisabled"
                    [required]="true"
                    (keydown)="preventNonNumericInput($event)"
                    type="number"
                    ><span
                        ngProjectAs="end-decorator"
                        class="text-[#F3F3F0] pr-4 h-full flex items-center justify-center"
                    >
                        €
                    </span></sag-ui-input
                >
            </div>
            <div class="w-full" formGroupName="secondSubsidy">
                <sag-ui-input
                    class="w-full p-0 border-0"
                    [label]="longestSubsidyLabel | translate"
                    formControlName="amount"
                    [disabled]="isDisabled"
                    [required]="true"
                    (keydown)="preventNonNumericInput($event)"
                    type="number"
                    ><span
                        ngProjectAs="end-decorator"
                        class="text-[#F3F3F0] pr-4 h-full flex items-center justify-center"
                    >
                        €
                    </span></sag-ui-input
                >
            </div>
        </div>
    </div>
</form>
