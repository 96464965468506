import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ButtonModule } from '@siemens/component-lib';
import { ResetSeasonForm, ResetStepper } from '../../../state/season.action';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-season-success',
    standalone: true,
    templateUrl: './season-success.component.html',
    imports: [ButtonModule, TranslateModule]
})
export class SeasonSuccessComponent {
    constructor(private router: Router, private store: Store) {}

    public onBackToHome(): void {
        this.router.navigate(['/']);
        this.store.dispatch(new ResetStepper());
        this.store.dispatch(new ResetSeasonForm());
    }

    public handleNavigateToSeasonsOverview(): void {
        this.router.navigate(['/seasons']);
    }
}
