import { CommonModule } from '@angular/common';
import { Component, computed, ElementRef, inject, Input, OnInit, Signal, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, CheckboxModule, DialogService, TooltipModule } from '@siemens/component-lib';
import { select, Store } from '@ngxs/store';
import { SeasonState } from '../../../state/season.state';
import { filter, fromEvent, map, Observable, switchMap, tap } from 'rxjs';
import { Season, SeasonStatus, UserApplication } from 'libs/interfaces/src';
import {
    DeleteApplicationComment,
    LazyLoadApplications,
    ToggleSelectedForPayout,
    UpdateApplicationComment
} from '../../../state/season.action';
import { scrollOverAmountElement } from '../../../helpers/tiny-helpers';
import { environment } from '../../../../../environments/environment';
import { CommentDialogComponent } from '../comment-dialog/comment-dialog.component';
import { ApplicationStatus } from '../../../enums/application-status.enum';
import { FinancialOverviewResponse } from '../../../models/season-financial.model';

@Component({
    selector: 'app-season-applications-table',
    standalone: true,
    templateUrl: './applications-table.component.html',
    imports: [TranslateModule, CommonModule, CheckboxModule, ButtonModule, TooltipModule]
})
export class SeasonApplicationsTableComponent implements OnInit {
    @Input() columnNames: string[] = [];
    @ViewChild('table', { static: true }) table!: ElementRef;
    public applications$: Signal<UserApplication[] | null> = select(SeasonState.getApplications);
    public season$: Observable<Season> = inject(Store).select(SeasonState.getSeason);
    public financialOverview$: Signal<FinancialOverviewResponse> = select(SeasonState.getFinancialOverview);
    public isLoading$: Observable<boolean | null> = inject(Store).select(SeasonState.isLoadingSeasons);
    public allApplicationsLoaded$: Signal<boolean> = select(SeasonState.allApplicationsLoaded);
    private isLazyLoadingApplications$: Signal<boolean> = select(SeasonState.isLazyLoadingApplications);
    public seasonStatus = SeasonStatus;
    public applicationStatus = ApplicationStatus;

    public statusColor = {
        [ApplicationStatus.DRAFT]: '#00BEDC',
        [ApplicationStatus.SUBMITTED]: '#00BEDC',
        [ApplicationStatus.APPROVED]: '#01D65A',
        [ApplicationStatus.DECLINED]: '#FF2640',
        [ApplicationStatus.CANCELED]: '#FF2640'
    };

    protected get applicationsPotentialPayouts$(): Signal<number[]> {
        return computed(() => this.applications$().map(application => application.potentialPayout?.getAmount()));
    }

    protected get availableSeasonBudget$(): Signal<number> {
        return computed(() => this.financialOverview$().availableBudget?.getAmount());
    }

    constructor(private store: Store, private dialog: DialogService) {}

    ngOnInit(): void {
        this.initScrollListener();
    }

    public toggleSelectedForPayout(application: UserApplication): void {
        this.store.dispatch(new ToggleSelectedForPayout({ application }));
    }

    public openApplication(application: UserApplication): void {
        window.open(
            `${environment.customerAppUrl}/dashboard/application-process/${application.id}?readonly=true`,
            '_blank'
        );
    }

    public openCommentDialog(application: UserApplication): void {
        const dialogRef = this.dialog.open<string, UserApplication>(CommentDialogComponent, {
            data: application,
            width: '500px'
        });

        dialogRef.afterClosed
            .pipe(
                filter(comment => comment !== undefined && comment !== null),
                switchMap(comment =>
                    this.store.dispatch(
                        comment === ''
                            ? new DeleteApplicationComment({ id: application.id })
                            : new UpdateApplicationComment({ id: application.id, comment })
                    )
                )
            )
            .subscribe();
    }

    private initScrollListener(): void {
        fromEvent(this.table.nativeElement, 'scroll')
            .pipe(
                filter(() => !this.isLazyLoadingApplications$()),
                tap((event: any) => {
                    if (!scrollOverAmountElement(event) || this.allApplicationsLoaded$()) {
                        return;
                    }
                    const season = this.store.selectSnapshot(SeasonState.getSeason);
                    this.store.dispatch(new LazyLoadApplications({ seasonId: season.id }));
                })
            )
            .subscribe();
    }
}
