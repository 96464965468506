<h1 mat-dialog-title>{{ 'translations.dialogs.add-item.title' | translate }}</h1>

<div mat-dialog-content class="dialog-content">
    <p [innerHTML]="'translations.dialogs.add-item.text' | translate"></p>

    <div class="uc-dialog grid grid-cols-2 gap-4 mb-7">
        <div class="flex items-center">
            <p class="text-center mr-4 flex-1">
                <b>{{ 'translations.dialogs.add-item.item-technical-name-label' | translate }}</b>
            </p>
            <sag-ui-input
                [placeholder]="'translations.dialogs.add-item.item-technical-name-placeholder' | translate"
                [(ngModel)]="data.title"
            ></sag-ui-input>
        </div>
        <div
            *ngFor="let lang of langs; let i = index"
            class="flex gap-3 items-center [&:nth-child(3)]:col-start-2 [&:nth-child(3)]:row-start-2"
        >
            <p class="text-center mr-4 flex-1">
                <b
                    >{{ 'translations.' + lang | translate }} <span *ngIf="lang !== 'en'"><br />(optional):</span></b
                >
            </p>
            <sag-ui-input
                [placeholder]="
                    ('translations.dialogs.add-item.enter-translation-for' | translate) +
                    ('translations.' + lang | translate)
                "
                [(ngModel)]="data.translations![lang]"
            ></sag-ui-input>
        </div>
    </div>
</div>

<div align="end" class="actions" mat-dialog-actions>
    <sag-ui-button color="secondary" (click)="cancel()">
        {{ 'translations.dialogs.add-item.cancel' | translate }}
    </sag-ui-button>
    <sag-ui-button (click)="proceed()"> {{ 'translations.dialogs.add-item.confirm' | translate }} </sag-ui-button>
</div>
