import { Component, Input } from '@angular/core';
import { SeasonInfoComponent } from '../season-creation-season-info/season-info.component';
import { TimeframeComponent } from '../season-creation-timeframe/timeframe.component';
import { BudgetComponent } from '../season-creation-budget/budget.component';
import { SocialCriteriaComponent } from '../season-creation-social-criteria/social-criteria.component';
import { SalaryWeightComponent } from '../season-creation-salary-weight/salary-weight.component';
import { TranslateModule } from '@ngx-translate/core';
import { SeasonFormGroup } from '../../../models/season-form.model';

@Component({
    selector: 'app-summary',
    standalone: true,
    templateUrl: './summary.component.html',
    imports: [
        SeasonInfoComponent,
        TimeframeComponent,
        BudgetComponent,
        SocialCriteriaComponent,
        SalaryWeightComponent,
        TranslateModule
    ]
})
export class SummaryComponent {
    @Input() public timeframe: SeasonFormGroup['timeframe'];
    @Input() public budget: SeasonFormGroup['budget'];
    @Input() public socialCriteria: SeasonFormGroup['socialCriteria'];
    @Input() public salaryWeight: SeasonFormGroup['salary'];

    public hasSummaryPageBeenReached = false;
}
