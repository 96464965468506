import { User } from './user';

export interface WorkersCouncilGroup {
    id: string;
    unit: string;
    name: string;
    delegates?: User[];
}

export interface PatchWorkersCouncilGroup {
    name?: string;
    delegateGIDs?: string[];
}
