<div class="flex flex-grow h-full">
    <!-- Stepper on the left -->
    <div
        class="[&_div_[sag-ui-stepper-knob]]:bg-transparent flex flex-col border-r border-customBorder p-10 h-[90vh] w-[380px]"
    >
        <sag-ui-stepper
            [steps]="steps"
            [active]="(seasonStepper$ | async).active"
            orientation="vertical"
            class="h-full"
            (selectedChange)="handleNavigateToStep($event)"
        ></sag-ui-stepper>
    </div>

    <!-- Main content on the right -->
    <div class="w-3/4 pl-32 pt-10">
        <header class="bg-transparent mb-4 flex justify-between items-center">
            <div>
                <h1 class="text-xl font-bold">
                    {{ seasonInfo?.get('title')?.value || ('seasons.season-creation.no-title' | translate) }}
                </h1>
                <p class="bg-yellow-400 text-black text-xs font-semibold px-3 py-0 rounded-full inline-block">
                    {{ (season$ | async)?.status ?? ('seasons.season-creation.draft' | translate) }}
                </p>
            </div>
            <div class="flex space-x-5">
                <sag-ui-button color="tertiary" icon="close" (click)="onCancel()">
                    {{ 'seasons.season-creation.buttons.cancel' | translate }}
                </sag-ui-button>
            </div>
        </header>
        <div class="h-px w-full bg-[#4C4C68] my-10"></div>

        @if(hasSeasonLoaded){

        <form [formGroup]="seasonCreationForm" ngxsForm="season.seasonCreationForm" (ngSubmit)="onSubmit()">
            <div>
                <!-- Content based on the current step -->
                <ng-container [ngSwitch]="(seasonStepper$ | async).selected">
                    <app-season-info *ngSwitchCase="0" [seasonInfo]="seasonInfo" />
                    <app-timeframe *ngSwitchCase="1" [timeframe]="timeframe" />
                    <app-budget *ngSwitchCase="2" [budget]="budget" />
                    <app-social-criteria *ngSwitchCase="3" [socialCriteria]="socialCriteria" />
                    <app-salary-weight *ngSwitchCase="4" [salaryWeight]="salaryWeight" />
                    <app-summary
                        *ngSwitchCase="5"
                        [timeframe]="timeframe"
                        [budget]="budget"
                        [socialCriteria]="socialCriteria"
                        [salaryWeight]="salaryWeight"
                    />
                </ng-container>
            </div>

            <div class="flex flex-row ml-auto justify-end space-x-5 pb-4">
                @if ((seasonStepper$ | async).selected !== 0) {
                <sag-ui-button color="tertiary" icon="chevron-left" (click)="onBack()">
                    {{ 'seasons.season-creation.buttons.back' | translate }}
                </sag-ui-button>
                } @if ((seasonStepper$ | async).selected !== 5) {
                <sag-ui-button icon="chevron-right" (click)="onNext()" [disabled]="!isFromValidInCorrectStep">
                    {{ 'seasons.season-creation.buttons.next' | translate }}
                </sag-ui-button>
                }
            </div>
            @if((seasonStepper$ | async).selected === 5) {
            <div class="w-full flex flex-row ml-auto justify-center space-x-5 pb-4">
                <sag-ui-button class="grid w-full" (click)="onSubmit()" [loading]="isSavingSeason$ | async">
                    {{
                        (season$ | async)?.id
                            ? ('seasons.season-creation.buttons.update' | translate)
                            : ('seasons.season-creation.buttons.save' | translate)
                    }}
                </sag-ui-button>
            </div>
            }
        </form>
        } @else {
        <div class="flex w-full h-full items-center justify-center">
            <sag-ui-spinner [size]="'lg'" class="scale-150"></sag-ui-spinner>
        </div>
        }
    </div>
</div>
