import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { iif, map, Observable, of, switchMap, take } from 'rxjs';
import { GbrUser } from 'interfaces/src';
import { UserState } from '../state/user.state';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard {
    user$: Observable<GbrUser> = inject(Store).select(UserState.user);
    constructor(private router: Router, private authService: AuthService) {}

    public canActivate(): Observable<boolean> {
        return this.authService.isAuthenticated$.pipe(
            switchMap(auth => iif(() => auth, this.user$, of(null))),
            take(1),
            map(user => {
                if (!user) {
                    return false;
                }
                const allowedRoles = ['Admin'];
                if (allowedRoles.some(role => user.roles?.includes(role))) {
                    return true;
                } else {
                    void this.router.navigate(['/auth/unauthorized']);
                    return false;
                }
            })
        );
    }
}

export const canActivateAdmin: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    adminGuard = inject(AdminGuard)
) => adminGuard.canActivate();
