<div class="flex flex-col justify-center items-center h-full">
    <img class="mb-8" [src]="'assets/shared-ui/images/sad-dinosaur.svg'" />
    <p class="text-[40px] leading-[52px]">{{ 'unauthorized.part1' | translate }}</p>
    <p class="text-[40px] leading-[52px]">{{ 'unauthorized.part2' | translate }}</p>
    <span class="text-[16px] leading-[28px] mt-5">
        {{ 'unauthorized.support' | translate }}
        <a href="mailto:gbr-erholungsmassnahmen.betriebsrat@siemens.com">{{
            'unauthorized.click-here' | translate
        }}</a></span
    >
</div>
