import { Component, inject, Signal } from '@angular/core';
import { select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GbrUser } from 'interfaces/src';
import { UserState } from '../../../auth/state/user.state';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html'
})
export class UserComponent {
    protected user$?: Observable<GbrUser | undefined> = inject(Store).select(UserState.user);
    protected readonly picture: Signal<string | undefined> = select(UserState.profilePicture);
}
