import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class TokenInjector implements HttpInterceptor {
    constructor(private oidcSecurityService: OidcSecurityService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.oidcSecurityService.getIdToken().pipe(
            switchMap(token => {
                if (token) {
                    const url = environment.apiUrl;
                    if (request.url.includes(this.removeProtocol(url))) {
                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                    }
                }
                return next.handle(request);
            })
        );
    }

    private removeProtocol = (str: string): string => str.replace(/^https?:\/\//, '');
}
