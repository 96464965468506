import { Component, Input, OnInit } from '@angular/core';
import { ButtonModule, InputModule } from '@siemens/component-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { DecrementStepper, IncrementStepper } from '../../../state/season.action';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SeasonFormGroup } from '../../../models/season-form.model';

@Component({
    selector: 'app-budget',
    standalone: true,
    templateUrl: './budget.component.html',
    imports: [InputModule, ButtonModule, ReactiveFormsModule, CommonModule, TranslateModule]
})
export class BudgetComponent implements OnInit {
    @Input() public budget: SeasonFormGroup['budget'];
    @Input() public enableIcon = false;
    @Input() public isDisabled?: boolean;

    public calendarYearBudgetLabel = 'seasons.season-creation.budget.calendar-year-budget';
    public shortestSubsidyLabel = 'seasons.season-creation.budget.shortest-subsidy';
    public longestSubsidyLabel = 'seasons.season-creation.budget.longest-subsidy';

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.setDisabledState();
    }

    private setDisabledState(): void {
        if (this.isDisabled) {
            this.budget.disable();
        } else {
            this.budget.enable();
        }
    }

    public onNext(): void {
        this.store.dispatch(new IncrementStepper());
    }

    public onBack(): void {
        this.store.dispatch(new DecrementStepper());
    }

    public toggleDisable(): void {
        this.isDisabled = !this.isDisabled;
    }

    public preventNonNumericInput(event: KeyboardEvent): void {
        if (['e', 'E', '+', '-', '.', ','].includes(event.key)) {
            event.preventDefault();
        }
    }
}
