export type IJwtIdTokenClaims = {
    'custom:country': string;
    'custom:gid': string;
    'custom:org_code': string;
    email: string;
    email_verified: string;
    family_name: string;
    given_name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    identities: any;
    nickname: string;
    preferred_username: string;
    sub: string;
    username: string;
};
