import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SalaryRangeForm, SeasonForm, SocialCriteriaForm } from '../models/season-create.model';
import { salaryWeights } from '../configs/salary-weight.config';
import { TimeFrameValidators } from '../components/season-create-edit/season-creation-timeframe/timeframe-validators';
import { EligibilityCriteriaProperties } from '../enums/eligibility-criteria.enum';
import { SalaryWeightFormGroup, SeasonFormGroup } from '../models/season-form.model';
import { ComparisonOperator } from '../enums/comparison-operator.enum';
import { Season } from 'libs/interfaces/src';
import { EligibilityCriteria, SeasonCreate } from '../models/season-create-dto.model';
import Dinero from 'dinero.js';

export class SeasonCreateOperations {
    public static initializeForm(season?: Season): FormGroup<SeasonFormGroup> {
        return new FormGroup<SeasonFormGroup>({
            seasonInfo: new FormGroup({
                id: new FormControl(season?.id ?? ''),
                title: new FormControl(season?.title ?? '', [Validators.required])
            }),
            timeframe: new FormGroup(
                {
                    startDate: new FormControl(season?.startDate ?? undefined, [Validators.required]),
                    endDate: new FormControl(season?.endDate ?? undefined, [Validators.required]),
                    applicationDeadlineDate: new FormControl(season?.applicationDeadline ?? undefined, [
                        Validators.required
                    ])
                },
                TimeFrameValidators.endDateBeforeApplicationDeadline()
            ),
            budget: new FormGroup({
                totalBudget: new FormControl(season?.totalBudget.getAmount() / 100 ?? null, [Validators.required]),
                employee: this.createSubsidyForm('employeeGid', season),
                spouse: this.createSubsidyForm('spouse', season),
                child: this.createSubsidyForm('child', season)
            }),
            socialCriteria: new FormGroup({
                parentalLeave: this.createWeightStepperFormControl(
                    EligibilityCriteriaProperties.IS_ON_PARENTAL_LEAVE,
                    null,
                    season
                ),
                percentageOfEmployment: this.createWeightStepperFormControl(
                    EligibilityCriteriaProperties.IS_PART_TIME,
                    null,
                    season
                ),
                severeDisability: this.createWeightStepperFormControl(
                    EligibilityCriteriaProperties.IS_DISABLED,
                    null,
                    season
                ),
                shiftWorker: this.createWeightStepperFormControl(
                    EligibilityCriteriaProperties.IS_SHIFT_WORKER,
                    null,
                    season
                ),
                singleParent: this.createWeightStepperFormControl(
                    EligibilityCriteriaProperties.IS_SINGLE_PARENT,
                    null,
                    season
                ),
                caregiver: this.createWeightStepperFormControl(
                    EligibilityCriteriaProperties.IS_CAREGIVER,
                    null,
                    season
                ),
                severeDisabilityOfTheSpouse: this.createWeightStepperFormControl(
                    EligibilityCriteriaProperties.IS_DISABLED,
                    'spouse',
                    season
                ),
                severeDisabilityOfTheChild: this.createWeightStepperFormControl(
                    EligibilityCriteriaProperties.IS_DISABLED,
                    'child',
                    season
                ),
                isElegibleForChildBenefit: this.createWeightStepperFormControl(
                    EligibilityCriteriaProperties.IS_ELIGIBLE,
                    'child',
                    season
                )
            }),
            salary: new FormGroup({
                salaryWeights: new FormArray(
                    (season?.salaryRangeItems ?? salaryWeights).map(
                        salaryWeight =>
                            new FormGroup<SalaryWeightFormGroup>({
                                id: new FormControl(salaryWeight.id ?? undefined),
                                lowerBound: new FormControl(salaryWeight.lowerBound),
                                upperBound: new FormControl(salaryWeight.upperBound),
                                weight: this.createWeightStepperSalaryFormControl(salaryWeight, season)
                            })
                    )
                )
            })
        });
    }

    private static createWeightStepperFormControl(
        criteriaProperty: EligibilityCriteriaProperties,
        type?: 'child' | 'spouse',
        season?: Season
    ): FormControl {
        const criteria =
            season?.eligibilityCriteria?.find(
                criteria => criteria.property === criteriaProperty && type === criteria.joinedEntityName
            ) ?? null;
        return new FormControl(criteria?.weight ?? 0, [Validators.required, Validators.min(1)]);
    }

    private static createWeightStepperSalaryFormControl(salaryRange: SalaryRangeForm, season?: Season): FormControl {
        const seasonSalaryRange =
            season?.salaryRangeItems.find(item => item.lowerBound === salaryRange.lowerBound) ?? null;
        return new FormControl(seasonSalaryRange?.weight ?? 0, [Validators.required, Validators.min(1)]);
    }

    private static createSubsidyForm(name: string, season?: Season): FormGroup {
        const firstSubsidy = season?.payoutElements?.find(
            element => element.minimumDuration === 7 && element.name === name
        );
        const secondSubsidy = season?.payoutElements?.find(
            element => element.id !== firstSubsidy.id && element.name === name
        );

        return new FormGroup({
            firstSubsidy: new FormGroup({
                minimumDuration: new FormControl(firstSubsidy?.minimumDuration ?? 7, [Validators.required]),
                maximumDuration: new FormControl(firstSubsidy?.maximumDuration ?? 13, [Validators.required]),
                amount: new FormControl(firstSubsidy?.amount.getAmount() / 100 ?? null, [Validators.required])
            }),
            secondSubsidy: new FormGroup({
                minimumDuration: new FormControl(secondSubsidy?.minimumDuration ?? 14, [Validators.required]),
                maximumDuration: new FormControl(null),
                amount: new FormControl(secondSubsidy?.amount.getAmount() / 100 ?? null, [Validators.required])
            })
        });
    }

    public static mapSeasonFormToSeasonResponse(seasonForm: SeasonForm): SeasonCreate {
        const salaryRangeItems =
            seasonForm.salary?.salaryWeights?.map(salary => ({
                id: seasonForm.seasonInfo?.id ? salary?.id : undefined,
                lowerBound: salary?.lowerBound,
                upperBound: salary?.upperBound,
                weight: salary?.weight
            })) || [];

        return {
            id: seasonForm.seasonInfo?.id ?? undefined,
            title: seasonForm.seasonInfo.title,
            startDate: seasonForm.timeframe.startDate,
            endDate: seasonForm.timeframe.endDate,
            applicationDeadline: seasonForm.timeframe.applicationDeadlineDate,
            totalBudget: Dinero({ amount: Number(seasonForm.budget.totalBudget) * 100, currency: 'EUR' }),
            salaryRangeItems,
            payoutElements: this.addPayoutElements(seasonForm),
            eligibilityCriteria: this.addEligibilityCriteria(seasonForm.socialCriteria)
        };
    }

    private static addEligibilityCriteria(socialCriteria: SocialCriteriaForm): EligibilityCriteria[] {
        return Object.keys(socialCriteria).map(criteria =>
            this.generateCriteriaObject(criteria, socialCriteria[criteria])
        );
    }

    public static addPayoutElements(seasonForm: SeasonForm) {
        const payoutElements = [];

        payoutElements.push({
            name: 'employeeGid',
            minimumDuration: seasonForm.budget.employee.firstSubsidy.minimumDuration,
            maximumDuration: seasonForm.budget.employee.firstSubsidy.maximumDuration,
            amount: Dinero({ amount: Number(seasonForm.budget.employee.firstSubsidy.amount) * 100, currency: 'EUR' })
        });
        payoutElements.push({
            name: 'employeeGid',
            minimumDuration: seasonForm.budget.employee.secondSubsidy.minimumDuration,
            maximumDuration: seasonForm.budget.employee.secondSubsidy.maximumDuration,
            amount: Dinero({ amount: Number(seasonForm.budget.employee.secondSubsidy.amount) * 100, currency: 'EUR' })
        });
        payoutElements.push({
            name: 'spouse',
            minimumDuration: seasonForm.budget.spouse.firstSubsidy.minimumDuration,
            maximumDuration: seasonForm.budget.spouse.firstSubsidy.maximumDuration,
            amount: Dinero({ amount: Number(seasonForm.budget.spouse.firstSubsidy.amount) * 100, currency: 'EUR' })
        });
        payoutElements.push({
            name: 'spouse',
            minimumDuration: seasonForm.budget.spouse.secondSubsidy.minimumDuration,
            maximumDuration: seasonForm.budget.spouse.secondSubsidy.maximumDuration,
            amount: Dinero({ amount: Number(seasonForm.budget.spouse.secondSubsidy.amount) * 100, currency: 'EUR' })
        });
        payoutElements.push({
            name: 'child',
            minimumDuration: seasonForm.budget.child.firstSubsidy.minimumDuration,
            maximumDuration: seasonForm.budget.child.firstSubsidy.maximumDuration,
            amount: Dinero({ amount: Number(seasonForm.budget.child.firstSubsidy.amount) * 100, currency: 'EUR' })
        });
        payoutElements.push({
            name: 'child',
            minimumDuration: seasonForm.budget.child.secondSubsidy.minimumDuration,
            maximumDuration: seasonForm.budget.child.secondSubsidy.maximumDuration,
            amount: Dinero({ amount: Number(seasonForm.budget.child.secondSubsidy.amount) * 100, currency: 'EUR' })
        });

        return payoutElements;
    }

    private static generateCriteriaObject(criteria: string, weight: number): EligibilityCriteria {
        switch (criteria) {
            case 'parentalLeave':
                return {
                    property: EligibilityCriteriaProperties.IS_ON_PARENTAL_LEAVE,
                    value: true,
                    comparisonOperator: ComparisonOperator.EQUALS,
                    weight
                };
            case 'percentageOfEmployment':
                return {
                    property: EligibilityCriteriaProperties.IS_PART_TIME,
                    value: true,
                    comparisonOperator: ComparisonOperator.EQUALS,
                    weight
                };
            case 'severeDisability':
                return {
                    property: EligibilityCriteriaProperties.IS_DISABLED,
                    value: true,
                    comparisonOperator: ComparisonOperator.EQUALS,
                    weight
                };
            case 'shiftWorker':
                return {
                    property: EligibilityCriteriaProperties.IS_SHIFT_WORKER,
                    value: true,
                    comparisonOperator: ComparisonOperator.EQUALS,
                    weight
                };
            case 'singleParent':
                return {
                    property: EligibilityCriteriaProperties.IS_SINGLE_PARENT,
                    value: true,
                    comparisonOperator: ComparisonOperator.EQUALS,
                    weight
                };
            case 'caregiver':
                return {
                    property: EligibilityCriteriaProperties.IS_CAREGIVER,
                    value: true,
                    comparisonOperator: ComparisonOperator.EQUALS,
                    weight
                };
            case 'severeDisabilityOfTheSpouse':
                return {
                    property: EligibilityCriteriaProperties.IS_DISABLED,
                    joinedEntityName: 'spouse',
                    value: true,
                    comparisonOperator: ComparisonOperator.EQUALS,
                    weight
                };
            case 'severeDisabilityOfTheChild':
                return {
                    property: EligibilityCriteriaProperties.IS_DISABLED,
                    joinedEntityName: 'child',
                    value: true,
                    comparisonOperator: ComparisonOperator.EQUALS,
                    weight
                };
            case 'isElegibleForChildBenefit':
                return {
                    property: EligibilityCriteriaProperties.IS_ELIGIBLE,
                    joinedEntityName: 'child',
                    value: true,
                    comparisonOperator: ComparisonOperator.EQUALS,
                    weight
                };
            default:
                return null;
        }
    }
}
