import { appendItemsWithoutDuplicates, insertOrUpdateItem } from '../../shared/utils/insertOrUpdateItem';
import { User } from '../../shared/interfaces/CreateOrUpdateUserPayload';
import { Action, Selector, State, StateContext, StateOperator } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { patch, removeItem } from '@ngxs/store/operators';
import { catchError, filter, of, switchMap, tap, throwError } from 'rxjs';
import { UserManagementService } from '../services/user-management.service';
import { CreateUser, DeleteUser, GetUsers, UpdatePage, UpdateSearchTerm, UpdateUser } from './users.actions';

export interface UsersStateModel {
    loading: boolean;
    users: User[];
    page: number;
    pageSize: number;
    total: number;
    searchTerm: string;
    hasNextPage: boolean;
}

export interface PageOptions {
    page: number;
    pageSize: number;
}

@State({
    name: 'UserState',
    defaults: {
        loading: false,
        users: [],
        page: 0,
        pageSize: 10,
        total: 0,
        hasNextPage: true,
        searchTerm: ''
    }
})
@Injectable()
export class UsersState {
    constructor(private userService: UserManagementService) {}

    @Action(GetUsers)
    public loadUsers(ctx: StateContext<UsersStateModel>) {
        const { page, pageSize, searchTerm, hasNextPage } = ctx.getState();
        return of(hasNextPage).pipe(
            filter(hasNextPage => hasNextPage),
            tap(() => ctx.patchState({ loading: true })),
            switchMap(() => this.userService.getUsers(page, pageSize, searchTerm)),
            catchError(err => {
                ctx.patchState({
                    loading: false
                });
                return throwError(() => err);
            }),
            tap(userResults => {
                const {
                    items: users,
                    metadata: { hasNextPage: hasNextPage, itemCount: total }
                } = userResults;
                ctx.setState(patch({ users: appendUsersWithoutDuplicates(users), total, hasNextPage, loading: false }));
            })
        );
    }

    @Action(UpdateUser)
    public updateUser(ctx: StateContext<UsersStateModel>, { user }: UpdateUser) {
        ctx.patchState({ loading: true });
        return this.userService.updateUser(user).pipe(
            catchError(err => {
                ctx.patchState({
                    loading: false
                });
                return throwError(() => err);
            }),
            tap(result => ctx.setState(patch({ users: insertOrUpdateUser(result), loading: false })))
        );
    }

    @Action(CreateUser)
    public addUser(ctx: StateContext<UsersStateModel>, { user }: CreateUser) {
        ctx.patchState({ loading: true });
        return this.userService.createUser(user).pipe(
            catchError(err => {
                ctx.patchState({
                    loading: false
                });
                return throwError(() => err);
            }),
            tap(result => ctx.setState(patch({ users: insertOrUpdateUser(result), loading: false })))
        );
    }

    @Action(DeleteUser)
    public deleteUser(ctx: StateContext<UsersStateModel>, { gid }: DeleteUser) {
        ctx.patchState({ loading: true });
        return this.userService.deleteUser(gid).pipe(
            catchError(err => {
                ctx.patchState({
                    loading: false
                });
                return throwError(() => err);
            }),
            tap(() =>
                ctx.setState(
                    patch({
                        users: removeItem(user => user.gid === gid),
                        loading: false
                    })
                )
            )
        );
    }

    @Action(UpdatePage)
    public updatePage(ctx: StateContext<UsersStateModel>, { pageOptions: { page, pageSize } }: UpdatePage) {
        const { pageSize: oldPageSize, hasNextPage } = ctx.getState();
        ctx.setState(
            patch({
                page,
                pageSize,
                hasNextPage: hasNextPage || pageSize !== oldPageSize
            })
        );
        return ctx.dispatch(new GetUsers());
    }

    @Action(UpdateSearchTerm)
    public updateSearchTerm(ctx: StateContext<UsersStateModel>, { searchTerm }: UpdateSearchTerm) {
        ctx.setState(
            patch({
                searchTerm,
                hasNextPage: true
            })
        );
        return ctx.dispatch(new GetUsers());
    }

    @Selector()
    static users(state: UsersStateModel) {
        return state?.users?.filter(user => filterUser(user, state.searchTerm)).sort(sortNames);
    }

    @Selector()
    static total(state: UsersStateModel) {
        return state?.total;
    }

    @Selector()
    static isLoading(state: UsersStateModel) {
        return state?.loading;
    }

    @Selector()
    static pageOptions(state: UsersStateModel) {
        const { page, pageSize } = state ?? { page: 1, pageSize: 5 };
        return { page, pageSize };
    }
}

const appendUsersWithoutDuplicates = (users: User[]): StateOperator<User[]> =>
    appendItemsWithoutDuplicates(users, 'gid');

const insertOrUpdateUser = (user: User): StateOperator<User[]> => insertOrUpdateItem(user, 'gid');

const filterableProps = ['gid', 'firstName', 'lastName', 'email'];

function filterUser(user: User, searchTerm: string) {
    return filterableProps.some(prop => user[prop]?.toLowerCase().includes(searchTerm.toLowerCase()));
}

function sortNames(a: User, b: User) {
    if (a.lastName < b.lastName) {
        return -1;
    } else if (b.lastName < a.lastName) {
        return 1;
    } else {
        return a.firstName < b.firstName ? -1 : b.firstName < a.firstName ? 1 : 0;
    }
}
