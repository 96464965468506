<!-- timeline.component.html -->
<div class="flex flex-col items-center text-white w-full">
    <div class="flex justify-between w-full mt-2 text-sm">
        <div [ngStyle]="{ 'width.%': whiteBarWidth }" class="flex justify-between items-center">
            <span>{{ startDate | date : 'dd.MM.yyyy' }}</span>
            <span>{{ endDate | date : 'dd.MM.yyyy' }}</span>
        </div>
        <div [ngStyle]="{ 'width.%': greenBarWidth }" class="flex justify-end items-center min-w-[85px]">
            <span>{{ applicationDeadlineDate | date : 'dd.MM.yyyy' }}</span>
        </div>
    </div>
    <div class="flex w-full h-2 bg-gray-800">
        <div [ngStyle]="{ 'width.%': whiteBarWidth }" class="bg-white"></div>
        <div [ngStyle]="{ 'width.%': greenBarWidth }" class="bg-siemensGreen"></div>
    </div>
    <div class="flex justify-between w-full mt-4 text-xs text-gray-400">
        <div class="flex flex-col items-center" *ngFor="let monthLabel of getMonthLabels()">
            <div>{{ monthLabel.split(' ')[0] }}</div>
            <div>{{ monthLabel.split(' ')[1] }}</div>
        </div>
    </div>
</div>
