import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    AvatarModule,
    ButtonModule,
    CoreModule,
    InputModule,
    SelectModule,
    SnackbarModule,
    TabsModule,
    TextareaModule
} from '@siemens/component-lib';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { UserComponent } from './components/user/user.component';

const components = [HeaderComponent, UserComponent, FooterComponent];

const sagUIModules = [
    ButtonModule,
    InputModule,
    SelectModule,
    TextareaModule,
    SnackbarModule,
    TabsModule,
    AvatarModule
];

const modules = [
    RouterModule,
    MatMenuModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatExpansionModule,
    TranslateModule,
    MatSnackBarModule,
    MatOptionModule,
    MatIconModule,
    CoreModule.forRoot('sag')
];

@NgModule({
    declarations: [components],
    imports: [CommonModule, ...modules, ...sagUIModules],
    exports: [components, modules, ...sagUIModules]
    // providers: [MatSnackBarModule]
})
export class SharedModule {}
