import { User } from '../../shared/interfaces/CreateOrUpdateUserPayload';
import { PageOptions } from './users.state';

export class GetUsers {
    static type = '[Users] Load';
}

export class CreateUser {
    static type = '[Users] Create New';
    constructor(public user: User) {}
}

export class UpdateUser {
    static type = '[Users] Update';
    constructor(public user: User) {}
}

export class DeleteUser {
    static type = '[Users] Delete';
    constructor(public gid: string) {}
}

export class UpdatePage {
    constructor(public pageOptions: PageOptions) {}
    static type = '[Users] Update Page Options';
}

export class UpdateSearchTerm {
    constructor(public searchTerm: string) {}
    static type = '[Users] Update Search Term';
}
