<div *ngFor="let item of itemsWithChildren | keyvalue">
    <mat-expansion-panel class="acc-panel">
        <mat-expansion-panel-header>
            <mat-panel-title>{{ item.key }}</mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <mat-accordion [displayMode]="'flat'">
                <app-translation-part
                    [selectedLanguage]="selectedLanguage"
                    [frontendType]="frontendType"
                    [allLanguages]="allLanguages"
                    [parent]="(parent !== '' ? parent + '.' : '') + item.key"
                    [items]="item.value"
                ></app-translation-part>
            </mat-accordion>
        </ng-template>
    </mat-expansion-panel>
</div>

<div class="panel-contents">
    <div *ngFor="let item of itemsWithoutChildren | keyvalue" class="translations-editor">
        <div>
            <p class="translation-key">{{ item.key }}</p>
        </div>

        <div class="trans-input">
            <div class="textarea-container">
                <sag-ui-textarea
                    [attr.aria-label]="'translation text'"
                    [value]="item.value"
                    [(ngModel)]="item.value"
                    (change)="changeOfValue(item.key, item.value)"
                >
                </sag-ui-textarea>
            </div>
        </div>
    </div>

    <div class="translations-footer">
        <sag-ui-button *ngIf="parent !== ''" color="primary" (click)="addItem()" icon="plus-large">
            {{ 'translations.buttons.add-item' | translate }}
        </sag-ui-button>
        <sag-ui-button color="primary" (click)="addTopic()" icon="plus-large">
            {{ 'translations.buttons.add-topic' | translate }}
        </sag-ui-button>
    </div>
</div>
